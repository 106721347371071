import { createStyles } from '@material-ui/core';

const styles = () => createStyles({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '& svg': {
      opacity: 0.7,
    },
    '&:hover': {
      '& svg': {
        opacity: 1,
      },
    },
  },
});

export default styles;
