import { createStyles, Theme } from '@material-ui/core';

const styles = ({ spacing }: Theme) => createStyles({
  root: {
    marginBottom: 0,
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    cursor: 'pointer',
  },
  displayNone: {
    display: 'none',
  },
  wideBanner: {
    marginLeft: spacing(-4),
    marginRight: spacing(-4),
  },
});

export default styles;
