import { createAction, createSlice } from '@reduxjs/toolkit';
import { ErrorInfo } from '#shared/types/common';

export interface OnboardingProcessState {
  isOnbordingStatusChecked: boolean;
}

const initialState: OnboardingProcessState = {
  isOnbordingStatusChecked: false,
};

const ACTION_PREFIX = 'onboarding';

export const onboardingProcessRequest = createAction(`${ACTION_PREFIX}/onboardingProcessRequest`);
export const onboardingProcessRequestError = createAction<ErrorInfo>(`${ACTION_PREFIX}/onboardingProcessRequestError`);

const OnboardingProcessSlice = createSlice({
  name: ACTION_PREFIX,
  initialState,
  reducers: {
    onboardingProcessRequestSuccess: () => {
      return {
        isOnbordingStatusChecked: true,
      };
    },
  },
  extraReducers: {
    [onboardingProcessRequestError.type]: () => {
      return {
        isOnbordingStatusChecked: true,
      };
    },
  },
});

export const {
  onboardingProcessRequestSuccess,
} = OnboardingProcessSlice.actions;
export default OnboardingProcessSlice.reducer;
