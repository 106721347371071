import React from 'react';

import { FormComponent, FormioComponentName } from '#web-components/components/Form/types';
import { ColumnDefinition, ListItem } from '#web-components/types/table';
import TextCell from '#web-components/components/Table/Cells/TextCell';

import FileCell from '../FileCell';

interface CellProps {
  component: FormComponent;
  item: ListItem;
  columnDefinition: ColumnDefinition;
}

interface CustomCellProps {
  component: FormComponent;
  item: ListItem;
  columnDefinition: ColumnDefinition;
}

export const componentMap: Record<string, React.ElementType<CellProps>> = {
  [FormioComponentName.file]: FileCell,
  [FormioComponentName.fileLatest]: FileCell,
  [FormioComponentName.fileLegacy]: FileCell,
};

const CustomCell: React.FC<CustomCellProps> = ({ columnDefinition, component, item }: CustomCellProps) => {
  if (componentMap[component.type]) {
    const Component = componentMap[component.type];
    return <Component component={component} columnDefinition={columnDefinition} item={item} />;
  }

  return <TextCell item={item} columnDefinition={columnDefinition} />;
};

export default CustomCell;
