import { makeStyles } from '@material-ui/core';
import React from 'react';
import styles from './ErrorNavLink.styles';

type ErrorNavLinkProps = {
  to: string,
  title: string,
  children?: React.ReactNode,
};

const useStyles = makeStyles(styles, { name: 'ErrorNavLink' });

export default function ErrorNavLink({ to, title, children }: ErrorNavLinkProps) {
  const classes = useStyles();
  return <a href={to} title={title} className={classes.root}>{children}</a>;
}
