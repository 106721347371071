import baseForm from 'formiojs/components/day/Day.form';
import {
  defaultConditionalFields,
  defaultDataFields,
  defaultValidationFields,
} from '#web-components/components/Form/constants';

export default () => {
  return baseForm([
    {
      key: 'data',
      components: [
        ...defaultDataFields,
      ],
    },
    {
      key: 'validation',
      components: [
        ...defaultValidationFields,
      ],
    },
    {
      key: 'conditional',
      components: defaultConditionalFields,
    },
  ]);
};
