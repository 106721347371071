import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorInfo } from '#shared/types/common';
import { ProcessInstanceHistoryListItem } from '#shared/types/processInstance';
import { ROWS_PER_PAGE_OPTIONS } from '#web-components/components/Table';
import { FilterParams, Order } from '#web-components/types/table';

const ACTION_PREFIX = 'processInstancesHistory';

export interface ProcessInstancesHistoryState {
  list: Array<ProcessInstanceHistoryListItem>;
  count: number;
  filterParams: FilterParams
}

const initialState: ProcessInstancesHistoryState = {
  list: [],
  count: 0,
  filterParams: {
    page: 0,
    rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
    order: Order.desc,
    orderField: 'endTime',
  },
};

export const getProcessInstancesHistoryRequest = createAction<{
  filterParams: FilterParams,
  isInitialRequest?: boolean,
}>(
  `${ACTION_PREFIX}/getProcessInstancesHistoryRequest`,
);
export const getProcessInstancesHistoryError = createAction<ErrorInfo>(
  `${ACTION_PREFIX}/getProcessInstancesHistoryError`,
);

const ProcessInstancesSlice = createSlice({
  name: ACTION_PREFIX,
  initialState,
  reducers: {
    getProcessInstancesHistorySuccess(state, action: PayloadAction<{
      list: Array<ProcessInstanceHistoryListItem>,
      filterParams: FilterParams,
      count?: number
    }>) {
      const { list, filterParams, count } = action.payload;
      return {
        ...state,
        list,
        filterParams,
        count: count || 0,
      };
    },
    getProcessInstancesHistoryClean(state) {
      return {
        ...state,
        count: 0,
        list: [],
        filterParams: initialState.filterParams,
      };
    },
  },
});

export const {
  getProcessInstancesHistorySuccess,
  getProcessInstancesHistoryClean,
} = ProcessInstancesSlice.actions;

export default ProcessInstancesSlice.reducer;
