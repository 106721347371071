import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserTask } from '#shared/types/task';
import { ErrorInfo } from '#shared/types/common';
import { FilterParams, Order } from '#web-components/types/table';
import { ROWS_PER_PAGE_OPTIONS } from '#web-components/components/Table';

const ACTION_PREFIX = 'pendingTasks';

export interface PendingTasksState {
  list: Array<UserTask>;
  count: number;
  filterParams: FilterParams;
}

export const initialState: PendingTasksState = {
  list: [],
  count: 0,
  filterParams: {
    page: 0,
    rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
    order: Order.desc,
    orderField: 'created',
  },
};

export const getPendingTasksCountRequest = createAction(`${ACTION_PREFIX}/getPendingTasksCountRequest`);
export const getPendingTasksCountError = createAction<ErrorInfo>(`${ACTION_PREFIX}/getPendingTasksCountError`);

export const getPendingTasksListRequest = createAction<{
  filterParams: FilterParams, isInitialRequest?: boolean
}>(`${ACTION_PREFIX}/getPendingTasksListRequest`);
export const getPendingTasksListError = createAction<ErrorInfo>(`${ACTION_PREFIX}/getPendingTasksListError`);

export const claimTaskRequest = createAction<{ taskId: string, taskName: string }>(`${ACTION_PREFIX}/claimTaskRequest`);
export const claimTaskSuccess = createAction(`${ACTION_PREFIX}/claimTaskSuccess`);
export const claimTaskError = createAction<ErrorInfo>(`${ACTION_PREFIX}/claimTaskError`);

const PendingTasksSlice = createSlice({
  name: ACTION_PREFIX,
  initialState,
  reducers: {
    getPendingTasksCountSuccess(state: PendingTasksState, action: PayloadAction<number>) {
      return {
        ...state,
        count: action.payload,
      };
    },
    getPendingTasksListSuccess(state: PendingTasksState, action: PayloadAction<{
      list: Array<UserTask>,
      filterParams: FilterParams;
    }>) {
      const { list, filterParams } = action.payload;
      return {
        ...state,
        list,
        filterParams,
      };
    },
    getPendingTasksCountClean(state) {
      return {
        ...state,
        count: 0,
      };
    },
    getPendingTasksListClean(state) {
      return {
        ...state,
        list: [],
        filterParams: initialState.filterParams,
        count: 0,
      };
    },
  },
});

export default PendingTasksSlice.reducer;

export const {
  getPendingTasksCountSuccess,
  getPendingTasksListSuccess,
  getPendingTasksListClean,
  getPendingTasksCountClean,
} = PendingTasksSlice.actions;
