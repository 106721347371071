import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ErrorInfo } from '#shared/types/common';
import { ProcessDefinition, ProcessDefinitionGroupData } from '#shared/types/processDefinition';
import { Form, FormSubmission } from '#web-components/components/Form/types';

export interface ProcessDefinitionsState {
  groupData: ProcessDefinitionGroupData;
  list: Array<ProcessDefinition>;
  count: number;
  form: Form | null;
  current: ProcessDefinition | null;
  formData: FormSubmission | null;
}

const initialState: ProcessDefinitionsState = {
  list: [],
  groupData: {
    groups: [],
    ungrouped: [],
  },
  count: 0,
  form: null,
  current: null,
  formData: null,
};

const ACTION_PREFIX = 'processDefinitions';

export const startProcessRequest = createAction<{
  processDefinitionKey: string, goToFirstTask?: boolean,
}>(`${ACTION_PREFIX}/startProcessRequest`);
export const startProcessSuccess = createAction(`${ACTION_PREFIX}/startProcessSuccess`);
export const startProcessWithFormSuccess = createAction(`${ACTION_PREFIX}/startProcessWithFormSuccess`);
export const startProcessError = createAction<ErrorInfo>(`${ACTION_PREFIX}/startProcessError`);
export const startProcessWithFormError = createAction<Array<ErrorInfo>>(`${ACTION_PREFIX}/startProcessWithFormError`);
export const startProcessWithFormClean = createAction(`${ACTION_PREFIX}/startProcessWithFormClean`);

export const getProcessDefinitionsCountRequest = createAction(`${ACTION_PREFIX}/getProcessDefinitionsCountRequest`);
export const getProcessDefinitionsCountError = (
  createAction<ErrorInfo>(`${ACTION_PREFIX}/getProcessDefinitionsCountError`)
);

export const getProcessDefinitionsRequest = createAction(`${ACTION_PREFIX}/getProcessDefinitionsRequest`);
export const getProcessDefinitionsError = createAction<ErrorInfo>(`${ACTION_PREFIX}/getProcessDefinitionsError`);

export const getGroupedProcessDefinitionsRequest = createAction(`${ACTION_PREFIX}/getGroupedProcessDefinitionsRequest`);
export const getGroupedProcessDefinitionsError = createAction<ErrorInfo>(
  `${ACTION_PREFIX}/getGroupedProcessDefinitionsError`,
);

export const getProcessDefinitionFormRequest = createAction<string>(`${ACTION_PREFIX}/getProcessDefinitionFormRequest`);
export const getProcessDefinitionFormError = createAction<ErrorInfo>(`${ACTION_PREFIX}/getProcessDefinitionFormError`);

export const getProcessDefinitionRequest = createAction<string>(`${ACTION_PREFIX}/getProcessDefinitionRequest`);
export const getProcessDefinitionError = createAction<ErrorInfo>(`${ACTION_PREFIX}/getProcessDefinitionError`);

export const startProcessWithFormRequest = createAction<{
  processDefinitionKey: string,
  formData: FormSubmission,
}>(`${ACTION_PREFIX}/startProcessWithFormRequest`);

const ProcessDefinitionsSlice = createSlice({
  name: ACTION_PREFIX,
  initialState,
  reducers: {
    getProcessDefinitionsSuccess(state, action: PayloadAction<Array<ProcessDefinition>>) {
      return {
        ...state,
        list: action.payload,
      };
    },
    getGroupedProcessDefinitionsSuccess(state, action: PayloadAction<ProcessDefinitionGroupData>) {
      return {
        ...state,
        groupData: action.payload,
      };
    },
    getProcessDefinitionsCountSuccess(state, action: PayloadAction<number>) {
      return {
        ...state,
        count: action.payload,
      };
    },
    getProcessDefinitionFormSuccess: (state, action: PayloadAction<Form>) => ({
      ...state,
      form: action.payload,
    }),
    getProcessDefinitionSuccess: (state, action: PayloadAction<ProcessDefinition>) => ({
      ...state,
      current: action.payload,
    }),
    getProcessDefinitionFormClean: (state) => ({
      ...state,
      form: initialState.form,
      current: initialState.current,
      formData: initialState.formData,
    }),
  },
  extraReducers: {
    // we have to fill submission data for allowing to submit form multiply times
    // we need this in case of error while submitting form data (500 errors)
    [startProcessWithFormRequest.type]: (state, action: PayloadAction<{
      processDefinitionKey: string,
      formData: FormSubmission,
    }>) => {
      return {
        ...state,
        formData: {
          ...state.formData,
          data: action.payload.formData.data,
        },
      };
    },
  },
});

export const {
  getProcessDefinitionsSuccess,
  getProcessDefinitionsCountSuccess,
  getProcessDefinitionFormSuccess,
  getProcessDefinitionSuccess,
  getProcessDefinitionFormClean,
  getGroupedProcessDefinitionsSuccess,
} = ProcessDefinitionsSlice.actions;
export default ProcessDefinitionsSlice.reducer;
