import React, {
  useRef, useCallback, useMemo, useState,
} from 'react';
import isEqual from 'lodash/isEqual';
import { useTheme } from '@material-ui/core/styles';

import ConfirmModal from '#web-components/components/ConfirmModal';

import { FormComponent, FormSubmission } from '../../types';
import localizationForm from '../../localization/ua.json';

import './styles.scss';
import FormStyles from '../FormStyles';

import FormMemorized from './FormMemorized';

interface Props {
  language: string,
  onSubmit: (submission: FormSubmission) => void;
  onChange?: (submission: FormSubmission) => void;
  onCustomEvent?: (options: { type: string, data?: Record<string, unknown> }) => void;
  components?: Array<FormComponent>;
  submission?: FormSubmission;
  readOnly?: boolean;
  evalContext?: Record<string, unknown>;
  parentPath?: string;
  fileServiceNonSupport?: boolean;
  formRef?: React.Ref<unknown>;
  onDirtyChange?: (status?: boolean) => void;
  fileMaxSize?: string,
  fileMaxTotalSize?: string,
  blackList?: string[],
}

export function propsComparator(prevProps: Props, nextProps: Props) {
  return isEqual(prevProps, nextProps);
}

export default function FormioForm(props: Props) {
  const theme = useTheme();
  const confirmModalInit = useMemo(() => ({
    confirmationText: '',
    callBack: () => null,
  }), []);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const confirmModal = useRef<{ confirmationText: string, callBack:() => void }>(confirmModalInit);
  const handleClose = useCallback(() => {
    confirmModal.current = confirmModalInit;
    setConfirmModalOpen(false);
  }, [confirmModalInit]);
  const handleOpenConfirm = useCallback((callBack: () => void, confirmationText: string) => {
    confirmModal.current = { confirmationText, callBack };
    setConfirmModalOpen(true);
  }, []);
  const handleConfirm = useCallback(() => {
    setConfirmModalOpen(false);
    confirmModal.current.callBack();
    confirmModal.current = confirmModalInit;
  }, [confirmModalInit]);

  return (
    <div>
      <FormStyles />
      <FormMemorized {...props} theme={theme} handleOpenConfirm={handleOpenConfirm} />
      <ConfirmModal
        title={localizationForm.confirmModalTitle}
        confirmationText={confirmModal.current.confirmationText}
        isOpen={isConfirmModalOpen}
        submitText={localizationForm.approve}
        cancelText={localizationForm.cancel}
        onSubmit={handleConfirm}
        onOpenChange={handleClose}
      />
    </div>
  );
}
