import { createSelector } from 'reselect';
import {
  createAsyncActionErrorSelector,
  createAsyncActionIsLoadingSelector,
} from '../asyncAction';
import type { RootState } from '../rootReducer';
import { getNotificationListRequest, UserNotificationsState } from './slice';

export const selectUserNotificationsState = (state: RootState): UserNotificationsState => state.userNotifications;

export const selectUserNotifications = createSelector(
  selectUserNotificationsState,
  (userNotifications) => userNotifications.list,
);
export const selectUserNotificationsPaginationParams = createSelector(
  selectUserNotificationsState,
  (userNotifications) => userNotifications.paginationParams,
);

export const selectUserNotificationsIsLoading = createAsyncActionIsLoadingSelector(getNotificationListRequest.type);
export const selectUserNotificationsError = createAsyncActionErrorSelector(getNotificationListRequest.type);
