import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorInfo } from '#shared/types/common';
import { Settings, Channel } from 'types/settings';

const ACTION_PREFIX = 'settings';

export interface SettingsState {
  settings: Settings | null,
  timeout: number;
}

const initialState: SettingsState = {
  settings: null,
  timeout: 0,
};

export const getSettingsRequest = createAction(`${ACTION_PREFIX}/getSettingsRequest`);
export const getSettingsError = createAction<ErrorInfo>(`${ACTION_PREFIX}/getSettingsError`);

export const activateChannelRequest = createAction <Pick<Channel, 'channel' | 'address' | 'verificationCode'>>(
  `${ACTION_PREFIX}/activateChannelRequest`,
);
export const activateChannelSuccess = createAction(`${ACTION_PREFIX}/activateChannelSuccess`);
export const activateChannelError = createAction<ErrorInfo>(`${ACTION_PREFIX}/activateChannelError`);
export const activateChannelClean = createAction(`${ACTION_PREFIX}/activateChannelClean`);

export const deactivateChannelRequest = createAction <Pick<Channel, 'channel'>>(
  `${ACTION_PREFIX}/deactivateChannelRequest`,
);
export const deactivateChannelSuccess = createAction(`${ACTION_PREFIX}/deactivateChannelSuccess`);
export const deactivateChannelError = createAction<ErrorInfo>(`${ACTION_PREFIX}/deactivateChannelError`);

export const validateChannelRequest = createAction <Pick<Channel, 'channel' | 'address'>>(
  `${ACTION_PREFIX}/validateChannelRequest`,
);
export const validateChannelSuccess = createAction(`${ACTION_PREFIX}/validateChannelSuccess`);
export const validateChannelError = createAction<ErrorInfo>(`${ACTION_PREFIX}/validateChannelError`);

export const verifyChannelRequest = createAction <Pick<Channel, 'channel' | 'address'>>(
  `${ACTION_PREFIX}/verifyChannelRequest`,
);
export const verifyChannelError = createAction<ErrorInfo>(`${ACTION_PREFIX}/verifyChannelError`);

const SettingsSlice = createSlice({
  name: ACTION_PREFIX,
  initialState,
  reducers: {
    getSettingsSuccess(state, action: PayloadAction<Settings>) {
      return {
        ...state,
        settings: action.payload,
      };
    },
    getSettingsClean(state) {
      return {
        ...state,
        settings: null,
      };
    },
    validateChannelClean(state) {
      return {
        ...state,
        errors: null,
      };
    },
    verifyChannelSuccess(state, action: PayloadAction<{ expirationTime: number }>) {
      return {
        ...state,
        timeout: action.payload.expirationTime,
      };
    },
  },
});

export default SettingsSlice.reducer;

export const {
  getSettingsSuccess,
  getSettingsClean,
  validateChannelClean,
  verifyChannelSuccess,
} = SettingsSlice.actions;
