import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'constants/routes';
import NavbarContainer from '#web-components/components/Navbar';
import HomeButton from '#web-components/components/Navbar/components/HomeButton';
import LinkBack from '#web-components/components/LinkBack';
import styles from './NavbarWithNavigation.styles';

const useStyles = makeStyles(styles, { name: 'NavbarWithNavigation' });

interface NavbarWithNavigationProps {
  linkTo: string;
  linkCaption?: string;
  onLinkClick?: (e: React.MouseEvent) => void;
}

const NavbarWithNavigation: React.FC<NavbarWithNavigationProps> = ({ linkTo, linkCaption, onLinkClick }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  return (
    <NavbarContainer disableBackground>
      <div className={classes.root}>
        <LinkBack
          to={linkTo}
          title={linkCaption}
          component={RouterLink}
          onLinkClick={onLinkClick}
        />
        <HomeButton
          direction="right"
          homePath={ROUTES.HOME}
          appTitle={t('appTitle')}
          navLinkComponent={RouterLink}
        />
      </div>
    </NavbarContainer>
  );
};

export default NavbarWithNavigation;
