import { AjaxError } from 'rxjs/ajax';

import { ERROR_TYPE, ErrorInfo } from '#shared/types/common';
import { TaskSubmissionPayload, TaskData } from '#shared/types/task';
import type { i18n as I18n } from 'i18next';
import get from 'lodash/get';
import type { Form, FormSubmission } from '#web-components/components/Form/types';
import { getServerValidationErrors } from '#shared/utils/apiHelpers';

export const formatFormSubmissionToTaskData = (
  submission: FormSubmission,
): TaskSubmissionPayload => {
  return {
    data: submission.data,
  };
};

export const formatTaskDataToFormSubmission = (
  data?: TaskData,
): FormSubmission => {
  return { data: data || {} };
};

export const createErrorMessage = (serverErrorMessage: string, keyTitle?: string): string => {
  const shouldMessageBeWithTitle = keyTitle && !serverErrorMessage.includes(keyTitle);
  return shouldMessageBeWithTitle ? `${keyTitle}: ${serverErrorMessage}` : serverErrorMessage;
};

export const getValidationErrors = (i18n: I18n, response: AjaxError, formData?: Form | null): Array<ErrorInfo> => {
  const errorCode = get(response.response, 'code', '');

  const getNumberFromListSizeValidation = () => {
    const originalMessage = get(response.response, 'details.errors[0].message') as string | undefined;
    const max = parseInt(originalMessage?.split(' ').pop() || '', 10);
    if (errorCode !== 'LIST_SIZE_VALIDATION_ERROR' || Number.isNaN(max)) {
      return null;
    }
    return max;
  };

  if (errorCode && i18n.exists(`errors.validation.${errorCode}`)) {
    const max = getNumberFromListSizeValidation();
    return [{
      message: i18n.t(`errors.validation.${errorCode}`, max ? { max } : undefined),
      type: ERROR_TYPE.VALIDATION,
    }];
  }

  const serverErrors = getServerValidationErrors(response);
  const components = formData?.components || [];

  return serverErrors.map((serverError) => {
    const contextComponent = components.find((component) => component.key === serverError.field);
    const keyTitle = contextComponent?.label;
    const message = createErrorMessage(serverError.message, keyTitle);

    return { message, type: ERROR_TYPE.VALIDATION };
  });
};
