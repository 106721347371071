import { ForwardedRef, MutableRefObject } from 'react';

export const assignMultipleRefs = (
  node: unknown,
  localRef: MutableRefObject<unknown>,
  forwardedRef: ForwardedRef<unknown>,
) => {
  // eslint-disable-next-line no-param-reassign
  localRef.current = node;
  if (typeof forwardedRef === 'function') {
    forwardedRef(node);
  } else if (forwardedRef) {
    // eslint-disable-next-line no-param-reassign
    forwardedRef.current = node;
  }
};

const b64FromB64Url = (b64: string) => {
  // Replace non-url compatible chars with base64 standard chars
  let output = b64
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  // Pad out with standard base64 required padding characters
  const pad = output.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
    }
    output += new Array(5 - pad).join('=');
  }

  return output;
};

export const b64UrlDecodeUnicode = (urlStr: string) => {
  const str = b64FromB64Url(urlStr);
  return decodeURIComponent(Array.prototype.map.call(atob(str), (c) => {
    return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
  }).join(''));
};

const SUPPORTED_BROWSERS = ['Chrome'];

export const isBrowserSupported = () => {
  return SUPPORTED_BROWSERS.some((browser) => window.navigator.userAgent.includes(browser)
  && !window.navigator.userAgent.includes('Edg')
  && !window.navigator.userAgent.includes('OPR'));
};
