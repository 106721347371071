import ConfirmModal from '#web-components/components/ConfirmModal';
import { UnregisterCallback } from 'history';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';

export type RouterPromptProps = {
  title: string,
  okText: string,
  cancelText: string,
  enabled: boolean,
  text: string,
  baseName: string,
};

export default function RouterPrompt({
  title, okText, cancelText, enabled, text, baseName,
}: RouterPromptProps) {
  const history = useHistory<{ forceLeave?: boolean }>();
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const [shouldGoBack, setShouldGoBack] = useState(false);
  const unblockRef = useRef<UnregisterCallback>();

  useEffect(() => {
    if (enabled) {
      unblockRef.current = history?.block((prompt) => {
        const fullRouterPathName = baseName.concat(history.location.pathname).concat(history.location.search);
        const fullWindowPathName = window.location.pathname.concat(window.location.search);
        const isBackButtonClick = fullWindowPathName !== fullRouterPathName;

        if (prompt.state && prompt.state.forceLeave && !isBackButtonClick) {
          return undefined;
        }

        setCurrentPath(prompt.pathname.concat(prompt.search));
        setShowPrompt(true);

        // on browser back button clicked, actual browser url changed
        // so we have to change it back
        if (isBackButtonClick) {
          setShouldGoBack(true);
          window.history.forward();
        }
        // for block router, here we have to return some non empty string
        return 'true';
      });
    }
    return () => {
      unblockRef.current?.();
    };
  }, [enabled, baseName, history]);

  const handleOK = useCallback(() => {
    unblockRef.current?.();
    setShowPrompt(false);
    if (shouldGoBack) {
      history.goBack();
    } else {
      history.push(currentPath);
    }
  }, [currentPath, history, shouldGoBack]);

  const handleCancel = useCallback(async () => {
    setShowPrompt(false);
    setShouldGoBack(false);
  }, []);

  return showPrompt ? (
    <ConfirmModal
      title={title}
      isOpen={showPrompt}
      onSubmit={handleOK}
      submitText={okText}
      onOpenChange={handleCancel}
      cancelText={cancelText}
      confirmationText={text}
    />
  ) : null;
}
