import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core';

import styles from './ConfirmModal.styles';
import ConfirmModal, { ConfirmModalProps } from './ConfirmModal';

interface Props extends ConfirmModalProps {
  classes?: Partial<WithStyles<typeof styles>['classes']>;
}

export default withStyles(styles)(ConfirmModal) as React.ComponentClass<Props>;
