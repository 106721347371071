import { Observable, throwError } from 'rxjs';
import { ajax, AjaxError } from 'rxjs/ajax';
import { catchError } from 'rxjs/operators';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { ROUTES } from 'constants/routes';
import { history } from 'store/history';

const CSRF_TOKEN = 'XSRF-TOKEN';

interface ApiInterface {
  baseUrl: string;
  headers: Record<string, string>;
}

export class Api implements ApiInterface {
  baseUrl = '';

  headers = {};

  constructor(baseUrl: string, headers: Record<string, string>) {
    this.baseUrl = baseUrl;
    this.headers = headers;
  }

  // eslint-disable-next-line class-methods-use-this
  errorInterceptor = (response: AjaxError): Observable<never> => {
    if (response.status === 401 && history.location.pathname !== ROUTES.LOGIN) {
      history.push(ROUTES.LOGIN, {
        apiStatus: 401,
        forceLeave: true,
      });
    }

    return throwError(response);
  };

  get$(appendixUrl: string) {
    return ajax({
      url: `${this.baseUrl}/${appendixUrl}`,
      headers: this.headers,
      method: 'GET',
      withCredentials: true,
    }).pipe(
      catchError(this.errorInterceptor),
    );
  }

  post$(appendixUrl: string, body: Record<string, unknown>) {
    Cookies.set(CSRF_TOKEN, uuidv4());

    return ajax({
      url: `${this.baseUrl}/${appendixUrl}`,
      headers: {
        ...this.headers,
        [`X-${CSRF_TOKEN}`]: Cookies.get(CSRF_TOKEN),
      },
      body,
      method: 'POST',
      withCredentials: true,
    }).pipe(
      catchError(this.errorInterceptor),
    );
  }

  put$(appendixUrl: string, body: Record<string, unknown>) {
    Cookies.set(CSRF_TOKEN, uuidv4());

    return ajax({
      url: `${this.baseUrl}/${appendixUrl}`,
      headers: {
        ...this.headers,
        [`X-${CSRF_TOKEN}`]: Cookies.get(CSRF_TOKEN),
      },
      body,
      method: 'PUT',
      withCredentials: true,
    }).pipe(
      catchError(this.errorInterceptor),
    );
  }
}
