import { RUNTIME_API_APPENDIX } from 'constants/baseUrl';
import { ENTITIES } from 'constants/entities';
import { HistoryFilterOptions } from '#shared/types/common';
import { buildURLQuery } from '#shared/utils/apiHelpers';

import { runtimeApi } from '.';

export const getProcessInstancesList = (requestParams: HistoryFilterOptions) => {
  const urlQuery = buildURLQuery(requestParams);
  return runtimeApi.get$(
    `${RUNTIME_API_APPENDIX}/${ENTITIES.PROCESS_INSTANCE_PLURAL}?${urlQuery}`,
  );
};

export const getProcessInstancesCount = () => {
  return runtimeApi.get$(`${RUNTIME_API_APPENDIX}/${ENTITIES.PROCESS_INSTANCE_PLURAL}/count`);
};
