import i18n from 'localization';
import { STATUSES } from 'reapop';
import { ERROR_TYPE } from '#shared/types/common';
import { ROUTES } from './routes';

export const processDefinitionFormNotFoundErrorProps = {
  message: i18n.t('startFormPage:errors.formNotFound.message'),
  isSystemError: true,
  componentProps: {
    description: i18n.t('startFormPage:errors.formNotFound.description'),
    backLink: ROUTES.PROCESS_LIST,
    backLinkTitle: i18n.t('startFormPage:errors.formNotFound.backLinkTitle'),
  },
};

export const processDefinitionNotFoundErrorProps = {
  message: i18n.t('startFormPage:errors.processNotFound.message'),
  isSystemError: true,
  componentProps: {
    description: i18n.t('startFormPage:errors.processNotFound.description'),
    backLink: ROUTES.PROCESS_LIST,
    backLinkTitle: i18n.t('startFormPage:errors.processNotFound.backLinkTitle'),
  },
};

export const formNotFoundErrorProps = {
  message: i18n.t('taskPage:errors.formNotFound.message'),
  isSystemError: true,
  componentProps: {
    description: i18n.t('taskPage:errors.formNotFound.description'),
    backLink: ROUTES.USER_TASK_LIST,
    backLinkTitle: i18n.t('taskPage:errors.formNotFound.backLinkTitle'),
  },
};

export const taskSuspendedErrorProps = {
  message: i18n.t('taskPage:errors.taskSuspended.message'),
  isSystemError: true,
  componentProps: {
    description: i18n.t('taskPage:errors.taskSuspended.description'),
    backLink: ROUTES.USER_TASK_LIST,
    backLinkTitle: i18n.t('taskPage:errors.taskSuspended.backLinkTitle'),
  },
};

export const notFoundErrorProps = {
  message: i18n.t('errors.notFound.message'),
  componentProps: {
    title: '404',
    description: i18n.t('errors.notFound.description'),
  },
};

export const defaultNotificationErrorProps = {
  message: i18n.t('errors.notification.default.message'),
  type: ERROR_TYPE.NOTIFICATION,
  notifyType: STATUSES?.error,
  componentProps: {
    title: i18n.t('errors.notification.default.title'),
  },
};

export const tryAgainNotificationErrorProps = {
  message: i18n.t('errors.notification.tryAgain.message'),
  type: ERROR_TYPE.NOTIFICATION,
  componentProps: {
    title: i18n.t('errors.notification.default.title'),
  },
};

export const conflictNotificationErrorProps = {
  type: ERROR_TYPE.NOTIFICATION,
  message: i18n.t('errors.notification.conflict.message'),
};

export const permissionNotificationErrorProps = {
  type: ERROR_TYPE.NOTIFICATION,
  message: i18n.t('errors.notification.permission.message'),
};

export const defaultCriticalErrorProps = {
  type: ERROR_TYPE.CRITICAL,
  isSystemError: true,
  componentProps: {
    hasRefreshBtn: true,
  },
};

export const forbiddenErrorProps = {
  message: i18n.t('errors.forbidden.message'),
  isSystemError: true,
  componentProps: {
    title: '403',
    description: i18n.t('errors.forbidden.description'),
    backLinkTitle: i18n.t('errors.forbidden.backToStart'),
    backLink: `${ROUTES.LOGOUT}`,
  },
};

export const rateLimitCriticalErrorProps = {
  type: ERROR_TYPE.CRITICAL,
  componentProps: {
    description: i18n.t('errors.rateLimit.description'),
    hasRefreshBtn: true,
    hideNavigation: true,
  },
};
