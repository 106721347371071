import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import {
  filter, map, mergeMap,
} from 'rxjs/operators';
import { of } from 'rxjs';

import * as api from 'api/taskManagement';
import { ERROR_TYPE } from '#shared/types/common';
import i18n from 'localization';
import { notify, STATUSES } from 'reapop';
import { tryAgainNotificationErrorProps } from 'constants/errorProps';
import { getCriticalErrorProps, catchError, getNotificationErrorProps } from '#shared/utils/apiHelpers';
import {
  getPendingTasksCountRequest,
  getPendingTasksCountError,
  getPendingTasksCountSuccess,
  getPendingTasksListRequest,
  getPendingTasksListSuccess,
  getPendingTasksListError,
  claimTaskRequest,
  claimTaskError,
  claimTaskSuccess,
  initialState,
} from './slice';

export const getPendingTasksCountEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$.pipe(
    filter(getPendingTasksCountRequest.match),
    mergeMap(() => {
      return api.getPendingTasksCount().pipe(
        map((response) => getPendingTasksCountSuccess(response.response.count)),
        catchError(({ response }) => of(getPendingTasksCountError({
          type: ERROR_TYPE.NOTIFICATION,
          traceId: response?.traceId,
          componentProps: {
            title: i18n.t('errors.notification.pendingTasksCount.title'),
          },
        }))),
      );
    }),
  );
};

export const getUserTasksListEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$.pipe(
    filter(getPendingTasksListRequest.match),
    mergeMap(({ payload }) => {
      const { filterParams, isInitialRequest } = payload;
      const {
        page,
        rowsPerPage,
        order,
        orderField,
      } = filterParams;
      const firstResult = page * rowsPerPage;
      return api.getPendingTasksList({
        firstResult,
        maxResults: rowsPerPage + 1,
        sortBy: orderField,
        sortOrder: order,
      }).pipe(
        mergeMap((response) => of(
          getPendingTasksListSuccess({
            list: response.response,
            filterParams,
          }),
        )),
        catchError((serverResponse) => of(getPendingTasksListError(
          isInitialRequest
            ? getCriticalErrorProps({ serverResponse })
            : getNotificationErrorProps(serverResponse.response, tryAgainNotificationErrorProps),
        ))),
      );
    }),
  );
};

export const claimUserTaskEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$.pipe(
    filter(claimTaskRequest.match),
    mergeMap(({ payload }) => {
      const { taskId, taskName } = payload;
      return api.claimTask(taskId).pipe(
        mergeMap(() => of(
          claimTaskSuccess(),
          getPendingTasksListRequest({ isInitialRequest: true, filterParams: initialState.filterParams }),
          notify(
            i18n.t(
              'userTaskListPage:components.pendingTasksTable.notification.success.message',
              { taskName },
            ),
            STATUSES.success,
          ),
        )),
        catchError((response) => {
          if (response.status === 404 || response.status === 409) {
            return of(
              getPendingTasksListRequest({ isInitialRequest: true, filterParams: initialState.filterParams }),
              claimTaskError({
                type: ERROR_TYPE.NOTIFICATION,
                traceId: response.response?.traceId,
                message: i18n.t(
                  'userTaskListPage:components.pendingTasksTable.notification.warning.message',
                  { taskName },
                ),
                notifyType: STATUSES.warning,
              }),
            );
          }
          return of(claimTaskError(
            getNotificationErrorProps(response.response, tryAgainNotificationErrorProps),
          ));
        }),
      );
    }),
  );
};
