import { createStyles } from '@material-ui/core';

import { spacing } from '#web-components/styles';

const styles = () => createStyles({
  root: {
    paddingTop: spacing * 16,
  },
  title: {
    marginBottom: spacing * 6,
  },
  caption: {
    marginTop: spacing * 2,
    display: 'block',
  },
});

export default styles;
