import { ignoreBuilderFields } from '#web-components/utils';
import customFormioFileSettings from '../CustomFormioFile/CustomFormioFileSettings';

export default () => customFormioFileSettings([
  {
    key: 'file',
    components: [
      ...ignoreBuilderFields([
        'image',
        'privateDownload',
        'uploadOnly',
      ]),
      {
        key: 'filePattern',
        overrideEditForm: true,
        defaultValue: '*.csv',
        ignore: true,
      },
    ],
  },
  {
    key: 'data',
    components: [
      ...ignoreBuilderFields(['multiple']),
    ],
  },
  {
    key: 'validation',
    components: [
      {
        type: 'textfield',
        key: 'resourceValidation',
        label: 'Resource for validation',
        input: true,
        weight: 100,
        overrideEditForm: true,
        validate: {
          required: true,
        },
      },
    ],
  },
  {
    key: 'table',
    components: [
      ...ignoreBuilderFields(['sortAsNumber']),
    ],
  },
]);
