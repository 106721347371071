import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as Icon } from '#web-components/assets/icons/backLeft.svg';

import styles from './BackLeftIcon.styles';

type BackLeftIconProps = {
  className?: string;
};

const useStyles = makeStyles(styles, { name: 'BackLeftIcon' });

export default function BackLeftIcon({ className }: BackLeftIconProps) {
  const classes = useStyles();
  return (
    <span className={clsx(classes.icon, className)}>
      <Icon />
    </span>
  );
}
