import React, { useMemo } from 'react';

import Input from '#web-components/components/FormControls/Input';
import CalendarIcon from '#web-components/components/Icons/CalendarIcon';
import { FormControlError } from '#web-components/types/formControls';
import { getLocaleDayPeriodRegexp } from '#web-components/utils';
import { DatePickerLocales } from '#web-components/components/Form/types';
import { ALL_LETTERS_REGEXP } from '#web-components/constants/regexp';

export type IconButtonProps = {
  name: string;
  id?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  inputDisabled?: boolean;
  tabIndex?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: FormControlError;
  description?: string;
  format: string;
  locale: DatePickerLocales;
};

function convertFormatToMask(format: string, locale: DatePickerLocales) {
  let tempFormat = format;
  const toInsertAfter = [];
  // for long month format, disable mask
  if (format.match(/M{4}/)) {
    return undefined;
  }

  if (tempFormat.match(/M{3}/)) {
    const index = format.match(/M{3}/)?.index as number;
    tempFormat = format.replace('MMM', '');
    toInsertAfter.push({
      index,
      entity: [ALL_LETTERS_REGEXP, ALL_LETTERS_REGEXP, ALL_LETTERS_REGEXP],
    });
  }
  const formatAfterMap = tempFormat.split('').map((character) => {
    if (character.match(/[ydhmsHMG]/)) {
      return /\d/;
    }
    if (character === 'a') {
      return getLocaleDayPeriodRegexp(locale);
    }
    return character;
  });
  toInsertAfter.forEach((el) => {
    formatAfterMap.splice(el.index, 0, el.entity);
  });
  return formatAfterMap.flat();
}

const DateTimePickerInput = ({
  id,
  value,
  onClick,
  onChange,
  onFocus,
  disabled,
  inputDisabled,
  name,
  tabIndex,
  placeholder,
  error,
  description,
  format,
  locale,
} : IconButtonProps, ref: React.Ref<HTMLInputElement>) => {
  const inputMask = useMemo(() => convertFormatToMask(format, locale), [format, locale]);

  return (
    <Input
      id={id}
      inputRef={ref}
      tabIndex={tabIndex}
      name={name}
      placeholder={placeholder}
      value={value}
      onClick={onClick}
      onFocus={onFocus}
      onChangeEvent={onChange}
      disabled={disabled || inputDisabled}
      inputMask={inputMask}
      suffix={<CalendarIcon />}
      error={error}
      description={description}
      forceShowPlaceholder
    />
  );
};

export default React.forwardRef(DateTimePickerInput);
