import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import {
  circle,
  circleMarker,
  control,
  Control,
  divIcon,
  featureGroup,
  FeatureGroup,
  GeoJSON,
  geoJSON,
  map,
  marker,
  tileLayer,
  Util,
  Marker,
  Circle,
  markerClusterGroup,
} from 'leaflet';
import { Observable } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import * as geojson from 'geojson';
import { FeatureCollection, GeometryObject } from 'geojson';
import forEach from 'lodash/forEach';
import 'leaflet-search';
import 'leaflet-draw';
import 'leaflet.markercluster';
import 'leaflet.featuregroup.subgroup';

import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-search/src/leaflet-search.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import shadowIconImg from '#web-components/assets/icons/marker-shadow.png';
import { whiteTheme } from '#web-components/styles';
import Input from '#web-components/components/FormControls/Input';
import { FormControlError } from '#web-components/types/formControls';
import FieldError from '#web-components/components/FieldError';
import DescriptionBox from '#web-components/components/DescriptionBox';
import {
  GeocodeOptions,
  GeometryValue,
  LayerConfig,
  MapMode,
  OverlayConfig,
} from './types';
import { convertFromGeometry, getGeometryValue } from './utils';
import styles from './Map.styles';

const defaultColor = whiteTheme.colors.layoutBackgroundPrimary;
const MAX_MAP_BBOX = '-180,-90,180,90';

export type MapProps = {
  value?: Array<GeometryValue> | GeometryValue;
  name: string;
  disabled?: boolean;
  singleValue?: boolean;
  mode?: MapMode;
  error?: FormControlError;
  overlaySearchProp: string;
  searchZoom: number;
  id: string;
  layerConfigList?: Array<LayerConfig>;
  overlayConfigList?: Array<OverlayConfig>;
  geocodeSearch?: { url: string, placeholder: string, showRadiusSettings?: boolean };
  description?: string;
  loadGeoJSON: (url: string) => Observable<AjaxResponse<unknown>>;
  onChange?: (value: Array<GeometryValue> | GeometryValue) => void;
} & (
  ({ geocodeEnabled?: false; } & Partial<GeocodeOptions>)
  | ({ geocodeEnabled: true; } & GeocodeOptions)
);

const useStyles = makeStyles(styles);

const Map: React.FC<MapProps> = (props: MapProps) => {
  const {
    singleValue,
    value = [],
    layerConfigList = [],
    overlayConfigList = [],
    loadGeoJSON,
    id,
    name,
    error,
    overlaySearchProp,
    searchZoom,
    disabled,
    mode = MapMode.edit,
    geocodeSearch,
    geocodeEnabled,
    geocodeSearchZoom,
    geocodeMarkerRadius,
    description,
    onChange = () => { },
  } = props;
  const valueArray = useMemo(() => {
    if (mode === MapMode.select) {
      return [];
    }
    return getGeometryValue(value, singleValue);
  }, [singleValue, value, mode]);
  const classes = useStyles();
  const editableLayers = useRef<FeatureGroup>(new FeatureGroup());
  const mapElId = `map-${id}`;
  const geocodeSearchId = `search-field-${id}`;
  const [customSearchRadius, onCustomRadiusChange] = useState(geocodeMarkerRadius || 200);
  const createMarkerIcon = (color?: string) => {
    return divIcon({
      // TODO: use svg import here after transition of this component to React. Not feasible otherwise.
      html: `
      <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
      <path d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5c-1.38
      0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" fill="${color || defaultColor}"></path>
      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24
      5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><circle cx="12" cy="9" r="2.5"></circle></svg>
      `,
      shadowUrl: shadowIconImg,
      iconSize: [40, 40],
      iconAnchor: [20, 20],
      className: '',
    });
  };
  const searchPoint = useRef<Marker>(marker({ lat: 0, lng: 0 }, { icon: createMarkerIcon() }));
  const searchCircle = useRef<Circle>(circle([0, 0], {
    radius: customSearchRadius,
  }));

  useEffect(() => {
    editableLayers.current.clearLayers();
    if (Array.isArray(valueArray)) {
      valueArray.forEach((geoParams) => {
        geoJSON(geoParams, {
          pointToLayer: (feature: never, latlng: never) => marker(latlng, { icon: createMarkerIcon() }),
          onEachFeature(feature, layer) {
            editableLayers.current.addLayer(layer);
          },
        });
      });
    }
  }, [valueArray]);

  useEffect(() => {
    if (!document.getElementById(mapElId)) {
      return;
    }
    const baseMapConfig = layerConfigList.reduce((result, current) => {
      return {
        ...result,
        [current.name]: tileLayer(current.url, {
          attribution: current.attribution,
          maxZoom: current.maxZoom || 20,
          maxNativeZoom: current.maxZoom,
        }),
      };
    }, {});
    const defaultLayer = layerConfigList[0] && get(baseMapConfig, layerConfigList[0].name);
    const mapInstance = map(mapElId, {
      center: [50, 32],
      zoom: 7,
      layers: defaultLayer ? [defaultLayer] : undefined,
    });
    // // eslint-disable-next-line no-underscore-dangle
    // mapInstance._layersMaxZoom = 19;
    const markerClusters = markerClusterGroup({});
    const overlayMapConfig = overlayConfigList.reduce((result, current) => {
      return {
        ...result,
        [current.name]: featureGroup.subGroup(markerClusters),
      };
    }, {});
    const searchGroup = featureGroup(Object.values(overlayMapConfig));

    control.layers(baseMapConfig, overlayMapConfig).addTo(mapInstance);
    mapInstance.addLayer(editableLayers.current);
    mapInstance.addLayer(markerClusters);

    const onEachFeature = (config: OverlayConfig) => (feature: geojson.Feature, layer: GeoJSON) => {
      // TODO: Temp solution. Redo into react when we are ready to use react-leaflet
      const propertiesList = (config.detailParamsList || []).map((param) => (
        `<li>${param.title}: ${get(feature.properties, param.path)}</li>`
      )).join('');
      layer
        .bindPopup(`<h4>${get(feature.properties, config.detailsTitlePath)}</h4><ul>${propertiesList}</ul>`, {
          closeButton: false,
        })
        .on('popupopen', (event) => {
          if (mode === MapMode.select) {
            const selectedFeature = event.target.feature;
            const newValue = convertFromGeometry({ features: [selectedFeature] });
            if (newValue.length) {
              onChange({
                ...newValue[0],
                properties: {
                  ...selectedFeature.properties,
                  id: selectedFeature.id ? selectedFeature.id.split('.')[1] : '',
                },
              });
            }
          }
        });
    };

    const updateOverlays = (overlayTypeMap: Array<OverlayConfig>) => {
      forEach(overlayMapConfig, (overlayLayer: FeatureGroup, layerName) => {
        const config = overlayTypeMap.find((overlay) => overlay.name === layerName);
        const type = config?.type;
        const url = config?.url;
        if (!type || !url) {
          return;
        }

        const defaultParameters = {
          service: 'WFS',
          version: '1.1.0',
          request: 'getFeature',
          typeName: type,
          outputFormat: 'application/json',
        };

        const customParams = {
          bbox: MAX_MAP_BBOX,
        };
        const parameters = Util.extend(defaultParameters, customParams);
        const bboxFormat = ',EPSG:4326';

        loadGeoJSON(`${url}${Util.getParamString(parameters)}${bboxFormat}`).subscribe(
          (data) => {
            overlayLayer.clearLayers();
            geoJSON(data.response as geojson.GeoJsonObject, {
              onEachFeature: onEachFeature(config),
              pointToLayer: (feature: never, latlng: never) => {
                return marker(latlng, { icon: createMarkerIcon(config?.color) });
              },
            }).addTo(overlayLayer);
          },
        );
      });
    };

    const handleChange = () => {
      const newValue = convertFromGeometry(editableLayers.current.toGeoJSON() as FeatureCollection<GeometryObject>);
      onChange(singleValue ? newValue[newValue.length - 1] || {} : newValue);
    };
    mapInstance.whenReady(() => {
      updateOverlays(overlayConfigList);
    });
    mapInstance.on('overlayremove', (event) => {
      searchGroup.removeLayer(event.layer);
    });
    mapInstance.on('overlayadd', (event) => {
      searchGroup.addLayer(event.layer);
    });
    mapInstance.on('draw:created', (e) => {
      editableLayers.current.addLayer(e.layer);
      handleChange();
    });
    mapInstance.on('draw:edited', () => {
      handleChange();
    });
    mapInstance.on('draw:deleted', () => {
      handleChange();
    });

    const searchOption = {
      propertyName: overlaySearchProp,
      propertyLoc: ['lat', 'lon'],
      autoCollapse: true,
      autoType: true,
      initial: false,
      firstTipSubmit: true,
      zoom: searchZoom,
      minLength: 2,
      layer: searchGroup,
      casesensitive: false,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const searchControl = new Control.Search({
      ...searchOption,
      filterData: (text: string, records: Record<string, unknown>) => {
        const frecords: Record<string, unknown> = {};

        if (text === '') {
          return [];
        }

        const I = searchOption.initial ? '^' : ''; // search only initial text
        const icase = !searchOption.casesensitive ? 'i' : undefined;
        const regSearch = new RegExp(I + text, icase);

        // eslint-disable-next-line no-restricted-syntax
        for (const key in records) {
          if (regSearch.test(key)) {
            frecords[key] = records[key];
          }
        }

        return frecords;
      },
    });
    const drawControl = new Control.Draw({
      draw: {
        marker: {
          icon: createMarkerIcon(),
        },
        rectangle: false,
        circle: false,
        circlemarker: false,
      },
      edit: {
        featureGroup: editableLayers.current,
        remove: true,
      },
    });
    mapInstance.addControl(searchControl);
    if (geocodeEnabled && geocodeSearch) {
      searchCircle.current.on({
        move: (event) => {
          const eventLatLng = get(event, 'latlng');
          if (eventLatLng) {
            searchPoint.current.addTo(mapInstance);
            searchPoint.current.setLatLng(eventLatLng);
          } else {
            searchPoint.current.removeFrom(mapInstance);
          }
        },
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mapInstance.addControl(new Control.Search({
        textPlaceholder: geocodeSearch?.placeholder,
        url: geocodeSearch?.url,
        container: geocodeSearchId,
        jsonpParam: 'json_callback',
        propertyName: 'display_name',
        collapsed: false,
        propertyLoc: ['lat', 'lon'],
        marker: geocodeSearch.showRadiusSettings
          ? searchCircle.current
          : circleMarker([0, 0], { radius: geocodeMarkerRadius }),
        autoCollapse: false,
        autoType: false,
        firstTipSubmit: true,
        minLength: 2,
        zoom: geocodeSearchZoom,
      }));
    }
    if (!disabled && mode === MapMode.edit) {
      mapInstance.addControl(drawControl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div id={geocodeSearchId} className={classes.searchField} />
      {geocodeSearch && geocodeSearch.showRadiusSettings && (
        <Input
          className={classes.customRadius}
          name="custom-radius"
          value={customSearchRadius}
          onChange={(val) => {
            searchCircle.current.setRadius(val as number);
            onCustomRadiusChange(val as number);
          }}
          withNumberFormat
        />
      )}
      <div id={mapElId} className={classes.root} data-xpath={name} />
      {error && (
        <FieldError error={error} />
      )}
      {description && <DescriptionBox description={description} />}
    </>
  );
};

export default Map;
