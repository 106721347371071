import { createStyles } from '@material-ui/core';
import { spacing } from '#web-components/styles';

const styles = () => createStyles({
  button: {
    marginTop: spacing,
    display: 'inline-block',
  },
});

export default styles;
