import { HistoryFilterOptions } from '#shared/types/common';
import { NOTIFICATIONS_API_APPENDIX } from 'constants/baseUrl';
import { buildURLQuery } from '#shared/utils/apiHelpers';
import { notificationsApi } from '.';

export const getNotificationList = (requestParams: Omit<HistoryFilterOptions, 'sort'>) => {
  const urlQuery = buildURLQuery(requestParams);
  return notificationsApi.get$(
    `${NOTIFICATIONS_API_APPENDIX}/inbox?${urlQuery}`,
  );
};

export const acknowledgeNotification = (notificationId:string) => {
  return notificationsApi.post$(`${NOTIFICATIONS_API_APPENDIX}/inbox/${notificationId}/ack`, {});
};
