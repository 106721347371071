import { createStyles } from '@material-ui/core';

import { spacing } from '#web-components/styles';

const styles = () => createStyles({
  root: {
    marginTop: spacing * 4,
  },
});

export default styles;
