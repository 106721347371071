import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';
import { createAsyncActionErrorSelector, createAsyncActionIsLoadingSelector } from '../asyncAction';
import { getCompletedTasksListRequest } from './slice';

export const selectCompletedTasksState = (state: RootState) => state.completedTasks;
export const selectCompletedTasksList = createSelector(selectCompletedTasksState, (state) => state.list);
export const selectCompletedTasksCount = createSelector(selectCompletedTasksState, (state) => state.count);
export const selectCompletedTasksFilterParams = createSelector(
  selectCompletedTasksState,
  (state) => state.filterParams,
);
export const selectCompletedTasksIsLoading = createAsyncActionIsLoadingSelector(getCompletedTasksListRequest.type);
export const selectCompletedTasksError = createAsyncActionErrorSelector(getCompletedTasksListRequest.type);
