import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import Typography from '#web-components/components/Typography';
import Divider from '#web-components/components/Divider';
import { formatUTCDateTime } from '#web-components/utils';
import InlineButton from '#web-components/components/InlineButton';
import { ChevronDownIcon } from '#web-components/components/Icons';

import styles from './NotificationItem.styles';

const useStyles = makeStyles(styles, { name: 'NotificationItem' });

export interface Notification {
  id: string;
  isAcknowledged: boolean;
  subject: string;
  message: string;
  createdAt: string;
}

type Props = {
  notification: Notification;
  onOpen: (notification: Notification) => void;
  localization: {
    newOne: string;
    hide: string;
    show: string;
  },
};

function NotificationItem({
  notification, onOpen, localization,
}: Props) {
  const classes = useStyles();
  const {
    subject, message, isAcknowledged, createdAt,
  } = notification;
  const [isOpened, setIsOpened] = useState(false);

  const toggleItem = () => {
    setIsOpened((prev) => {
      if (!prev) onOpen(notification);
      return !prev;
    });
  };

  return (
    <div className={clsx(classes.root, isOpened && classes.itemOpened)}>
      <Divider />
      <div className={classes.titleWrapper}>
        <Typography variant="tinyText">{formatUTCDateTime(createdAt)}</Typography>
        {!isAcknowledged
        && <Typography variant="labels" className={classes.label}>{localization.newOne}</Typography>}
      </div>
      <div className={clsx(classes.messageWrapper, !isOpened && classes.messageWrapperClosed)}>
        <Typography variant="h3">{subject}</Typography>
        <Typography variant="h7" className={classes.message}>{message}</Typography>
      </div>

      <InlineButton
        size="medium"
        leftIcon={<ChevronDownIcon className={isOpened ? classes.iconActive : ''} />}
        onLinkClick={toggleItem}
      >
        {isOpened ? localization.hide : localization.show}
      </InlineButton>
    </div>
  );
}

export default NotificationItem;
