import { DateTime } from 'luxon';
import isPlainObject from 'lodash/isPlainObject';
import find from 'lodash/find';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import { isEmpty } from 'lodash';
import { currentTimezone, momentDate, convertFormatToMoment } from 'formiojs/utils/utils';

import {
  DateTimeComponent,
  FormComponent,
  FormioComponentName,
  NumberComponent,
  RadioComponent,
  SelectComponent,
} from '#web-components/components/Form/types';
import { I18N_SEPARATOR } from '#web-components/components/Form/constants';
import CommonFormioComponent from '#web-components/components/Form/formioComponents/CommonFormioComponent';

export default abstract class CommonGridComponent extends CommonFormioComponent {
  selectItemTemplate(item: unknown, component: FormComponent) {
    const data = item as Record<string, unknown> | string;
    const selectComponent = component as SelectComponent;

    if (isEmpty(data)) {
      return '';
    }
    if (typeof data === 'string') {
      return this.t(data);
    }
    if (data && !selectComponent.template) {
      const itemLabel = data.label || data;
      return (typeof itemLabel === 'string') ? this.t(itemLabel) : itemLabel;
    }

    const template = this.sanitize(
      selectComponent.template ? this.interpolate(selectComponent.template, { item: data }) : data.label,
    );
    if (template) {
      const div = document.createElement('div');
      div.innerHTML = template;
      const label = div.textContent;

      if (!label || !this.t(label)) {
        return '';
      }
      return this.t(label);
    }

    return JSON.stringify(data);
  }

  dateTimeValueConverter = (value: unknown, component: FormComponent) => {
    const dateTimeComponent = component as DateTimeComponent;
    const dateTimeValue = value as string;
    const date = DateTime.fromISO(dateTimeValue || '');

    if (!dateTimeValue) {
      return '';
    }

    return date.isValid
      ? date.toFormat(dateTimeComponent.format)
      : this.t(`customFormioComponents${I18N_SEPARATOR}dateTime${I18N_SEPARATOR}invalidDate`);
  };

  checkboxValueConverter = (value: unknown) => {
    return value
      ? this.i18next.t(`customFormioComponents${I18N_SEPARATOR}checkbox${I18N_SEPARATOR}optionTrue`)
      : this.i18next.t(`customFormioComponents${I18N_SEPARATOR}checkbox${I18N_SEPARATOR}optionFalse`);
  };

  numberValueConverter = (value: unknown, component: FormComponent) => {
    const numberComponent = component as unknown as NumberComponent;
    const { delimiter, decimalLimit, requireDecimal } = numberComponent;

    return (value as number).toLocaleString('uk-UA', {
      useGrouping: delimiter,
      maximumFractionDigits: decimalLimit,
      minimumFractionDigits: requireDecimal ? decimalLimit : undefined,
    });
  };

  radioValueConverter = (value: unknown, component: FormComponent) => {
    const radioComponent = component as RadioComponent;
    if (value === null || value === undefined) {
      return '';
    }
    const normalizedValue = String(value);
    const option = find(radioComponent.values, (v) => v.value === normalizedValue);

    return get(option, 'label', '');
  };

  selectValueConverter = (value: unknown, component: FormComponent) => {
    const data = value as Record<string, unknown>;
    return (component.multiple && Array.isArray(data))
      ? data.map((item) => this.selectItemTemplate(item, component)).join(', ')
      : this.selectItemTemplate(data, component);
  };

  dayValueConverter = (value: unknown) => {
    return value;
  };

  legacyDateTimeConverter = (value: unknown, component: FormComponent) => {
    const dateTimeComponent = component as DateTimeComponent;
    const format = convertFormatToMoment(dateTimeComponent.format);
    return (value ? momentDate(value, '', currentTimezone()).format(format) : value) || '';
  };

  legacyFileConverter = (value: unknown) => {
    if (isArray(value)) {
      return map(value, 'originalName').join(', ');
    }

    return get(value, 'originalName', '');
  };

  valueConverterMap: Record<string, (value: unknown, component: FormComponent) => unknown> = {
    [FormioComponentName.datetimeLatest]: this.dateTimeValueConverter,
    [FormioComponentName.checkboxLatest]: this.checkboxValueConverter,
    [FormioComponentName.radioLatest]: this.radioValueConverter,
    [FormioComponentName.numberLatest]: this.numberValueConverter,
    [FormioComponentName.fileLatest]: () => '',
    [FormioComponentName.selectLatest]: this.selectValueConverter,
    [FormioComponentName.map]: () => '',

    [FormioComponentName.number]: this.numberValueConverter,
    [FormioComponentName.checkbox]: this.checkboxValueConverter,
    [FormioComponentName.select]: this.selectValueConverter,
    [FormioComponentName.radio]: this.radioValueConverter,
    [FormioComponentName.datetime]: this.legacyDateTimeConverter,
    [FormioComponentName.button]: () => '',
    [FormioComponentName.file]: this.legacyFileConverter,
  };

  prepareGridDisplayValue = (value: unknown, component: FormComponent) => {
    const converter = this.valueConverterMap[component.type];

    if (converter) {
      return converter(value, component);
    }
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    if (isPlainObject(value)) {
      return JSON.stringify(value);
    }
    if (value === null || value === undefined) {
      return '';
    }

    return value;
  };
}
