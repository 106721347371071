import baseColumnsForm from 'formiojs/components/columns/Columns.form';
import { ignoreBuilderFields } from '#web-components/utils';
import { defaultConditionalFields, defaultApiFields } from '#web-components/components/Form/constants';

const datagridDescription = [
  '<ol>',
  '<li>Область, в якій розміщено елемент «Columns», ділиться на 12 частин.</li>',
  '<li>Ширина кожної колонки задається в цих частинах.</li>',
  '<li>Offset – відступ на початку колонки.</li>',
  '</ol>',

  'Приклад:',

  '<ol>',
  '<li>Якщо ширину першої колонки задати 5, а другої 7, '
  + 'то перша колонка займе 5/12 ширини контейнера, а друга - 7/12 ширини контейнера.</li>',
  '<li>Якщо задати для другої колонки offset=1, а width=6, '
  + 'то між першою та другою колонками буде відступ в 1/12 контейнера.</li>',
  '</ol>',

  'Рекомендується задавати width и offset таким чином, щоб їх сума була рівна 12. '
  + 'Якщо сума буде більша, то колонки, які не вміщуються по ширині, будуть перенесені на наступний рядок.',
].join('\n');

export default () => {
  return baseColumnsForm([
    ...ignoreBuilderFields(['layout', 'logic']),
    {
      key: 'display',
      components: [
        ...ignoreBuilderFields(
          ['customClass', 'modalEdit', 'autoAdjust', 'hideLabel'],
        ),
        {
          weight: 150,
          type: 'datagrid',
          input: true,
          key: 'columns',
          label: 'Column Properties',
          description: datagridDescription,
          addAnother: 'Add Column',
          tooltip: 'The width, offset, push, and pull settings for each column.',
          reorder: true,
          components: [
            {
              type: 'hidden',
              key: 'components',
              defaultValue: [],
            },
            {
              type: 'select',
              key: 'size',
              defaultValue: 'md',
              ignore: true,
            },
            {
              type: 'number',
              key: 'width',
              defaultValue: 6,
              label: 'Width',
            },
            {
              type: 'number',
              key: 'offset',
              defaultValue: 0,
              label: 'Offset',
            },
            {
              type: 'number',
              key: 'push',
              defaultValue: 0,
              label: 'Push',
              ignore: true,
            },
            {
              type: 'number',
              key: 'pull',
              defaultValue: 0,
              label: 'Pull',
              ignore: true,
            },
          ],
        },
      ],
    },
    {
      key: 'api',
      components: [
        ...ignoreBuilderFields(['tags', 'properties']),
        ...defaultApiFields,
      ],
    },
    {
      key: 'conditional',
      components: defaultConditionalFields,
    },
  ]);
};
