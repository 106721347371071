import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import useAuthentication from 'hooks/useAuthentication';

interface PrivateRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ElementType;
  roles: string[];
}

const ANY_ROLE_ALLOWED = '*';

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, roles, ...rest }) => {
  const authentication = useAuthentication();
  const isAuthenticated = (): boolean => authentication.authenticated;

  const isAuthorized = (rolesToCheck: string[]): boolean => {
    return rolesToCheck.some((r) => (r === ANY_ROLE_ALLOWED || authentication.hasRealmRole(r)));
  };

  return (
    <Route
      {...rest}
      render={(props): JSX.Element => {
        if (isAuthenticated()) {
          return isAuthorized(roles) ? <Component {...props} /> : <Redirect to={{ pathname: ROUTES.FORBIDDEN }} />;
        }
        return <Redirect to={{ pathname: ROUTES.LOGIN }} />;
      }}
    />
  );
};

export default PrivateRoute;
