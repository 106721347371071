import React, { FunctionComponent } from 'react';
import { makeStyles, Box } from '@material-ui/core';

import { ColumnDefinition, ListItem } from '#web-components/types/table';
import FileIcon from '#web-components/components/Icons/FileIcon/FileIcon';
import { textFormatter } from '#web-components/components/Table/utils/formatters';
import Typography from '#web-components/components/Typography';
import InlineButton from '#web-components/components/InlineButton';
import BackLeftIcon from '#web-components/components/Icons/BackLeftIcon/BackLeftIcon';

import { EntityItem, EntityGroup } from '#web-components/types/groupedEntity';

import Table from '../EntityTable';
import styles from './EntityGroup.styles';

const useStyles = makeStyles(styles, { name: 'EntityGroupComponent' });

export interface EntityGroupComponentProps {
  group: EntityGroup,
  readonly?: boolean,
  backToGroups: () => void,
  emptyPlaceholder: string,
  onEntitySelect?: (item: ListItem) => void,
  existingGroupNames?: string[],
  'data-xpath'?: {
    backToGroups?: string,
  },
  GroupActions?: FunctionComponent<{ item: EntityGroup, unselectGroup: () => void }>,
  EntityTableActions?: FunctionComponent<{
    item: ListItem,
    list: Array<EntityItem>,
    groupName?: string,
    existingGroupNames: string[],
    moveToUngroupedEnable?: boolean,
  }>,
}

export default function EntityGroupComponent(props: EntityGroupComponentProps) {
  const {
    group,
    readonly,
    backToGroups,
    emptyPlaceholder,
    existingGroupNames,
    EntityTableActions,
    GroupActions,
    onEntitySelect,
    'data-xpath': dataXpath,
  } = props;
  const classes = useStyles(props);
  const columnDefinitions: ColumnDefinition[] = [
    {
      title: '',
      property: 'name',
      sortable: false,
      cellClass: classes.titleCellClass,
      shouldMemoize: true,
      headerCellClass: classes.cellClass,
      // TODO: Declare this component outside parent component or memoize it.
      //  If you want to allow component creation in props, set allowAsProps option to true
      // eslint-disable-next-line react/no-unstable-nested-components
      Component({ item, columnDefinition: { property } }) {
        return (
          <Box
            className={classes.cellName}
            onClick={() => onEntitySelect && onEntitySelect(item)}
          >
            <FileIcon size={24} className={classes.icon} />
            <Typography variant="h5">{textFormatter(item, property)}</Typography>
          </Box>
        );
      },
    },
    {
      title: '',
      property: '',
      sortable: false,
      cellClass: classes.cellClass,
      headerCellClass: classes.cellClass,
      // TODO: Declare this component outside parent component or memoize it.
      //  If you want to allow component creation in props, set allowAsProps option to true
      // eslint-disable-next-line react/no-unstable-nested-components
      Component({ item }) {
        if (EntityTableActions) {
          return (
            <EntityTableActions
              item={item}
              list={group.entityList || []}
              existingGroupNames={existingGroupNames || []}
              groupName={group.name}
              moveToUngroupedEnable
            />
          );
        }
        return null;
      },
    },
  ];

  return (
    <>
      <Box className={classes.title}>
        <InlineButton
          size="huge"
          leftIcon={<BackLeftIcon />}
          onLinkClick={backToGroups}
          data-xpath={dataXpath?.backToGroups}
        >
          {group.name}
        </InlineButton>
        {(!readonly && GroupActions) && (
          <GroupActions item={group} unselectGroup={backToGroups} />
        )}
      </Box>
      <Table
        columnDefinitions={readonly ? [columnDefinitions[0]] : columnDefinitions}
        list={group?.entityList || []}
        emptyPlaceholder={emptyPlaceholder}
        hideHeader
      />
    </>
  );
}
