export const X_PATH = {
  /* header */
  header: 'header',
  headerLinks: 'headerLinks',
  headerUserInfo: 'headerUserInfo',
  homeButton: 'homeButton',
  logout: 'logoutButton',

  /* table */
  tableFirstPage: 'tableFirstPage',
  tableNextPage: 'tableNextPage',
  tablePrevPage: 'tablePrevPage',
  tableLastPage: 'tableLastPage',
  tablePageCountSelect: 'tablePageCountSelect',
  tableContainer: 'tableContainer',
  tableRow: 'tableRow',
  tableHeadCell: 'tableHeadCell',

  /* main page */
  processDefinitionOption: 'processDefinitionMenuOption',
  processActiveOption: 'processActiveMenuOption',
  taskOption: 'taskMenuOption',
  reportOption: 'reportMenuOption',

  /* Login page */
  loginButton: 'loginButton',

  /* Task page */
  changeKeyButton: 'changeKeyButton',
  signButton: 'signButton',
  validationMessage: 'validationMessage',
  validationMessageTitle: 'validationMessageTitle',

  /* other */
  tabsButton: 'tabsButton',
  loader: 'loader',
  processDefinitionCreateLink: 'processDefinitionCreateLink',

  /* Reports page */
  reportsLinkButton: 'reportsLinkButton',
  downloadExcerpt: 'downloadExcerpt',

  /* Process Definitions page */
  backToGroups: 'backToGroups',
};
