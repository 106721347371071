import { createStyles, Theme } from '@material-ui/core';

const styles = ({ colors, spacing }: Theme) => createStyles({
  container: {
    marginBottom: spacing(5),
  },
  message: {
    color: colors.textErrors,
    marginBottom: spacing(4),
  },
});

export default styles;
