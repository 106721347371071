/* eslint-disable @typescript-eslint/no-explicit-any */
import WebformBuilder from 'formiojs/WebformBuilder';
import Webform from 'formiojs/Webform';
import { getRandomComponentId, fastCloneDeep } from 'formiojs/utils/utils';
import BuilderUtils from 'formiojs/utils/builder';
import { Components } from 'formiojs';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import get from 'lodash/get';

export class Builder extends WebformBuilder {
  destroy(deleteFromGlobal: boolean) {
    if (this.dialog) {
      this.dialog.close();
    }
    super.destroy(deleteFromGlobal);
  }

  highlightInvalidComponents() {
    const repeatablePaths = this.findRepeatablePaths();
    let hasInvalidComponents = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.webform.everyComponent((comp: { setCustomValidity?: any; key?: any; error?: any; path?: any; }) => {
      const { path } = comp;
      if (repeatablePaths.includes(path)) {
        comp.setCustomValidity(`${this.t('APIKeyNotUniqueError')}: ${comp.key}`);
        hasInvalidComponents = true;
      } else if (comp.error?.message?.startsWith(`${this.t('APIKeyNotUniqueError')}`)) {
        comp.setCustomValidity('');
      }
    });

    this.emit('builderFormValidityChange', hasInvalidComponents);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editComponent(
    component: { id: any; key: string },
    parent: any,
    isNew: any,
    isJsonEdit: any,
    original: { id: any; },
    flags: any,
  ) {
    // eslint-disable-next-line no-param-reassign
    component.id = component.id || original?.id || getRandomComponentId();

    // NEXT LINES ARE COPIED FROM FORMIO SOURCE
    if (!component.key) {
      return;
    }
    let saved = false;
    const componentCopy = fastCloneDeep(component);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let ComponentClass = Components.components[componentCopy.type];
    const isCustom = ComponentClass === undefined;
    // eslint-disable-next-line no-param-reassign
    isJsonEdit = isJsonEdit || isCustom;
    ComponentClass = isCustom ? Components.components.unknown : ComponentClass;
    // Make sure we only have one dialog open at a time.
    if (this.dialog) {
      this.dialog.close();
      this.highlightInvalidComponents();
    }

    // This is the render step.
    const editFormOptions: any = clone(get(this, 'options.editForm', {}));
    if (this.editForm) {
      this.editForm.destroy();
    }

    // Allow editForm overrides per component.
    const overrides = get(this.options, `editForm.${componentCopy.type}`, {});

    // Pass along the form being edited.
    editFormOptions.editForm = this.form;
    editFormOptions.editComponent = component;
    editFormOptions.flags = flags;
    this.editForm = new Webform(
      {
        ...omit(this.options, ['hooks', 'builder', 'events', 'attachMode', 'skipInit']),
        language: this.options.language,
        ...editFormOptions,
      },
    );

    this.editForm.form = (isJsonEdit && !isCustom) ? {
      components: [
        {
          type: 'textarea',
          as: 'json',
          editor: 'ace',
          weight: 10,
          input: true,
          key: 'componentJson',
          label: 'Component JSON',
          tooltip: 'Edit the JSON for this component.',
        },
        {
          type: 'checkbox',
          key: 'showFullSchema',
          label: 'Full Schema',
        },
      ],
    } : ComponentClass.editForm(cloneDeep(overrides));
    const instance = new ComponentClass(componentCopy);
    this.editForm.submission = isJsonEdit ? {
      data: {
        componentJson: component,
        showFullSchema: this.options.showFullJsonSchema,
      },
    } : {
      data: instance.component,
    };

    if (this.preview) {
      this.preview.destroy();
    }
    // eslint-disable-next-line no-prototype-builtins
    if (!ComponentClass.builderInfo.hasOwnProperty('preview') || ComponentClass.builderInfo.preview) {
      this.preview = new Webform(omit({ ...this.options, preview: true }, [
        'hooks',
        'builder',
        'events',
        'attachMode',
        'calculateValue',
      ]));
    }

    this.componentEdit = this.ce('div', { class: 'component-edit-container' });
    this.setContent(this.componentEdit, this.renderTemplate('builderEditForm', {
      componentInfo: ComponentClass.builderInfo,
      editForm: this.editForm.render(),
      preview: this.preview ? this.preview.render() : false,
      helplinks: this.helplinks,
    }));

    this.dialog = this.createModal(this.componentEdit, get(this.options, 'dialogAttr', {}));

    // This is the attach step.
    this.editForm.attach(this.componentEdit.querySelector('[ref="editForm"]'));
    this.updateComponent(componentCopy);

    this.editForm.on('change', (event: any) => {
      if (event.changed) {
        if (event.changed.component && event.changed.component.key === 'showFullSchema') {
          const { value } = event.changed;
          this.editForm.submission = {
            data: {
              componentJson: value ? instance.component : component,
              showFullSchema: value,
            },
          };
          return;
        }
        // See if this is a manually modified key. Treat custom component keys as manually modified
        if ((event.changed.component && (event.changed.component.key === 'key')) || isJsonEdit) {
          componentCopy.keyModified = true;
        }

        if (event.changed.component && (['label', 'title'].includes(event.changed.component.key))) {
          // Ensure this component has a key.
          if (isNew) {
            if (this.form) {
              let formComponents = this.findNamespaceRoot(parent.formioComponent);
              // excluding component which key uniqueness is to be checked to prevent the comparing of the same keys
              formComponents = formComponents.filter((comp: any) => editFormOptions.editComponent.id !== comp.id);

              // Set a unique key for this component.
              BuilderUtils.uniquify(formComponents, event.data);
            }
          }
        }

        // Update the component.
        this.updateComponent(event.data.componentJson || event.data, event.changed);
      }
    });
    this.addEventListener(this.componentEdit.querySelector('[ref="cancelButton"]'), 'click', (event: any) => {
      event.preventDefault();
      this.editForm.detach();
      this.emit('cancelComponent', component);
      this.dialog.close();
      this.highlightInvalidComponents();
    });

    this.addEventListener(this.componentEdit.querySelector('[ref="removeButton"]'), 'click', (event: any) => {
      event.preventDefault();
      // Since we are already removing the component, don't trigger another remove.
      saved = true;
      this.editForm.detach();
      this.removeComponent(component, parent, original);
      this.dialog.close();
      this.highlightInvalidComponents();
    });

    // eslint-disable-next-line consistent-return
    this.addEventListener(this.componentEdit.querySelector('[ref="saveButton"]'), 'click', (event: any) => {
      event.preventDefault();
      if (!this.editForm.checkValidity(this.editForm.data, true, this.editForm.data)) {
        this.editForm.setPristine(false);
        this.editForm.showErrors();
        return false;
      }
      saved = true;
      this.saveComponent(component, parent, isNew, original);
    });

    const dialogClose = () => {
      this.editForm.destroy(true);
      if (this.preview) {
        this.preview.destroy(true);
        this.preview = null;
      }
      if (isNew && !saved) {
        this.removeComponent(component, parent, original);
        this.highlightInvalidComponents();
      }
      // Clean up.
      this.removeEventListener(this.dialog, 'close', dialogClose);
      this.dialog = null;
    };
    this.addEventListener(this.dialog, 'close', dialogClose);

    // Called when we edit a component.
    this.emit('editComponent', component);
  }
}
