import React from 'react';
import { useSelector } from 'react-redux';
import { selectorAsyncActionCriticalErrors } from 'store/asyncAction/selectors';

import { ErrorInfo, ERROR_TYPE } from '#shared/types/common';
import { NavbarWithNavigation } from 'components/Navbar';
import SidebarLayoutCommon from '#web-components/components/Layouts/Sidebar';
import { getRegistryTitle, getRegistryTitleFull } from '#shared/utils/registrySettings';
import { useTranslation } from 'react-i18next';
import ErrorLayout from '../Error';

interface SidebarLayoutProps {
  error?: ErrorInfo
  children: React.ReactNode;
  title?: string;
  isLoading?: boolean;
  loaderDescription?: string;
  sideBarContent?: string | React.ReactElement;
  backLinkPath: string;
  backLinkCaption?: string;
  onBackLinkClick?: (e: React.MouseEvent) => void;
}

export default function SidebarLayout({
  children,
  title,
  isLoading,
  loaderDescription,
  sideBarContent,
  backLinkPath,
  backLinkCaption,
  error,
  onBackLinkClick,
}: SidebarLayoutProps) {
  const criticalError = useSelector(selectorAsyncActionCriticalErrors);
  const hasError = criticalError || (error && (!error.type || error.type === ERROR_TYPE.CRITICAL));
  const registryTitle = getRegistryTitle(REGISTRY_SETTINGS);
  const registryTitleFull = getRegistryTitleFull(REGISTRY_SETTINGS);
  const { t } = useTranslation('common');

  if (hasError) {
    return (
      <ErrorLayout error={criticalError || error} />
    );
  }

  return (
    <SidebarLayoutCommon
      t={t}
      title={title}
      registryName={registryTitle}
      registryFullName={registryTitleFull}
      isLoading={isLoading}
      loaderDescription={loaderDescription}
      sideBarContent={sideBarContent}
      navbar={(
        <NavbarWithNavigation linkTo={backLinkPath} linkCaption={backLinkCaption} onLinkClick={onBackLinkClick} />
      )}
    >
      {children}
    </SidebarLayoutCommon>
  );
}
