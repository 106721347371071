import { Components } from 'react-formio';
import baseEditGridForm from 'formiojs/components/editgrid/EditGrid.form';
import {
  withLegacyComponent,
} from '#web-components/components/Form/components/WithLegacyComponent/WithLegacyComponent';
import { addUniqClasses } from '#web-components/utils';
import { ComponentsConfig, FormioComponentName } from '../../types';
import { COMPONENT_CLASSES, defaultApiFields } from '../../constants';

const EditGrid = (Components as ComponentsConfig).components.editgrid;

export default class EditGridLegacy extends withLegacyComponent(EditGrid) {
  static schema() {
    return EditGrid.schema({
      type: FormioComponentName.editgridLegacy,
      key: 'editgridLegacy',
    });
  }

  static get builderInfo() {
    return {
      ...EditGrid.builderInfo,
      schema: EditGridLegacy.schema(),
    };
  }

  static editForm = () => baseEditGridForm([
    {
      key: 'api',
      components: defaultApiFields,
    },
  ]);

  constructor(component: Record<string, unknown>, options: Record<string, unknown>, data: unknown) {
    super(component, options, data);
    this.component.customClass = addUniqClasses(COMPONENT_CLASSES.bootstrapComponent, this.component.customClass);
  }

  calculateComponentValue(data: unknown, flags: Record<string, unknown>, row: unknown) {
    // fix to avoid duplication of components in the builder
    if (this.builderMode) {
      return false;
    }
    return super.calculateComponentValue(data, flags, row);
  }
}
