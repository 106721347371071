import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Observable } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';

import withFormioControl from '#web-components/components/Form/components/WithFormioControl';
import { FormControlError } from '#web-components/types/formControls';
import Map from '#web-components/components/Map';
import { GeometryValue } from '#web-components/components/Map/types';

import type { MapComponent } from '../../types';
import styles from './CustomMap.styles';

interface Props {
  value: Array<GeometryValue>;
  name: string;
  // eslint-disable-next-line react/no-unused-prop-types
  onChange: (value: unknown) => void;
  loadGeoJSON: (url: string) => Observable<AjaxResponse<unknown>>;
  component: MapComponent;
  // eslint-disable-next-line react/no-unused-prop-types
  error?: FormControlError;
  // eslint-disable-next-line react/no-unused-prop-types
  disabled?: boolean;
  id: string;
}

class FormioMap extends React.Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const {
      value,
      component,
      name,
      disabled,
      loadGeoJSON,
      id,
      onChange,
      error,
    } = this.props;
    const geocodeSearch = component.geocodeSearchUrl ? {
      placeholder: component.geocodeSearchPlaceholder || '',
      url: component.geocodeSearchUrl,
      showRadiusSettings: component.geocodeMarkerRadiusCustom,
    } : undefined;

    if (hasError) {
      return '';
    }

    return (
      <Map
        value={value}
        name={name}
        disabled={disabled}
        description={component.description}
        singleValue={component.singleValue}
        overlaySearchProp={component.overlaySearchProp}
        searchZoom={component.searchZoom}
        mode={component.mode}
        id={id}
        error={error}
        layerConfigList={component.layers}
        overlayConfigList={component.overlays}
        geocodeSearch={geocodeSearch}
        geocodeSearchZoom={component.geocodeSearchZoom}
        geocodeMarkerRadius={component.geocodeMarkerRadius}
        geocodeEnabled={component.geocodeEnabled}
        loadGeoJSON={loadGeoJSON}
        onChange={onChange}
      />
    );
  }
}

export default (withFormioControl(withStyles(styles)(FormioMap)));
