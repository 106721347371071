import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as Icon } from '#web-components/assets/icons/error.svg';

import styles from './ErrorIconIcon.styles';

type ErrorIconProps = {
  className?: string;
  title?: string;
};

const useStyles = makeStyles(styles, { name: 'ErrorIcon' });

export default function ErrorIcon({ className, title }: ErrorIconProps) {
  const classes = useStyles();
  return (
    <span className={clsx(classes.root, className)} title={title}>
      <Icon />
    </span>
  );
}
