import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { X_PATH } from 'constants/xPath';
import Menu from 'components/Menu';
import HomeButton from '#web-components/components/Navbar/components/HomeButton';
import NavbarContainer from '#web-components/components/Navbar';
import HeaderLinks from './components/HeaderLinks';

interface NavBarProps {
  disableBackground?: boolean
}

const EGovNavbar: React.FC<NavBarProps> = ({ disableBackground }) => {
  const { t } = useTranslation('common');
  return (
    <NavbarContainer disableBackground={disableBackground} data-xpath={X_PATH.header}>
      <HomeButton
        fullWidth
        direction="left"
        homePath="/"
        appTitle={t('appTitle')}
        navLinkComponent={RouterLink}
      />
      <HeaderLinks />
      <Menu />
    </NavbarContainer>
  );
};

export default EGovNavbar;
