import { userApi } from 'api';
import { USER_API_APPENDIX } from 'constants/baseUrl';

export const login = () => {
  return userApi.get$(`${USER_API_APPENDIX}/home`);
};

export const getInfo = () => {
  return userApi.get$(`${USER_API_APPENDIX}/api/userinfo`);
};
