import { createStyles, Theme } from '@material-ui/core/styles';

const styles = ({ colors }: Theme) => createStyles({
  icon: {
    display: 'flex',
    '& path': {
      fill: colors.uIIconBase,
    },
    '&:hover': {
      '& path': {
        fill: colors.uIBase,
      },
    },
  },
});

export default styles;
