import { historyApi } from 'api';
import { HISTORY_API_APPENDIX } from 'constants/baseUrl';
import { ENTITIES } from 'constants/entities';
import { HistoryFilterOptions } from '#shared/types/common';
import { buildURLQuery } from '#shared/utils/apiHelpers';

export const getProcessInstancesHistory = (requestParams: HistoryFilterOptions) => {
  const urlQuery = buildURLQuery(requestParams);
  return historyApi.get$(
    `${HISTORY_API_APPENDIX}/${ENTITIES.PROCESS_INSTANCE_PLURAL}?${urlQuery}`,
  );
};
export const getCompletedTasksList = (requestParams: HistoryFilterOptions) => {
  const urlQuery = buildURLQuery(requestParams);
  return historyApi.get$(`${HISTORY_API_APPENDIX}/${ENTITIES.TASK_PLURAL}?${urlQuery}`);
};
