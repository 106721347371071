import { ReactComponent } from 'react-formio';
import get from 'lodash/get';
import React from 'react';
import { addUniqClasses } from '#web-components/utils';
import isNumber from 'lodash/isNumber';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

import { FormComponent, FORMIO_EVENT, FormioComponentName } from '#web-components/components/Form/types';
import { COMPONENT_CLASSES, I18N_SEPARATOR, NAVIGATION_CODE } from '#web-components/components/Form/constants';

import CommonGridComponent from '../CommonGridComponent';
import settingsForm from './CustomEditGridSettings';
import EditGridAdapter from './EditGridAdapter';

class CustomEditGrid extends CommonGridComponent {
  static schema() {
    return ReactComponent.schema({
      type: FormioComponentName.editgrid,
      label: 'Edit Grid',
      key: 'editgrid',
      components: [],
      removeRow: '',
    });
  }

  static get builderInfo() {
    return {
      title: 'EditGrid',
      group: 'advanced',
      icon: 'at',
      documentation: '/userguide/#editgrid',
      weight: 10,
      schema: CustomEditGrid.schema(),
    };
  }

  static editForm = settingsForm;

  labelComponent = 'span';

  constructor(component: Record<string, unknown>, options: Record<string, unknown>, data: unknown) {
    super(component, options, data);
    this.component.customClass = addUniqClasses(COMPONENT_CLASSES.editgrid, this.component.customClass);
    // fix to avoid duplication of components in the builder
    if (this.builderMode) {
      this.options.preview = true;
    }
  }

  get componentDefinition() {
    return this.component as FormComponent;
  }

  // eslint-disable-next-line class-methods-use-this
  get emptyValue() {
    return [];
  }

  onExecuteActionOnSelection = (selectedItems: Array<Record<string, unknown>>, code: string) => {
    const dataClone = cloneDeep(this.data);
    const isValid = this.root.checkValidity(this.root.data, true);

    if (!isValid) {
      return;
    }

    dataClone[NAVIGATION_CODE] = code;
    set(dataClone, this.componentDefinition.key, selectedItems);

    this.emit('customEvent', {
      type: FORMIO_EVENT.NAVIGATION,
      data: dataClone,
    });
  };

  renderReact = () => {
    return (
      <EditGridAdapter
        theme={this.root.options.theme}
        component={this.componentDefinition}
        name={get(this, 'info.attr.name')}
        value={this.dataValue}
        onChange={this.updateValue}
        onExecuteActionOnSelection={this.onExecuteActionOnSelection}
        error={this.error}
        components={this.component.components}
        language={this.i18next.language}
        evalContext={{
          ...(this.options?.evalContext || {}),
          parent: this.data,
        }}
        prepareGridDisplayValue={this.prepareGridDisplayValue}
        disabled={this.disabled}
        textLabels={{
          add: this.t(`customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}add`),
          view: this.t(`customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}view`),
          save: this.t(`customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}save`),
          cancel: this.t(`customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}cancel`),
          edit: this.t(`customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}edit`),
          delete: this.t(`customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}delete`),
          editModalTitle: this.t(`customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}editModalTitle`),
          viewModalTitle: this.t(`customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}viewModalTitle`),
          paginationAriaLabel: {
            firstPage: this.t(
              `customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}aria${I18N_SEPARATOR}firstPage`,
            ),
            prevPage: this.t(
              `customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}aria${I18N_SEPARATOR}prevPage`,
            ),
            nextPage: this.t(
              `customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}aria${I18N_SEPARATOR}nextPage`,
            ),
            lastPage: this.t(
              `customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}aria${I18N_SEPARATOR}lastPage`,
            ),
          },
          emptyPlaceholder: this.t(
            `customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}empty`,
          ),
          deleteConfirmationTitle: this.t(
            `customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}deleteConfirmationTitle`,
          ),
          deleteConfirmationYes: this.t(
            `customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}deleteConfirmationYes`,
          ),
          deleteConfirmationNo: this.t(
            `customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}deleteConfirmationNo`,
          ),
          rowsPerPage: this.t(`customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}perPage`),
          getDisplayedRowsLabel: (params: { from: number, to: number, count?: number }) => (isNumber(params.count)
            ? this.t(`customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}pagination`, params)
            : this.t(`customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}paginationNoCount`, params)),
          searchPlaceholder: this.t(
            `customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}searchPlaceholder`,
          ),
          searchLabel: this.t(
            `customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}searchLabel`,
          ),
          searchEmptyPlaceholder: this.t(
            `customFormioComponents${I18N_SEPARATOR}editGrid${I18N_SEPARATOR}searchEmptyPlaceholder`,
          ),
        }}
      />
    );
  };
}

export default CustomEditGrid;
