import { Components } from 'react-formio';
import baseRadioForm from 'formiojs/components/radio/Radio.form';

import {
  withLegacyComponent,
} from '#web-components/components/Form/components/WithLegacyComponent/WithLegacyComponent';
import { addUniqClasses } from '#web-components/utils';

import { ComponentsConfig, FormioComponentName } from '../../types';
import { COMPONENT_CLASSES, defaultApiFields } from '../../constants';

const Radio = (Components as ComponentsConfig).components.radio;

export default class RadioLegacy extends withLegacyComponent(Radio) {
  getValueAsString = (value: unknown) => {
    const normilizedValue = value === false ? value.toString() : value;
    return super.getValueAsString(normilizedValue);
  };

  static schema() {
    return Radio.schema({
      type: FormioComponentName.radioLegacy,
      key: 'radioLegacy',
    });
  }

  static get builderInfo() {
    return {
      ...Radio.builderInfo,
      schema: RadioLegacy.schema(),
    };
  }

  static editForm = () => baseRadioForm([
    {
      key: 'api',
      components: defaultApiFields,
    },
  ]);

  constructor(component: Record<string, unknown>, options: Record<string, unknown>, data: unknown) {
    super(component, options, data);
    this.component.customClass = addUniqClasses(COMPONENT_CLASSES.bootstrapComponent, this.component.customClass);
  }
}
