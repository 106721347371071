import React, { useState } from 'react';
import { WithStyles } from '@material-ui/core';
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { ArrowDropDownIcon } from '#web-components/components/Icons';

import styles from './HeaderWithYearPicker.styles';

export interface Props extends ReactDatePickerCustomHeaderProps, WithStyles<typeof styles> {}

const HeaderWithYearPicker = (props: Props) => {
  const {
    decreaseMonth,
    increaseMonth,
    date,
    monthDate,
    changeYear,
    classes,
  } = props;

  const [yearShown, setYearShown] = useState(false);

  const selectYear = (year: Date) => {
    changeYear(new Date(year).getFullYear());
    setYearShown(false);
  };

  return (
    <div className={classes['react-datepicker__custom-header']}>
      <div className="react-datepicker__custom-header__month">
        <button
          aria-label="Previous Month"
          className="react-datepicker__navigation react-datepicker__navigation--previous"
          onClick={decreaseMonth}
          type="button"
        >
          <span
            className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
          >
            {'<'}
          </span>
        </button>
        <span className="react-datepicker__current-month">
          {monthDate.toLocaleString('uk', {
            month: 'long',
          })}
        </span>
        <button
          aria-label="Next Month"
          className="react-datepicker__navigation react-datepicker__navigation--next"
          onClick={increaseMonth}
          type="button"
        >
          <span
            className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
          >
            {'>'}
          </span>
        </button>
      </div>
      <button
        aria-label="Year"
        className="react-datepicker__year-btn"
        onClick={() => setYearShown(!yearShown)}
        type="button"
      >
        {new Date(date).getFullYear()}
        <ArrowDropDownIcon className={yearShown ? 'react-datepicker__year-icon__active' : ''} />
      </button>
      <div className={`react-datepicker-year ${yearShown ? 'react-datepicker-year__active' : ''}`}>
        <DatePicker
          selected={date}
          onChange={selectYear}
          showYearPicker
          dateFormat="yyyy"
          yearItemNumber={18}
          inline
        />
      </div>
    </div>
  );
};

export default HeaderWithYearPicker;
