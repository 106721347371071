export const API_URL = ENVIRONMENT_VARIABLES.apiUrl || '';

export const PROCESS_MANAGEMENT_BASE_URL = API_URL;
export const TASK_MANAGEMENT_BASE_URL = API_URL;
export const FORM_PROVIDER_BASE_URL = API_URL;
export const USER_API_BASE_URL = API_URL;
export const DOCUMENTS_API_BASE_URL = API_URL;
export const HISTORY_API_BASE_URL = API_URL;
export const RUNTIME_API_BASE_URL = API_URL;
export const SETTINGS_API_BASE_URL = API_URL;
export const NOTIFICATIONS_API_BASE_URL = API_URL;

export const PROCESS_MANAGEMENT_API_APPENDIX = 'officer/api/user-process-management';
export const TASK_MANAGEMENT_API_APPENDIX = 'officer/api/user-task-management';
export const FORM_PROVIDER_API_APPENDIX = 'officer/api/forms';
export const USER_API_APPENDIX = 'officer';
export const DOCUMENTS_MANAGEMENT_API_APPENDIX = 'documents';
export const HISTORY_API_APPENDIX = 'api/history';
export const RUNTIME_API_APPENDIX = 'api/runtime';
export const EXCERPTS_API_APPENDIX = 'api/excerpts';
export const SETTINGS_API_APPENDIX = 'api/settings/me';
export const NOTIFICATIONS_API_APPENDIX = 'api/notifications';
