import get from 'lodash/get';
import { ListItem, Order } from '#web-components/types/table';

export const localizedInsensitiveOrderBy = (
  list: ListItem[],
  orderField: string,
  order: Order,
  locale?: string,
) => {
  const collator = new Intl.Collator(locale, { sensitivity: 'accent' });
  return [...list].sort((a, b) => {
    const firstCompareField = get(order === Order.asc ? a : b, orderField);
    const secondCompareField = get(order === Order.asc ? b : a, orderField);
    return collator.compare(firstCompareField, secondCompareField);
  });
};
