import { createStyles, Theme } from '@material-ui/core/styles';
import { spacing } from '#web-components/styles';

const standardLayoutStyles = (theme: Theme) => createStyles({
  tooltip: {
    maxWidth: 800,
    marginTop: spacing / 2,
    background: theme.colors.layoutBackgroundPrimary,
    color: theme.colors.textMainPrimary,
    padding: spacing * 2,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.colors.uIIconHovered,
    borderRadius: 0,
  },
});

export default standardLayoutStyles;
