import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorInfo } from '#shared/types/common';
import { CurrentUser, LoginOptions } from '#shared/types/user';

const ACTION_PREFIX = 'currentUser';

export interface CurrentUserState {
  info: CurrentUser | null,
  initialized: boolean,
  authenticated: boolean,
  beenAuthenticated: boolean,
}

const initialState: CurrentUserState = {
  info: null,
  initialized: false,
  authenticated: false,
  beenAuthenticated: false,
};

export const clearInfo = createAction(`${ACTION_PREFIX}/clearInfo`);
export const getInfoRequest = createAction(`${ACTION_PREFIX}/getInfoRequest`);
export const getInfoError = createAction<ErrorInfo>(`${ACTION_PREFIX}/getInfoError`);
export const loginRequest = createAction<LoginOptions>(`${ACTION_PREFIX}/loginRequest`);

const CurrentUserSlice = createSlice({
  name: ACTION_PREFIX,
  initialState,
  reducers: {
    getInfoSuccess(state, action: PayloadAction<CurrentUser>) {
      return {
        ...state,
        info: action.payload,
        initialized: true,
        authenticated: true,
        beenAuthenticated: true,
      };
    },
  },
  extraReducers: {
    [getInfoRequest.type]: (state) => {
      return {
        ...state,
        info: null,
      };
    },
    [getInfoError.type]: (state) => {
      return {
        ...state,
        initialized: true,
      };
    },
    [clearInfo.type]: (state) => {
      return {
        ...state,
        info: null,
        authenticated: false,
      };
    },
  },
});

export default CurrentUserSlice.reducer;

export const {
  getInfoSuccess,
} = CurrentUserSlice.actions;
