import { createStyles, Theme } from '@material-ui/core/styles';
import { bodyText } from '#web-components/styles';

const mapStyles = (theme: Theme) => createStyles({
  root: {
    height: theme.spacing(80),
  },
  searchField: {
    border: 'none',
    paddingBottom: theme.spacing(2),
    '&:focus-visible': {
      outline: 'none',
    },

    '& .leaflet-control-search': {
      display: 'flex',
      borderBottom: `2px solid ${theme.colors.textMainPrimary}`,
      '&:focus-visible': {
        outline: 'none',
      },
      '&.search-exp': {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        background: 'none',
      },
      '&.search-load': {
        '& input.search-input': {
          background: 'none',
        },
      },
    },

    '& input.search-input': {
      ...bodyText,
      flexGrow: 1,
      float: 'none',
      font: 'inherit',
      color: 'currentColor',
      border: 0,
      height: '1.1876em',
      margin: 0,
      display: 'block',
      padding: '6px 0 7px',
      minWidth: 0,
      background: 'none',
      boxSizing: 'content-box',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      '&:focus-visible': {
        outline: 'none',
      },
    },

    '& .search-tip': {
      whiteSpace: 'normal',
    },
    '& .search-tooltip': {
      width: '100%',
    },

    '& .search-button': {
      float: 'none',
    },
  },
  customRadius: {
    paddingBottom: theme.spacing(2),
  },
});

export default mapStyles;
