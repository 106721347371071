import { createStyles } from '@material-ui/core/styles';

export type StyleProps = {
  size: string | number;
};

const sanitizeSize: (value: string | number) => string = (value) => {
  // lineHeight accepts number as is, so writing this helper to be sure
  // or the lineHeight would be set to 2800% instead of 28px
  return typeof value === 'number' ? `${value}px` : value;
};

const styles = () => createStyles({
  root: {
    width: ({ size }: StyleProps) => sanitizeSize(size),
    height: ({ size }: StyleProps) => sanitizeSize(size),
    lineHeight: ({ size }: StyleProps) => sanitizeSize(size),
    fontSize: ({ size }: StyleProps) => sanitizeSize(size),
    textAlign: 'center',
    display: 'inline-block',
  },
});

export default styles;
