import { Api } from './setupApi';
import {
  PROCESS_MANAGEMENT_BASE_URL,
  FORM_PROVIDER_BASE_URL,
  TASK_MANAGEMENT_BASE_URL,
  USER_API_BASE_URL,
  DOCUMENTS_API_BASE_URL,
  HISTORY_API_BASE_URL,
  RUNTIME_API_BASE_URL,
  SETTINGS_API_BASE_URL,
  NOTIFICATIONS_API_BASE_URL,
} from '../constants/baseUrl';

const headers = {
  'Content-Type': 'application/json',
};

export const processManagementApi = new Api(PROCESS_MANAGEMENT_BASE_URL, headers);

export const formProviderApi = new Api(FORM_PROVIDER_BASE_URL, headers);

export const taskManagementApi = new Api(TASK_MANAGEMENT_BASE_URL, headers);

export const userApi = new Api(USER_API_BASE_URL, headers);

export const documentsApi = new Api(DOCUMENTS_API_BASE_URL, headers);

export const historyApi = new Api(HISTORY_API_BASE_URL, headers);

export const runtimeApi = new Api(RUNTIME_API_BASE_URL, headers);

export const settingsApi = new Api(SETTINGS_API_BASE_URL, headers);

export const notificationsApi = new Api(NOTIFICATIONS_API_BASE_URL, headers);
