import {
  eUkraineHeadFont,
  eUkraineRegularFont,
  eUkraineLightFont,
  whiteTheme as defaultTheme,
} from '#web-components/styles';

import { createMuiTheme } from '#web-components/exports/theme';

export default () => {
  const themeColors = (APPLICATION_THEME?.colors as typeof defaultTheme.colors) || defaultTheme.colors;

  return createMuiTheme({
    typography: {
      fontFamily: 'e-Ukraine-Regular',
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 480, // Phone
        md: 992, // Tablet
        lg: 1280, // Laptop
        xl: 1680, // Desktop
      },
    },
    palette: {
      background: {
        default: themeColors.layoutBackgroundOutside,
      },
      primary: {
        main: themeColors.uIBase,
      },
      text: {
        primary: themeColors.textMainPrimary,
      },
    },
    colors: {
      ...themeColors,
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [eUkraineRegularFont, eUkraineHeadFont, eUkraineLightFont],
        },
      },
      MuiPaper: {
        elevation4: {
          boxShadow: 'none',
        },
      },
    },
  });
};
