import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Typography from '#web-components/components/Typography';
import Tooltip from '#web-components/components/Tooltip';
import styles from './RegistryName.styles';

const useStyles = makeStyles(styles, { name: 'RegistryName' });

export interface RegistryNameProps {
  name: string,
  fullName: string,
}

export default function RegistryName({ name, fullName }: RegistryNameProps) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Tooltip title={fullName} enterDelay={300}>
        <Typography component="span" variant="siteNamesMedium" className={classes.name}>{name}</Typography>
      </Tooltip>
    </Box>
  );
}
