import { createStyles } from '@material-ui/core/styles';
import { spacing } from '#web-components/styles';

import type { ConfirmModalProps } from './ConfirmModal';

const styles = () => createStyles({
  dialogContent: {
    marginTop: `${spacing * 3}px`,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  dialogActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      textTransform: (props: ConfirmModalProps) => (props.capitalizeButtons !== false ? 'capitalize' : 'none'),
    },
  },
});

export default styles;
