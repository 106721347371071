import React, { Component } from 'react';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import Table from 'components/Table';
import { ROUTES } from 'constants/routes';
import { UserTask } from '#shared/types/task';
import { ColumnDefinition, FilterParams } from '#web-components/types/table';
import { dateTimeFormatter } from '#web-components/utils';
import { spacing } from '#web-components/styles';
import { OkSignIcon } from '#web-components/components/Icons';

import PendingTasksTableAction from '../PendingTasksTableAction';

interface PendingTasksTableProps extends WithTranslation, RouteComponentProps {
  tasksList: UserTask[],
  getPendingTasksList: (filterParams: FilterParams, isInitialRequest?: boolean) => void,
  filterParams: FilterParams,
  claimTask: (id: string, name: string) => void,
  isLoadingTasks: boolean,
}

export default class PendingTasksTable extends Component<PendingTasksTableProps> {
  columnDefinitions: ColumnDefinition[] = [
    {
      title: this.props.t('components.pendingTasksTable.columns.processDefinitionName'),
      property: 'processDefinitionName',
      width: 408,
      sortable: false,
    },
    {
      title: this.props.t('components.pendingTasksTable.columns.businessKey'),
      property: 'businessKey',
      width: 408,
      sortable: false,
    },
    {
      title: this.props.t('components.pendingTasksTable.columns.name'),
      property: 'name',
      width: 352,
      sortable: false,
    },
    {
      title: this.props.t('components.pendingTasksTable.columns.dateCreated'),
      property: 'created',
      formatter: dateTimeFormatter,
      width: `${spacing * 26}px`,
    },
    {
      title: this.props.t('components.pendingTasksTable.columns.action'),
      property: '',
      sortable: false,
      width: `${spacing * 19}px`,
      // TODO: Declare this component outside parent component "PendingTasksTable" or memoize it.
      //  If you want to allow component creation in props, set allowAsProps option to true
      // eslint-disable-next-line react/no-unstable-nested-components
      Component: (props) => {
        const { item } = props;
        const hasAssignee = !!(item as UserTask).assignee;
        const isSuspended = !!(item as UserTask).suspended;
        const taskName = (item as UserTask).name || '';
        return (
          <PendingTasksTableAction
            taskId={(item as UserTask).id}
            taskName={taskName}
            hasAssignee={hasAssignee}
            isDisabled={isSuspended}
            onExecute={this.handleTaskExecute}
            onClaim={this.handleTaskClaim}
          />
        );
      },
    },
  ];

  componentDidMount() {
    const { getPendingTasksList, filterParams } = this.props;
    getPendingTasksList(filterParams, true);
  }

  handleFilterChange = (filterParams: FilterParams) => {
    const { getPendingTasksList } = this.props;
    getPendingTasksList(filterParams, false);
  };

  handleTaskExecute = (id: string) => {
    const { history } = this.props;
    history.push(ROUTES.USER_TASK.replace(':taskId', id));
  };

  handleTaskClaim = (id: string, name: string) => {
    const { claimTask } = this.props;
    claimTask(id, name);
  };

  render() {
    const {
      tasksList,
      t,
      isLoadingTasks,
      filterParams: {
        page,
        rowsPerPage,
        order,
        orderField,
      },
    } = this.props;
    return (
      <Table
        columnDefinitions={this.columnDefinitions}
        onFilterChange={this.handleFilterChange}
        list={tasksList}
        order={order}
        orderField={orderField}
        pagination={{
          page,
          rowsPerPage,
        }}
        emptyPlaceholder={t('components.pendingTasksTable.emptyPlaceholder')}
        emptyPlaceholderIcon={<OkSignIcon />}
        hideEmptyPlaceholder={isLoadingTasks}
        dataXpathTableRowPropName="processInstanceId"
      />
    );
  }
}
