import React, {
  useCallback, useImperativeHandle, useMemo, useRef,
} from 'react';
import { Form } from 'react-formio';
import { Theme } from '@material-ui/core/styles';
import { debounce } from 'lodash';

import FileService from '#web-components/components/Form/FileService';
import { INITIAL_DATA_KEY } from '#web-components/components/Form/constants';

import { FormComponent, FormSubmission } from '../../types';
import localizationForm from '../../localization/ua.json';

const sanitizeConfig = {
  allowedTags: ['svg', 'path'],
  addTags: ['svg', 'path'],
  allowedAttr: ['d', 'transform', 'fill', 'viewBox', 'xmlns'],
  addAttr: ['d', 'transform', 'fill', 'viewBox', 'xmlns'],
};

const SUBMIT_TIMEOUT = 200;

interface Props {
  language: string,
  onSubmit: (submission: FormSubmission) => void;
  onChange?: (submission: FormSubmission) => void;
  onCustomEvent?: (options: { type: string, data?: Record<string, unknown> }) => void;
  components?: Array<FormComponent>;
  submission?: FormSubmission;
  readOnly?: boolean;
  evalContext?: Record<string, unknown>;
  parentPath?: string;
  fileServiceNonSupport?: boolean;
  formRef?: React.Ref<unknown>;
  handleOpenConfirm: (callBack: () => void, confirmationText: string) => void;
  theme: Theme;
  onDirtyChange?: (status?: boolean) => void;
  fileMaxSize?: string,
  fileMaxTotalSize?: string,
  blackList?: string[],
}

type FormRef = {
  instance?: {
    instance?: {
      pristine: boolean;
      id: string;
      destroy: () => void;
    };
  };
};

function FormMemorized({
  language,
  components,
  onSubmit,
  submission,
  readOnly,
  evalContext,
  onCustomEvent,
  parentPath,
  fileServiceNonSupport,
  onChange,
  formRef,
  theme,
  handleOpenConfirm,
  onDirtyChange,
  fileMaxSize,
  fileMaxTotalSize,
  blackList,
}: Props) {
  const innerRef = useRef<FormRef>(null);
  const prevPristine = useRef<boolean | undefined>(true);

  useImperativeHandle(formRef, () => innerRef.current);

  const handleChange = useCallback((args: FormSubmission) => {
    const formInstance = innerRef.current?.instance?.instance;
    const { pristine } = formInstance || {};
    const currentPristine = (pristine === undefined) ? true : pristine;
    onChange?.(args);
    if (currentPristine !== prevPristine.current) {
      onDirtyChange?.(!currentPristine);
    }
    prevPristine.current = currentPristine;
  }, [onChange, onDirtyChange]);

  const debouncedSubmit = useMemo(() => {
    return debounce(onSubmit, SUBMIT_TIMEOUT, { leading: true, trailing: false });
  }, [onSubmit]);

  return (
    <Form
      ref={innerRef}
      form={{ components }}
      options={{
        language,
        i18n: {
          [language]: localizationForm,
        },
        readOnly,
        sanitizeConfig,
        evalContext,
        theme,
        parentPath,
        handleOpenConfirm,
        fileService: new FileService({
          fileMaxSize,
          fileMaxTotalSize,
          displayFilePreview: fileServiceNonSupport,
        }),
        blackList,
        [INITIAL_DATA_KEY]: submission?.data || {},
      }}
      onSubmit={debouncedSubmit}
      submission={submission}
      onCustomEvent={onCustomEvent}
      onChange={handleChange}
    />
  );
}

export default React.memo(FormMemorized);
