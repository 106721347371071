import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PointUpIcon from '#web-components/components/Icons/PointUpIcon/PointUpIcon';
import Typography from '#web-components/components/Typography';

import styles from './DialogTitleConfirm.styles';

const useStyles = makeStyles(styles, { name: 'DialogTitleConfirm' });

export interface ConfirmModalProps {
  title: string | React.ReactNode;
  icon?: React.ReactNode;
}

const DialogTitleConfirm = ({ title, icon }: ConfirmModalProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.dialogTitle}>
      { icon || (<PointUpIcon size={60} />) }
      <Typography variant="h2" className={classes.titleText}>{title}</Typography>
    </Box>
  );
};

export default DialogTitleConfirm;
