import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import * as api from 'api/user';
import { USER_API_APPENDIX, USER_API_BASE_URL } from 'constants/baseUrl';
import {
  filter,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import { catchError } from '#shared/utils/apiHelpers';

import {
  getInfoError,
  getInfoRequest,
  getInfoSuccess,
  loginRequest,
} from './slice';

export const userLoginEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$.pipe(
    filter(loginRequest.match),
    tap(() => {
      // TODO: epic is not used, because strange behavior of browser, when doing navigation from epic.
      //  On localhost everything is fine
      const baseUrl = USER_API_BASE_URL || window.location.origin;
      window.location.replace(`${baseUrl}/${USER_API_APPENDIX}/home`);
    }),
  );
};

export const getUserInfoEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$.pipe(
    filter(getInfoRequest.match),
    mergeMap(() => {
      return api.getInfo().pipe(
        map(({ response }) => getInfoSuccess(response)),
        catchError((error) => of(getInfoError({ httpStatus: error.status }))),
      );
    }),
  );
};
