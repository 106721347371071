import { createStyles, Theme } from '@material-ui/core';
import { spacing } from '#web-components/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    marginBottom: spacing * 4,
    maxWidth: spacing * 92,
  },
  itemOpened: {
    marginBottom: spacing * 10,
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacing * 3.75,
  },
  messageWrapper: {
    marginTop: spacing * 2,
    marginBottom: spacing * 4,
  },
  messageWrapperClosed: {
    overflow: 'hidden',
    position: 'relative',
    marginBottom: spacing * 2,
    maxHeight: spacing * 9,
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      height: '70%',
      bottom: 0,
      background: theme.colors.uIGradient4,
      pointerEvents: 'none',
    },
  },

  label: {
    color: theme.colors.textAlternatePrimary,
    backgroundColor: theme.colors.uIBlueBase,
    padding: `0 ${theme.spacing(0.5)}px`,
  },
  message: {
    marginTop: spacing * 2,
    wordBreak: 'break-word',
  },
  iconActive: {
    transform: 'rotate(180deg)',
  },
});

export default styles;
