import baseCheckboxForm from 'formiojs/components/checkbox/Checkbox.form';
import { ignoreBuilderFields } from '#web-components/utils';
import {
  defaultConditionalFields,
  defaultDataFields,
  defaultValidationFields,
  tableSettingsTab,
  defaultApiFields,
} from '#web-components/components/Form/constants';

/**
 * This function returns the fields that should be present in the builder
 * You can extend the default one of any component
 * The values you pass are merged with the passed ones using the function unifyComponents (seacrh in formio.js source)
 *
 * *overrideEditForm* param is used when component key is equal to the default one to favor yours
 * *ignore* param will force the skipping of this component
 */
export default () => {
  return baseCheckboxForm([
    {
      key: 'display',
      components: ignoreBuilderFields([
        'inputType',
        'customClass',
        'autofocus',
        'modalEdit',
        'labelWidth',
        'shortcut',
        'labelMargin',
        'hideLabel',
        'tableView',
      ]),
    },
    {
      key: 'data',
      components: [
        ...defaultDataFields,
        ...ignoreBuilderFields([
          'persistent',
          'protected',
          'dbIndex',
          'encrypted',
          'redrawOn',
          'calculateServer',
        ]),
      ],
    },
    {
      key: 'validation',
      components: [
        ...defaultValidationFields,
        ...ignoreBuilderFields(['errorLabel']),
      ],
    },
    {
      key: 'api',
      components: [
        ...ignoreBuilderFields(['tags', 'properties']),
        ...defaultApiFields,
      ],
    },
    {
      key: 'logic',
      ignore: true,
    },
    {
      key: 'layout',
      ignore: true,
    },
    {
      key: 'conditional',
      components: defaultConditionalFields,
    },
    tableSettingsTab,
  ]);
};
