import React, { Component } from 'react';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { WithStyles } from '@material-ui/core';
import memoize from 'lodash/memoize';

import { ProcessDefinition, ProcessDefinitionGroup } from '#shared/types/processDefinition';
import StandardLayout from 'components/Layouts/Standard';
import { X_PATH } from 'constants/xPath';
import { ROUTES } from 'constants/routes';
import GroupedEntityList from '#web-components/components/GroupedEntityList';
import { MenuEmptyListPlaceholder } from '#web-components/components/MenuPanel';
import { ListItem } from '#web-components/types/table';
import { EntityGroup } from '#web-components/types/groupedEntity';

import { isUnregisteredRole } from 'utils/user';
import type { PropsFromState, DispatchProps } from './index';
import styles from './ProcessListPage.styles';

interface Props extends PropsFromState, DispatchProps, WithTranslation, WithStyles<typeof styles>, RouteComponentProps {
}

class ProcessListPage extends Component<Props> {
  componentDidMount() {
    const { getProcessGroupData } = this.props;
    getProcessGroupData();
  }

  handleClick = (item: ListItem) => {
    const process = item as ProcessDefinition;
    const { startProcess, history, userInfo } = this.props;
    const hasRealmUnregisteredRole = userInfo?.roles
      ? isUnregisteredRole(userInfo.roles)
      : false;
    if (process.formKey) {
      return history.push(ROUTES.PROCESS_START_FORM.replace(':processDefinitionKey', process.key));
    }
    return startProcess({ processDefinitionKey: process.key, goToFirstTask: hasRealmUnregisteredRole });
  };

  getGroups = memoize((groups: Array<ProcessDefinitionGroup>): Array<EntityGroup> => {
    return groups.map((group) => ({
      ...group,
      entityList: group.processDefinitions,
    }));
  });

  render() {
    const {
      processGroupData, t, isLoading, error,
    } = this.props;
    const convertedGroupList = this.getGroups(processGroupData.groups);
    const processListEmpty = !processGroupData.groups.length && !processGroupData.ungrouped.length;

    return (
      <StandardLayout
        title={t('pageTitle')}
        description={t('pageDescription')}
        isLoading={isLoading}
        error={error}
      >
        {
          !processListEmpty && (
            <GroupedEntityList
              groupList={convertedGroupList}
              ungroupedList={processGroupData.ungrouped}
              onEntitySelect={this.handleClick}
              readonly
              data-xpath={{
                backToGroups: X_PATH.backToGroups,
              }}
              localization={{
                groupsTitle: t('processGroups.groupsTitle'),
                ungroupsTitle: t('processGroups.ungroupsTitle'),
                emptyGroupsPlaceholder: t('processGroups.emptyGroupsPlaceholder'),
                emptyCurrentGroupPlaceholder: t('processGroups.emptyCurrentGroupPlaceholder'),
              }}
            />
          )
        }
        {
          (!isLoading && processListEmpty) && (
            <MenuEmptyListPlaceholder
              message={t('processList.emptyPlaceholder.message')}
              description={t('processList.emptyPlaceholder.description')}
            />
          )
        }
      </StandardLayout>
    );
  }
}

export default ProcessListPage;
