import React, { useEffect } from 'react';
import {
  Switch, Route, Redirect, useRouteMatch,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Home from 'pages/Home';
import ProcessInstanceList from 'pages/ProcessInstanceList';
import UserTaskList from 'pages/UserTaskList';
import Login from 'pages/Login';
import ProcessList from 'pages/ProcessList';
import TaskPage from 'pages/Task';
import NotFoundPage from 'pages/NotFound';
import StartForm from 'pages/StartForm';
import Forbidden from 'pages/Forbidden';
import ProfilePage from 'pages/Profile';
import ErrorPage from 'pages/Error';
import ReportListPage from 'pages/ReportList';
import ProfileEditPage from 'pages/ProfileEdit';
import ProfileConfirmChannel from 'pages/ProfileConfirmChannel';
import NotificationList from 'pages/NotificationList';
import {
  onboardingProcessRequest,
  selectOnboardingProcessStatusChecked,
  onboardingProcessRequestSuccess,
} from 'store/onboarding';

import useAuthentication from 'hooks/useAuthentication';
import { ROUTES } from 'constants/routes';
import { ROLES } from 'constants/roles';
import { X_PATH } from 'constants/xPath';
import Loader from '#web-components/components/Loader';
import PrivateRoute from './PrivateRoute';

const Routes: React.FC = () => {
  const dispatch = useDispatch();
  const { initialized, error, hasUnregisteredRealmRole } = useAuthentication();
  const isOnbordingStatusChecked = useSelector(selectOnboardingProcessStatusChecked);
  const isUnregistered = hasUnregisteredRealmRole;
  const onStartForm = useRouteMatch(ROUTES.PROCESS_START_FORM);
  const onboardingIsNeeded = isUnregistered && !onStartForm;
  const showLoader = !initialized || (onboardingIsNeeded && !isOnbordingStatusChecked);

  useEffect(() => {
    if (onboardingIsNeeded && !isOnbordingStatusChecked) {
      dispatch(onboardingProcessRequest());
    }
  }, [dispatch, onboardingIsNeeded, isOnbordingStatusChecked]);

  useEffect(() => {
    if (onStartForm) {
      dispatch(onboardingProcessRequestSuccess());
    }
  }, [dispatch, onStartForm]);

  if (showLoader) {
    return <Loader show data-xpath={X_PATH.loader} />;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <>
      <Loader show={false} data-xpath={X_PATH.loader} />
      <Switch>
        <Redirect from="/" to={ROUTES.HOME} exact />
        <Route path={ROUTES.LOGIN} component={Login} />
        <PrivateRoute
          path={ROUTES.HOME}
          component={Home}
          roles={[ROLES.OFFICER, ROLES.UNREGISTERED]}
        />
        <PrivateRoute
          path={ROUTES.PROCESS_INSTANCE_LIST}
          component={ProcessInstanceList}
          roles={[ROLES.OFFICER, ROLES.UNREGISTERED]}
        />
        <PrivateRoute
          path={ROUTES.USER_TASK_LIST}
          component={UserTaskList}
          roles={[ROLES.OFFICER, ROLES.UNREGISTERED]}
          exact
        />
        <PrivateRoute
          path={ROUTES.PROCESS_LIST}
          component={ProcessList}
          roles={[ROLES.OFFICER, ROLES.UNREGISTERED]}
          exact
        />
        <PrivateRoute
          path={ROUTES.PROCESS_START_FORM}
          component={StartForm}
          roles={[ROLES.OFFICER, ROLES.UNREGISTERED]}
        />
        <PrivateRoute
          path={ROUTES.REPORTS_LIST}
          component={ReportListPage}
          roles={[ROLES.OFFICER, ROLES.UNREGISTERED]}
        />
        <PrivateRoute
          path={ROUTES.USER_TASK}
          component={TaskPage}
          roles={[ROLES.OFFICER, ROLES.UNREGISTERED]}
        />
        <PrivateRoute
          path={ROUTES.PROFILE}
          component={ProfilePage}
          roles={[ROLES.OFFICER, ROLES.UNREGISTERED]}
          exact
        />
        <PrivateRoute
          path={ROUTES.PROFILE_EDIT}
          component={ProfileEditPage}
          roles={[ROLES.OFFICER, ROLES.UNREGISTERED]}
        />
        <PrivateRoute
          path={ROUTES.PROFILE_CONFIRM_CHANNEL}
          component={ProfileConfirmChannel}
          roles={[ROLES.OFFICER, ROLES.UNREGISTERED]}
        />
        <PrivateRoute
          path={ROUTES.NOTIFICATION_LIST}
          component={NotificationList}
          roles={[ROLES.OFFICER, ROLES.UNREGISTERED]}
          exact
        />
        <PrivateRoute path={ROUTES.FORBIDDEN} component={Forbidden} roles={['*']} />
        <PrivateRoute path="*" component={NotFoundPage} roles={[ROLES.OFFICER, ROLES.UNREGISTERED]} exact />
      </Switch>
    </>
  );
};

export default Routes;
