import { ActionsObservable } from 'redux-observable';
import { Action } from '@reduxjs/toolkit';
import { of } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
} from 'rxjs/operators';

import * as api from 'api/processInstance';
import { ERROR_TYPE } from '#shared/types/common';
import i18n from 'localization';
import { tryAgainNotificationErrorProps } from 'constants/errorProps';
import { getCriticalErrorProps, catchError, getNotificationErrorProps } from '#shared/utils/apiHelpers';
import {
  getProcessInstancesRequest,
  getProcessInstancesSuccess,
  getProcessInstancesCountRequest,
  getProcessInstancesCountSuccess,
  getProcessInstancesError,
  getProcessInstancesCountError,
} from './slice';

export const getProcessInstancesEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$
    .pipe(
      filter(getProcessInstancesRequest.match),
      mergeMap(({ payload }) => {
        const { filterParams, isInitialRequest } = payload;
        const {
          page,
          rowsPerPage,
          order,
          orderField,
        } = filterParams;
        const firstResult = page * rowsPerPage;
        return api.getProcessInstancesList({
          offset: firstResult,
          limit: rowsPerPage + 1,
          sort: `${order}(${orderField})`,
        }).pipe(
          mergeMap((response) => {
            return of(
              getProcessInstancesSuccess({
                list: response.response,
                filterParams,
              }),
            );
          }),
          catchError((serverResponse) => of(getProcessInstancesError(
            isInitialRequest
              ? getCriticalErrorProps({ serverResponse })
              : getNotificationErrorProps(serverResponse.response, tryAgainNotificationErrorProps),
          ))),
        );
      }),
    );
};

export const getProcessInstancesCountEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$
    .pipe(
      filter(getProcessInstancesCountRequest.match),
      mergeMap(() => {
        return api.getProcessInstancesCount().pipe(
          map((response) => {
            return getProcessInstancesCountSuccess(response.response.count);
          }),
          catchError(({ response }) => of(getProcessInstancesCountError({
            type: ERROR_TYPE.NOTIFICATION,
            traceId: response?.traceId,
            componentProps: {
              title: i18n.t('errors.notification.processInstancesCount.title'),
            },
          }))),
        );
      }),
    );
};
