import { createStyles, Theme } from '@material-ui/core';

const styles = ({ colors, spacing }: Theme) => createStyles({
  container: {
    textAlign: 'center',
    marginBottom: spacing(5),
  },
  link: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
    display: 'block',
    '& img': {
      width: 123,
    },
  },
  description: {
    color: colors.uIBase2,
    marginBottom: spacing(1),
  },
});

export default styles;
