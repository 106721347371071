import Component from 'formiojs/components/_classes/component/Component';
import CustomFormioFile from '../CustomFormioFile';
import DataImportSettings from './DataImportSettings';
import { FormioComponentName } from '../../types';

export default class DataImport extends CustomFormioFile {
  static editForm = DataImportSettings;

  static get builderInfo() {
    return {
      title: 'Data Import',
      icon: 'file',
      group: 'basic',
      documentation: '',
      weight: 100,
      schema: DataImport.schema(),
    };
  }

  public static schema() {
    return Component.schema({
      type: FormioComponentName.dataImport,
      label: 'Data Import',
      key: 'dataImport',
      image: false,
      filePattern: '*.csv',
      privateDownload: false,
      imageSize: '200',
      uploadOnly: false,
      fileMinSize: '0MB',
      fileMinTotalSize: '0MB',
    });
  }

  get defaultSchema() {
    return DataImport.schema();
  }
}
