import { PROCESS_MANAGEMENT_API_APPENDIX } from 'constants/baseUrl';
import { ENTITIES } from 'constants/entities';

import { processManagementApi } from '.';

export const getProcessListDefinitions = () => {
  return processManagementApi.get$(`${PROCESS_MANAGEMENT_API_APPENDIX}/${ENTITIES.PROCESS_DEFINITION}/`);
};

export const getGroupedProcessListDefinitions = () => {
  return processManagementApi.get$(`${PROCESS_MANAGEMENT_API_APPENDIX}/grouped-${ENTITIES.PROCESS_DEFINITION}`);
};

export const getProcessDefinitionsCount = () => {
  return processManagementApi.get$(`${PROCESS_MANAGEMENT_API_APPENDIX}/${ENTITIES.PROCESS_DEFINITION}/count`);
};

export const startProcess = (processKey: string) => {
  return processManagementApi
    .post$(`${PROCESS_MANAGEMENT_API_APPENDIX}/${ENTITIES.PROCESS_DEFINITION}/${processKey}/start`, {});
};

export const startProcessWithForm = (processKey: string, formData: Record<string, unknown>) => {
  return processManagementApi
    .post$(`${PROCESS_MANAGEMENT_API_APPENDIX}/${ENTITIES.PROCESS_DEFINITION}/${processKey}/start-with-form`, formData);
};

export const getProcessDefinitionByKey = (key: string) => {
  return processManagementApi.get$(`${PROCESS_MANAGEMENT_API_APPENDIX}/${ENTITIES.PROCESS_DEFINITION}/${key}`);
};
