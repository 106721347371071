import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import styles from './EmojiIcon.styles';

export interface EmojiIconProps {
  children: string;
  size?: number | string;
  className?: string;
}

const useStyles = makeStyles(styles, { name: 'EmojiIcon' });

export default function EmojiIcon({ children, size = 24, className }: EmojiIconProps) {
  const classes = useStyles({ size });
  return <span role="img" aria-label="" className={clsx(classes.root, className)}>{children}</span>;
}
