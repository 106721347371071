import { Theme, createStyles } from '@material-ui/core';
import { spacing } from '#web-components/styles';

const styles = ({ colors }:Theme) => createStyles({
  btn: {
    '& button': {
      borderColor: 'transparent',
      backgroundImage: `linear-gradient(${colors.uIBlueBackground}, ${colors.uIBlueBackground}),
      linear-gradient(to right, ${colors.uIBase}, ${colors.uIBase})`,
      marginTop: spacing * 2,
    },
  },
  divider: {
    marginTop: spacing * 3,
    marginBottom: spacing * 3,
  },
});

export default styles;
