import { makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import StandardLayout from 'components/Layouts/Standard';
import { TAB_QUERY_PARAM, UserTaskListTabs } from 'constants/tabs';
import {
  getPendingTasksListRequest,
  getPendingTasksListClean,
  selectPendingTasksIsLoading,
  selectPendingTasksList,
  selectPendingTasksError,
  claimTaskRequest,
  selectClaimTaskIsLoading,
  selectPendingTasksFilterParams,
} from 'store/pendingTasks';
import {
  getCompletedTasksListRequest,
  getCompletedTasksListClean,
  selectCompletedTasksIsLoading,
  selectCompletedTasksList,
  selectCompletedTasksError,
  selectCompletedTasksFilterParams,
} from 'store/completedTasks';
import { X_PATH } from 'constants/xPath';
import Tabs, { TabPanel } from '#web-components/components/Tabs';
import styles from './UserTaskListPage.styles';

import PendingTasksTable from './components/PendingTasksTable';
import CompletedTasksTable from './components/CompletedTasksTable';

const useStyles = makeStyles(styles, { name: 'UserTaskListPage' });

export default function UserTaskListPage() {
  const completedTaskList = useSelector(selectCompletedTasksList);
  const completedTaskFilterParams = useSelector(selectCompletedTasksFilterParams);
  const isCompletedLoading = useSelector(selectCompletedTasksIsLoading);
  const completedTaskListError = useSelector(selectCompletedTasksError);

  const isClaimTaskLoading = useSelector(selectClaimTaskIsLoading);
  const pendingTaskList = useSelector(selectPendingTasksList);
  const pendingTaskFilterParams = useSelector(selectPendingTasksFilterParams);
  const isPendingLoading = useSelector(selectPendingTasksIsLoading);
  const pendingTaskListError = useSelector(selectPendingTasksError);

  const { t } = useTranslation('userTaskListPage');
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleGetPendingTaskList = useCallback((filterParams, isInitialRequest) => {
    dispatch(getPendingTasksListRequest({ isInitialRequest, filterParams }));
  }, [dispatch]);

  const handleGetCompletedTaskList = useCallback((filterParams, isInitialRequest) => {
    dispatch(getCompletedTasksListRequest({ isInitialRequest, filterParams }));
  }, [dispatch]);

  const handleClaimTask = useCallback((taskId, taskName) => {
    dispatch(claimTaskRequest({ taskId, taskName }));
  }, [dispatch]);

  const handleTabChange = useCallback((search: string) => {
    history.push({ search });
  }, [history]);

  useEffect(() => {
    return function cleanup() {
      dispatch(getCompletedTasksListClean());
      dispatch(getPendingTasksListClean());
    };
  }, [dispatch]);

  const isLoading = isPendingLoading
  || isCompletedLoading
  || isClaimTaskLoading;

  return (
    <StandardLayout
      title={t('pageTitle')}
      isLoading={isLoading}
      error={pendingTaskListError || completedTaskListError}
    >
      <Tabs
        classes={{ tabs: classes.tabsRoot }}
        tabProps={{ 'data-xpath': X_PATH.tabsButton }}
        tabQueryParam={TAB_QUERY_PARAM}
        onTabChange={handleTabChange}
        queryString={history.location.search}
      >
        <TabPanel title={t('components.tabs.inProgress')} code={UserTaskListTabs.active}>
          <PendingTasksTable
            tasksList={pendingTaskList}
            getPendingTasksList={handleGetPendingTaskList}
            claimTask={handleClaimTask}
            filterParams={pendingTaskFilterParams}
            isLoadingTasks={isPendingLoading}
          />
        </TabPanel>
        <TabPanel title={t('components.tabs.closed')} code={UserTaskListTabs.closed}>
          <CompletedTasksTable
            tasksList={completedTaskList}
            getCompetedTasksList={handleGetCompletedTaskList}
            filterParams={completedTaskFilterParams}
            isLoadingTasks={isCompletedLoading}
          />
        </TabPanel>
      </Tabs>
    </StandardLayout>
  );
}
