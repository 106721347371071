import React from 'react';
import { makeStyles } from '@material-ui/core';

import { ReactComponent as Icon } from '#web-components/assets/icons/checkMark.svg';
import styles from './CheckMarkIcon.styles';

const useStyles = makeStyles(styles, { name: 'CheckMarkIcon' });

export default function CheckMarkIcon() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Icon />
    </div>
  );
}
