import React from 'react';
import ReactDOM from 'react-dom';

import './localization';
import * as serviceWorker from './serviceWorker';
import App from './App';

global.APPLICATION_THEME = undefined as unknown as ApplicationTheme;

const renderReact = () => {
  ReactDOM.render(
    <App />,
    document.getElementById('root'),
  );
};

if (!REGISTRY_ENVIRONMENT_VARIABLES.themeFile) {
  renderReact();
} else {
  const themeScript = document.createElement('script');
  themeScript.setAttribute('src', `${process.env.PUBLIC_URL}/themes/${REGISTRY_ENVIRONMENT_VARIABLES.themeFile}`);
  themeScript.async = false;

  document.head.append(themeScript);

  themeScript.onload = renderReact;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
