import { Components } from 'react-formio';
import baseEmailForm from 'formiojs/components/email/Email.form';

import {
  withLegacyComponent,
} from '#web-components/components/Form/components/WithLegacyComponent/WithLegacyComponent';
import { addUniqClasses } from '#web-components/utils';

import { ComponentsConfig, FormioComponentName } from '../../types';
import { COMPONENT_CLASSES, defaultApiFields } from '../../constants';

const Email = (Components as ComponentsConfig).components.email;

export default class EmailLegacy extends withLegacyComponent(Email) {
  static schema() {
    return Email.schema({
      type: FormioComponentName.emailLegacy,
      key: 'emailLegacy',
    });
  }

  static get builderInfo() {
    return {
      ...Email.builderInfo,
      schema: EmailLegacy.schema(),
    };
  }

  static editForm = () => baseEmailForm([
    {
      key: 'api',
      components: defaultApiFields,
    },
  ]);

  constructor(component: Record<string, unknown>, options: Record<string, unknown>, data: unknown) {
    super(component, options, data);
    this.component.customClass = addUniqClasses(COMPONENT_CLASSES.bootstrapComponent, this.component.customClass);
  }

  checkMailBlackList(email: string) {
    const emailSplited = email?.split('@');
    const emailHost = emailSplited?.length ? emailSplited[1] : '';
    return this.options.blackList?.includes(emailHost);
  }

  checkComponentValidity(data: Record<string, unknown>, dirty: boolean, rowData: Record<string, unknown>): boolean {
    if (this.checkMailBlackList(data[this.key] as string)) {
      this.setComponentValidity(
        [{
          message: this.t(this.errorMessage('blacklist_email')),
          level: 'error',
        }],
        dirty,
      );
      return false;
    }
    return super.checkComponentValidity(data, dirty, rowData);
  }
}
