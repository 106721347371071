import { INITIAL_DATA_KEY } from '#web-components/components/Form/constants';

import { modifySelectRowData, checkRefresh } from '../../utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withLegacyComponent = (Component: any) => class FormioLegacyClass extends Component {
  checkCondition(row: Record<string, unknown>, data: Record<string, unknown>) {
    return super.checkCondition(modifySelectRowData(this.component, this.root, row), data);
  }

  checkRefresh(refreshData: string, changed: Record<string, unknown>, flags: Record<string, unknown>) {
    return checkRefresh.call(this, refreshData, changed, flags);
  }

  evalContext(additional: Record<string, unknown> = {}) {
    return super.evalContext({
      ...additional,
      data: {
        ...(this.options[INITIAL_DATA_KEY] || {}),
        ...this.rootValue,
      },
    });
  }
} as typeof Component;
