import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as Icon } from '#web-components/assets/icons/closeFullScreen.svg';

import styles from './CloseFullScreenIcon.styles';

type CloseFullScreenIconProps = {
  className?: string;
};

const useStyles = makeStyles(styles, { name: 'ArrowLeftIcon' });

export default function CloseFullScreenIcon({ className }: CloseFullScreenIconProps) {
  const classes = useStyles();
  return (
    <span className={clsx(classes.icon, className)}>
      <Icon />
    </span>
  );
}
