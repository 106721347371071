import { createStyles, Theme } from '@material-ui/core';

const styles = ({ spacing }: Theme) => createStyles({
  linkContainer: {
    marginBottom: spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  link: {
    display: 'inline-flex',
    alignItems: 'start',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  text: {
    paddingTop: '3px',
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: spacing(1.5),
  },
});

export default styles;
