import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserTaskHistory } from '#shared/types/task';
import { ErrorInfo } from '#shared/types/common';
import { ROWS_PER_PAGE_OPTIONS } from '#web-components/components/Table';
import { FilterParams, Order } from '#web-components/types/table';

const ACTION_PREFIX = 'completedTasks';

export interface CompletedTasksState {
  list: Array<UserTaskHistory>;
  count: number;
  filterParams: FilterParams;
}

export const getCompletedTasksListRequest = createAction<{
  filterParams: FilterParams, isInitialRequest?: boolean
}>(`${ACTION_PREFIX}/getCompletedTasksListRequest`);
export const getCompletedTasksListError = createAction<ErrorInfo>(`${ACTION_PREFIX}/getCompletedTasksListError`);

const initialState: CompletedTasksState = {
  list: [],
  count: 0,
  filterParams: {
    page: 0,
    rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
    order: Order.desc,
    orderField: 'endTime',
  },
};

const CompletedTasksSlice = createSlice({
  name: ACTION_PREFIX,
  initialState,
  reducers: {
    getCompletedTasksListSuccess(state: CompletedTasksState, action: PayloadAction<{
      list: Array<UserTaskHistory>,
      filterParams: FilterParams;
      count?: number;
    }>) {
      const { list, filterParams, count } = action.payload;
      return {
        ...state,
        list,
        filterParams,
        count: count || 0,
      };
    },
    getCompletedTasksListClean(state) {
      return {
        ...state,
        list: [],
        filterParams: initialState.filterParams,
        count: 0,
      };
    },
  },
});

export default CompletedTasksSlice.reducer;

export const {
  getCompletedTasksListSuccess,
  getCompletedTasksListClean,
} = CompletedTasksSlice.actions;
