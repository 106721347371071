import { createStyles, Theme } from '@material-ui/core';

import { styleFormIoComponent } from '#web-components/styles/form';
import { COMPONENT_CLASSES } from '../../constants';

export const SCROLLABLE_GRID_CLASS = 'formio-map_scrollable-grid';
export const OVERLAY_GRID = 'formio-map_overlay-grid';

export default ({ colors, spacing }: Theme) => createStyles({
  '@global': {
    ...styleFormIoComponent(COMPONENT_CLASSES.map, colors),
    [`.${SCROLLABLE_GRID_CLASS}`]: {
      overflowX: 'scroll',
      '& td[ref] > *': {
        width: spacing(25),
      },
    },
    [`.${OVERLAY_GRID}`]: {
      '& .row > *': {
        overflow: 'hidden',
        paddingLeft: spacing(1),
        paddingRight: spacing(1),
      },
    },
    '.leaflet-control-layers .leaflet-control-layers-list label': {
      display: 'block',
    },
  },
});
