import { createStyles, Theme } from '@material-ui/core/styles';
import { spacing } from '#web-components/styles';

const styles = ({ colors }: Theme) => createStyles({
  box: {
    marginTop: spacing * 8,
    marginBottom: spacing * 2,
  },
  message: {
    color: colors.textMainSecondary,
    marginTop: spacing * 2,
  },
  errorDescription: {
    marginTop: spacing * 3,
    marginBottom: spacing * 6,
  },
});

export default styles;
