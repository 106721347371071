import React from 'react';
import { Status } from 'reapop';

import {
  NotificationErrorIcon as ErrorIcon,
  NotificationSuccessIcon as SuccessIcon,
  NotificationWarningIcon as WarningIcon,
} from '#web-components/components/Icons';

interface FlashMessageIconProps {
  status: Status;
  customIcon?: React.ElementType;
  className?: string;
}

const FlashMessageIcon: React.FC<FlashMessageIconProps> = (props: FlashMessageIconProps) => {
  const { status, customIcon: CustomIcon, className = undefined } = props;

  if (CustomIcon) {
    return <CustomIcon className={className} />;
  }

  switch (status) {
    case 'error':
      return <ErrorIcon size={28} className={className} />;
    case 'warning':
      return <WarningIcon size={28} className={className} />;
    case 'success':
      return <SuccessIcon size={28} className={className} />;
    default:
      return null;
  }
};

export default FlashMessageIcon;
