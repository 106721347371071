import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import {
  filter, map, mergeMap,
} from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import * as historyApi from 'api/history';
import { tryAgainNotificationErrorProps } from 'constants/errorProps';
import { getCriticalErrorProps, catchError, getNotificationErrorProps } from '#shared/utils/apiHelpers';
import {
  getCompletedTasksListSuccess,
  getCompletedTasksListError,
  getCompletedTasksListRequest,
} from './slice';

export const getCompetedTasksListEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$.pipe(
    filter(getCompletedTasksListRequest.match),
    mergeMap(({ payload }) => {
      const { filterParams, isInitialRequest } = payload;
      const {
        page,
        rowsPerPage,
        order,
        orderField,
      } = filterParams;
      const firstResult = page * rowsPerPage;
      const arrApi = [
        historyApi.getCompletedTasksList({
          offset: firstResult,
          limit: rowsPerPage + 1,
          sort: `${order}(${orderField})`,
        }),
      ];
      return forkJoin(arrApi).pipe(
        map(([taskListResp]) => getCompletedTasksListSuccess({
          list: taskListResp.response,
          filterParams,
        })),
        catchError((serverResponse) => of(getCompletedTasksListError(
          isInitialRequest
            ? getCriticalErrorProps({ serverResponse })
            : getNotificationErrorProps(serverResponse.response, tryAgainNotificationErrorProps),
        ))),
      );
    }),
  );
};
