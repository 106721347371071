import { createStyles } from '@material-ui/core';

import { spacing } from '#web-components/styles';

const styles = () => createStyles({
  root: {
    marginTop: spacing * 8,
    maxWidth: spacing * 80,
  },
  title: {
    marginTop: spacing * 2,
  },
  link: {
    marginTop: spacing * 2,
  },
});

export default styles;
