import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import { makeStyles } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import SidebarLayout from 'components/Layouts/Sidebar';
import {
  getSettingsClean,
  getSettingsRequest,
  validateChannelRequest,
  selectSettingsIsLoading,
  selectSettingsError,
  selectChannelEmail,
  selectValidateChannelValidationError,
  validateChannelClean,
} from 'store/settings';
import { emailBlacklistValidator } from 'utils/formControls';
import useQuery from 'hooks/useQuery';
import { ChannelType } from 'types/settings';
import { ROUTES } from 'constants/routes';
import { EMAIL_PATTERN } from '#shared/constants/formControls';
import Input from '#web-components/components/FormControls/Input';
import Typography from '#web-components/components/Typography';
import Button, { ButtonVariants } from '#web-components/components/Button';

import styles from './ProfileEditPage.styles';

const useStyles = makeStyles(styles, { name: 'ProfilePage' });

export default function ProfileEditPage() {
  const history = useHistory();
  const { state: locationState = {} } = useLocation<{ isEditEmail?: boolean }>();

  const { t } = useTranslation('profilePage');
  const classes = useStyles();
  const dispatch = useDispatch();
  const settings = useSelector(selectChannelEmail);
  const isLoading = useSelector(selectSettingsIsLoading);
  const error = useSelector(selectSettingsError);
  const serverValidateErrorMessage = useSelector(selectValidateChannelValidationError);
  const addressQuery = useQuery().get('address');

  const methods = useForm({
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: {
      email: addressQuery || settings?.address || '',
    },
  });
  const {
    control, setValue, getValues, setError,
  } = methods;

  const handleBackLinkClick = useCallback((e) => {
    e.preventDefault();
    history.push(ROUTES.PROFILE);
  }, [history]);

  useEffect(() => {
    dispatch(getSettingsRequest());
    return () => {
      dispatch(getSettingsClean());
    };
  }, [dispatch, setError]);

  useEffect(() => {
    if (settings) {
      const { address } = settings;
      setValue('email', addressQuery || address || '');
    }
  }, [settings, setValue, addressQuery]);

  useEffect(() => {
    if (serverValidateErrorMessage) {
      setError('email', { message: serverValidateErrorMessage });
    }

    return () => {
      dispatch(validateChannelClean());
    };
  }, [dispatch, serverValidateErrorMessage, setError]);

  const onSubmit = useCallback(() => {
    const data = getValues();
    dispatch(replace(`${ROUTES.PROFILE_EDIT}?address=${data.email}`));
    dispatch(validateChannelRequest({
      channel: ChannelType.EMAIL,
      address: data.email,
    }));
  }, [dispatch, getValues]);

  const handleSubmit = useCallback(() => {
    methods.handleSubmit(onSubmit)();
  }, [methods, onSubmit]);

  const onCancel = useCallback((e) => {
    e.preventDefault();
    history.goBack();
  }, [history]);

  const emailValidator = (email: string) => {
    if (emailBlacklistValidator(email)) {
      return t('common:errors.validation.emailBlacklist');
    }

    if (locationState.isEditEmail && settings?.address === email) {
      return t('edit.validation.emailMatchesCurrentOne');
    }

    return true;
  };

  return (
    <SidebarLayout
      backLinkPath=""
      title={locationState.isEditEmail ? t('edit.editEmailTitle') : t('edit.addEmailTitle')}
      onBackLinkClick={handleBackLinkClick}
      backLinkCaption={t('edit.backToProfile')}
      isLoading={isLoading}
      error={error}
      sideBarContent={(
        <>
          <Typography variant="h5" className={classes.sidebarTitle}>{t('edit.sidebarTitle')}</Typography>
          <Typography variant="bodyText">{t('edit.sidebarDescription')}</Typography>
        </>
      )}
    >
      <form className={classes.root}>
        <Controller
          name="email"
          control={control}
          rules={{
            required: t('common:errors.validation.required') as string,
            pattern: { value: EMAIL_PATTERN, message: t('edit.validation.emailPattern') },
            validate: emailValidator,
          }}
          render={({ field, fieldState }) => (
            <Input
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              label={t('edit.fields.email.label')}
              error={fieldState.error}
              placeholder={t('edit.fields.email.placeholder')}
              description={t('edit.fields.email.hint')}
              isLabelShrink
              fullWidth
            />
          )}
        />
        <div className={classes.buttonContainer}>
          <Button variant={ButtonVariants.secondary} onClick={onCancel}>
            {t('edit.cancelButton')}
          </Button>
          <Button variant={ButtonVariants.primary} onClick={handleSubmit} disabled={!isEmpty(methods.formState.errors)}>
            {t('edit.submitButton')}
          </Button>
        </div>
      </form>
    </SidebarLayout>
  );
}
