import { TAB_QUERY_PARAM, ProcessInstanceListTabs } from './tabs';
import packageJson from '../../package.json';

export const APP_URL_PREFIX = packageJson.homepage;

export const ROUTES = {
  PROCESS_LIST: '/process-list',
  PROCESS_START_FORM: '/process-list/:processDefinitionKey/start-form',
  PROCESS_INSTANCE_LIST: '/process-instance-list',
  USER_TASK_LIST: '/user-tasks-list',
  LOGIN: '/login',
  HOME: '/home',
  USER_TASK: '/user-tasks-list/:taskId',
  REPORTS_LIST: '/reports-list',
  FORBIDDEN: '/forbidden',
  LOGOUT: '/login?logout',
  PROFILE: '/profile',
  PROFILE_CONFIRM_CHANNEL: '/profile/:channel/confirm',
  PROFILE_EDIT: '/profile/edit',
  NOTIFICATION_LIST: '/notification-list',
};

export const TAB_ROUTES = {
  PROCESS_INSTANCE_LIST_ACTIVE: `${ROUTES.PROCESS_INSTANCE_LIST}?${TAB_QUERY_PARAM}=${ProcessInstanceListTabs.active}`,
  PROCESS_INSTANCE_LIST_ENDED: `${ROUTES.PROCESS_INSTANCE_LIST}?${TAB_QUERY_PARAM}=${ProcessInstanceListTabs.ended}`,
};
