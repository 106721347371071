import { createStyles, Theme } from '@material-ui/core/styles';
import { spacing } from '#web-components/styles';

interface Props {
  direction: 'right' | 'left';
  fullWidth?: boolean;
}

const styles = ({ colors }: Theme) => createStyles({
  logo: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: spacing * 2,
    paddingBottom: spacing * 2,
    '& svg': {
      fill: colors.uIBase,
    },
  },
  title: ({ direction, fullWidth }: Props) => {
    const isRight = direction === 'right';
    return {
      marginLeft: isRight ? 0 : spacing,
      textAlign: isRight ? 'right' : 'left',
      marginRight: isRight ? spacing : 0,
      flexGrow: 1,
      maxWidth: !fullWidth ? spacing * 22 : 'auto',
    };
  },
});

export default styles;
