import { createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import {
  spacing,
  bodyText,
} from '#web-components/styles';

const styles = ({ colors }: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: spacing * 2,
    paddingBottom: spacing * 2,
  },
  'react-datepicker__custom-header': {
    display: 'flex',

    '& .react-datepicker__year-btn': {
      ...bodyText,
      display: 'flex',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      color: colors.textMainPrimary,

      '& .react-datepicker__year-icon__active': {
        transform: 'rotate(180deg)',
      },
    },

    '& .react-datepicker__custom-header__month': {
      position: 'relative',
      width: '80%',

      '& .react-datepicker__navigation': {
        top: -spacing / 1.5,
      },
    },

    '& .react-datepicker-year': {
      color: colors.textMainPrimary,
      display: 'none',
      position: 'absolute',
      top: `${spacing + 50}px`,
      left: 0,
      right: 0,
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',

      '&__active': {
        display: 'block',
      },

      '& .react-datepicker': {
        background: colors.dropmenuBackground,
        color: colors.textMainPrimary,
        border: 'none',
        height: '350px',
        zIndex: 1,
      },

      '& .react-datepicker__navigation': {
        top: spacing,
      },

      '& .react-datepicker-year-header': {
        color: colors.textMainPrimary,
      },

      '& .react-datepicker__year-wrapper': {
        maxWidth: 'none',
        height: '100%',

        '& .react-datepicker__year-text': {
          margin: `${spacing * 0.5}px ${spacing * 2}px`,
          height: spacing * 5,
          paddingTop: spacing,
          ...bodyText,

          '&:hover': {
            backgroundColor: colors.dropmenuHover,
          },
        },
        '& .react-datepicker__year-text--selected': {
          backgroundColor: colors.dropmenuSelected,
          color: colors.textMainPrimary,
        },
      },
    },
  },
});

export default styles;
