import { ActionsObservable } from 'redux-observable';
import { Action } from '@reduxjs/toolkit';
import { of } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
} from 'rxjs/operators';

import * as api from 'api/notifications';
import { tryAgainNotificationErrorProps } from 'constants/errorProps';
import { catchError, getComponentErrorProps, getNotificationErrorProps } from '#shared/utils/apiHelpers';
import {
  getNotificationListRequest,
  getNotificationListSuccess,
  getNotificationListError,
  acknowledgeNotificationRequest,
  acknowledgeNotificationSuccess,
} from './slice';

export const getNotificationListEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$
    .pipe(
      filter(getNotificationListRequest.match),
      mergeMap(({ payload }) => {
        const { paginationParams, isInitialRequest } = payload;
        const {
          page,
          rowsPerPage,
        } = paginationParams;
        const firstResult = page * rowsPerPage;
        return api.getNotificationList({
          offset: firstResult,
          limit: rowsPerPage + 1,
        }).pipe(
          map((response) => {
            return getNotificationListSuccess({
              list: response.response,
              paginationParams,
            });
          }),
          catchError((serverResponse) => {
            return of(
              getNotificationListError(isInitialRequest
                ? getComponentErrorProps({ serverResponse })
                : getNotificationErrorProps(serverResponse.response, tryAgainNotificationErrorProps)),
            );
          }),
        );
      }),
    );
};

export const acknowledgeNotificationEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$
    .pipe(
      filter(acknowledgeNotificationRequest.match),
      mergeMap(({ payload }) => {
        const { notificationId } = payload;

        return api.acknowledgeNotification(notificationId)
          .pipe(
            map(() => (acknowledgeNotificationSuccess({ notificationId }))),
            catchError((serverResponse) => {
              return of(getNotificationListError(
                serverResponse.status !== 403
                  ? getNotificationErrorProps(serverResponse.response, tryAgainNotificationErrorProps)
                  : getComponentErrorProps({ serverResponse }),
              ));
            }),
          );
      }),
    );
};
