import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Typography from '#web-components/components/Typography';
import useTimer from '#web-components/hooks/useTimer';

import styles from './QRCode.styles';

export type QRCodeProps = {
  url: string;
  qrCode: string;
  title: string;
  description: string;
  timerText: string;
  onTime: (time: number) => void,
};

const useStyles = makeStyles(styles, { name: 'QRCode' });

export default function QRCode({
  url, qrCode, title, description, timerText, onTime,
}: QRCodeProps) {
  const classes = useStyles();
  const timeout = 3 * 60; // 3 minutes
  const { timeFormatted, time } = useTimer(timeout, 'uk');
  const src = `data:image/bmp;base64,${qrCode}`;

  useEffect(() => {
    onTime(time);
  }, [time, onTime]);

  return (
    <Box className={classes.container}>
      <Typography variant="h7">{title}</Typography>
      <a href={url} className={classes.link} target="_blank" rel="noreferrer">
        <img src={src} alt={title} />
      </a>
      <Typography variant="h7" className={classes.description}>{description}</Typography>
      <Typography variant="h7">{timerText} {timeFormatted}</Typography>
    </Box>
  );
}
