import React from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import '#web-components/styles/index';
import './App.scss';
import 'formiojs/dist/formio.full.css';

import Notifications from 'components/Notifications';
import ErrorBoundary from 'components/ErrorBoundary';
import Routes from './routes/Routes';
import { history } from './store/history';
import { store } from './store/store';
import createTheme from './styles/theme';

const App: React.FC = () => (
  <ThemeProvider theme={createTheme()}>
    <CssBaseline />
    <ErrorBoundary>
      <Router history={history}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Notifications />
            <Routes />
          </ConnectedRouter>
        </Provider>
      </Router>
    </ErrorBoundary>
  </ThemeProvider>
);

export default App;
