import React, { useCallback, useState } from 'react';
import FlashMessage, { ViewType } from '#web-components/components/FlashMessage';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Typography from '#web-components/components/Typography';
import { NotificationWarningIcon } from '#web-components/components/Icons';
import ConfirmModal from '#web-components/components/ConfirmModal';
import { isBrowserSupported } from '#web-components/utils/common';
import styles from './UnsupportedBrowserNotification.styles';

const UNSUPPORTED_BROWSERS_ACK_KEY = 'unsupportedBrowsersAck';

const useStyles = makeStyles(styles, { name: 'UnsupportedBrowserNotification' });

export interface UnsupportedBrowserNotificationProps {
  t?: (key: string) => string;
  wideBanner?: boolean;
}

export default function UnsupportedBrowserNotification(
  { wideBanner, t = (key) => key }: UnsupportedBrowserNotificationProps,
) {
  const classes = useStyles();
  const browserIsOk = isBrowserSupported();

  const [open, setOpen] = useState(localStorage.getItem(UNSUPPORTED_BROWSERS_ACK_KEY) !== 'true' && !browserIsOk);

  const handleCloseModal = useCallback(() => {
    setOpen(false);
    localStorage.setItem(UNSUPPORTED_BROWSERS_ACK_KEY, 'true');
  }, []);
  const handleBannerClick = useCallback(() => {
    setOpen(true);
  }, []);
  return (
    <>
      <FlashMessage
        className={clsx(classes.root, { [classes.displayNone]: browserIsOk, [classes.wideBanner]: wideBanner })}
        status="warning"
        viewType={ViewType.plain}
        message={t('components.unsupportedBrowserNotification.bannerMessage')}
        onClick={handleBannerClick}
      />
      <ConfirmModal
        isOpen={open}
        submitText={t('components.unsupportedBrowserNotification.modalButton')}
        onOpenChange={() => setOpen(true)}
        onSubmit={handleCloseModal}
        icon={<Typography variant="h1"><NotificationWarningIcon size={56} /></Typography>}
        confirmationText={t('components.unsupportedBrowserNotification.modalMessage')}
      />
    </>
  );
}
