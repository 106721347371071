import React, { useCallback, useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ErrorInfo } from '#shared/types/common';
import Typography from '#web-components/components/Typography';
import Button from '#web-components/components/Button';
import { SystemErrorDescription, ErrorSideBarContent } from '#web-components/components/Layouts/Error';
import { NotificationErrorIcon as FireIcon } from '#web-components/components/Icons';
import styles from './CriticalError.styles';

const useStyles = makeStyles(styles, { name: 'CriticalError' });

interface CriticalErrorProps {
  error: ErrorInfo
}

export default function CriticalError({ error }: CriticalErrorProps) {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const reloadPage = useCallback(() => {
    window.location.reload();
  }, []);

  const errorFields = useMemo(() => {
    const errorInfoFields = [{
      name: t('components.errorLayout.pageUrl'),
      value: window.location.href,
    }];
    if (error.traceId) {
      errorInfoFields.unshift({
        name: t('components.errorLayout.traceId'),
        value: error.traceId,
      });
    }
    return errorInfoFields;
  }, [error.traceId, t]);

  return (
    <>
      <Grid container className={classes.box}>
        <FireIcon size={56} />
      </Grid>
      <Grid container>
        <Grid item xs={7}>
          <Typography variant="h3">
            {t('components.errorLayout.smthWentWrong')}
          </Typography>
          <Typography variant="body1" className={classes.message}>
            {t('components.errorLayout.reloadPageAndContactAdministrator')}
          </Typography>
          <SystemErrorDescription
            className={classes.errorDescription}
            title={t('components.errorLayout.systemErrorDescription')}
            fields={errorFields}
          />
          <Button onClick={reloadPage}>
            {t('components.errorLayout.reloadPage')}
          </Button>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <ErrorSideBarContent
            title={t('components.errorLayout.needHelp')}
            description={t('components.errorLayout.copySystemErrorDescription')}
            mail={{
              email: REGISTRY_ENVIRONMENT_VARIABLES.supportEmail,
              subject: t('errors.supportEmail.subject'),
              body: t('errors.supportEmail.body'),
            }}
            errorData={errorFields}
          />
        </Grid>
      </Grid>
    </>
  );
}
