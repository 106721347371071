import { createStyles, Theme } from '@material-ui/core';
import { spacing } from '#web-components/styles';

const styles = ({ colors }: Theme) => createStyles({
  notificationsContainer: {
    paddingBottom: spacing * 4,
    maxWidth: spacing * 92,
  },
  pagination: {
    borderTop: `2px solid ${colors.uIBase}`,
  },
});

export default styles;
