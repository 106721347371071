import React from 'react';
import { ReactComponent } from 'react-formio';
import get from 'lodash/get';
import { of, Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { addUniqClasses } from '#web-components/utils';

import CommonFormioComponent from '../CommonFormioComponent';
import { FormComponent, FormioComponentName } from '../../types';
import { COMPONENT_CLASSES } from '../../constants';

import settingsForm from './CustomMapSettings';
import MapAdapter from './MapAdapter';

class CustomMap extends CommonFormioComponent {
  static get builderInfo() {
    return {
      title: 'Map',
      icon: 'home',
      group: 'basic',
      documentation: '',
      weight: 70,
      schema: CustomMap.schema(),
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: FormioComponentName.map,
      label: 'Map',
      key: 'map',
      dataGridLabel: true,
      labelPosition: 'right',
      value: '',
    });
  }

  static editForm = settingsForm;

  geoJSONMap: Record<string, AjaxResponse<unknown>> = {};

  constructor(component: Record<string, unknown>, options: Record<string, unknown>, data: unknown) {
    super(component, options, data);
    this.component.customClass = addUniqClasses(COMPONENT_CLASSES.map, this.component.customClass);
  }

  get componentDefinition() {
    return this.component as FormComponent;
  }

  getValueAsString() {
    return '';
  }

  get emptyValue() {
    return get(this.componentDefinition, 'singleValue') ? {} : [];
  }

  loadGeoJSON = (url: string): Observable<AjaxResponse<unknown>> => {
    if (this.geoJSONMap[url]) {
      return of(this.geoJSONMap[url]);
    }

    return ajax({
      method: 'GET',
      url,
      crossDomain: true,
    }) as unknown as Observable<AjaxResponse<unknown>>;
  };

  renderReact = () => {
    return (
      <MapAdapter
        theme={this.root.options.theme}
        ref={(ref: HTMLElement) => this.initReactInstance(ref)}
        component={this.componentDefinition}
        name={get(this, 'info.attr.name')}
        value={this.dataValue}
        onChange={this.updateValue}
        loadGeoJSON={this.loadGeoJSON}
        error={this.error}
        disabled={this.disabled}
        id={this.id}
      />
    );
  };
}

export default CustomMap;
