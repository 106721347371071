import { GeometryObject, Position } from 'geojson';
import isEmpty from 'lodash/isEmpty';

import { GeometryType, GeometryValue } from '#web-components/components/Map/types';

const createDot = (coordinatesPair: Position) => {
  return {
    longitude: coordinatesPair[0].toString(),
    latitude: coordinatesPair[1].toString(),
  };
};

export const convertFromLeafletFormat = (geometry: GeometryObject): GeometryValue | undefined => {
  if (geometry.type === 'Point') {
    return {
      geometry: {
        type: GeometryType.point,
        ...createDot(geometry.coordinates),
      },
    };
  }

  if (geometry.type === 'Polygon') {
    return {
      geometry: {
        type: GeometryType.polygon,
        dots: geometry.coordinates[0].map(createDot),
      },
    };
  }

  if (geometry.type === 'LineString') {
    return {
      geometry: {
        type: GeometryType.line,
        dots: geometry.coordinates.map(createDot),
      },
    };
  }

  return undefined;
};

const createCoordinates = (geometryObject: GeometryValue['geometry'] | { longitude: string, latitude: string }) => {
  return [parseFloat(geometryObject.longitude || ''), parseFloat(geometryObject.latitude || '')];
};

export const convertToLeafletFormat = (geometry: GeometryValue): GeometryObject | undefined => {
  if (geometry.geometry.type === GeometryType.point) {
    return {
      type: 'Point',
      coordinates: createCoordinates(geometry.geometry),
    };
  }

  if (geometry.geometry.type === GeometryType.polygon && geometry.geometry.dots) {
    return {
      type: 'Polygon',
      coordinates: [
        geometry.geometry.dots.map(createCoordinates),
      ],
    };
  }

  if (geometry.geometry.type === GeometryType.line && geometry.geometry.dots) {
    return {
      type: 'LineString',
      coordinates: [
        ...geometry.geometry.dots.map(createCoordinates),
      ],
    };
  }

  return undefined;
};

export const getGeometryValue = (value: Array<GeometryValue> | GeometryValue, singleValue?: boolean) => {
  const valueFromSingleObject = isEmpty(value) ? [] : [value as GeometryValue];
  const valueArray: Array<GeometryValue> = singleValue && !Array.isArray(value)
    ? valueFromSingleObject
    : value as Array<GeometryValue>;
  return valueArray.map(convertToLeafletFormat);
};

export const convertFromGeometry = (geoObject: { features: Array<{ geometry: GeometryObject }> }) => {
  const features = geoObject.features || [];
  return features
    .map((feature) => convertFromLeafletFormat(feature.geometry))
    .filter((feature) => !!feature) as Array<GeometryValue>;
};
