import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorInfo } from '#shared/types/common';
import { Notification } from 'types/notification';
import { FilterParamsWithoutOrdering } from '#web-components/types/table';
import { ROWS_PER_PAGE_OPTIONS } from '#web-components/components/Table';

const ACTION_PREFIX = 'userNotifications';

export interface UserNotificationsState {
  list: Array<Notification>,
  paginationParams: FilterParamsWithoutOrdering;
}

const initialState: UserNotificationsState = {
  list: [],
  paginationParams: {
    page: 0,
    rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
  },
};

export const getNotificationListRequest = createAction<{
  paginationParams: FilterParamsWithoutOrdering, isInitialRequest?: boolean
}>(`${ACTION_PREFIX}/getNotificationListRequest`);
export const getNotificationListError = createAction<ErrorInfo>(`${ACTION_PREFIX}/getNotificationListError`);

export const acknowledgeNotificationRequest = createAction<{
  notificationId: string
}>(`${ACTION_PREFIX}/acknowledgeNotificationRequest`);

const NotificationListSlice = createSlice({
  name: ACTION_PREFIX,
  initialState,
  reducers: {
    getNotificationListSuccess(state, action: PayloadAction<{
      list: Array<Notification>,
      paginationParams: FilterParamsWithoutOrdering,
    }>) {
      const { list, paginationParams } = action.payload;
      return {
        ...state,
        list,
        paginationParams,
      };
    },
    acknowledgeNotificationSuccess(state, action: PayloadAction<{
      notificationId: string
    }>) {
      const { notificationId } = action.payload;

      return {
        ...state,
        list: state.list.map((item) => (item.id === notificationId ? { ...item, isAcknowledged: true } : item)),
      };
    },
  },
});

export default NotificationListSlice.reducer;

export const {
  getNotificationListSuccess,
  acknowledgeNotificationSuccess,
} = NotificationListSlice.actions;
