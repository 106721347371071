import { createStyles } from '@material-ui/core';
import { spacing } from '#web-components/styles';

const styles = () => createStyles({
  search: {
    width: spacing * 32,
  },
});

export default styles;
