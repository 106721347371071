import React from 'react';
import { AppBar, makeStyles, Toolbar } from '@material-ui/core';
import clsx from 'clsx';
import Divider from '#web-components/components/Divider';
import styles from './Navbar.styles';

const useStyles = makeStyles(styles, { name: 'Navbar' });

export interface NavbarProps {
  // eslint-disable-next-line react/no-unused-prop-types
  disableBackground?: boolean; // TODO: remove prop
  children?: React.ReactNode;
  'data-xpath'?: string;
  className?: string;
}

const Navbar = (props: NavbarProps) => {
  const { children, 'data-xpath': dataXpath, className } = props;
  const classes = useStyles(props);

  return (
    <div className={clsx(classes.root, className)} data-xpath={dataXpath}>
      <AppBar position="static" classes={{ colorPrimary: classes.appBarColorPrimary, root: classes.appBar }}>
        <Toolbar disableGutters classes={{ root: classes.appBar }}>
          { children }
        </Toolbar>
        <Divider />
      </AppBar>
    </div>
  );
};

export default Navbar;
