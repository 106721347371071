import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, makeStyles } from '@material-ui/core';
import StandardLayout from 'components/Layouts/Standard';
import { X_PATH } from 'constants/xPath';
import InlineButton from '#web-components/components/InlineButton';
import Typography from '#web-components/components/Typography';
import { ReactComponent as LinkIcon } from '#web-components/assets/icons/link.svg';
import { FileIcon } from '#web-components/components/Icons';
import styles from './ReportListPage.styles';

const useStyles = makeStyles(styles, { name: 'ReportListPage' });
export default function ReportListPage() {
  const classes = useStyles();
  const { t } = useTranslation('reportListPage');

  const handleClick = useCallback(() => {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = ENVIRONMENT_VARIABLES.redashUrl;
    a.click();
  }, []);

  return (
    <StandardLayout
      title={t('title')}
    >
      <Grid container>
        <Grid item sm={7}>
          <FileIcon size={56} className={classes.file} />
          <Typography variant="bodyText">
            {t('description')}
          </Typography>
          <Box className={classes.box}>
            <InlineButton
              data-xpath={X_PATH.reportsLinkButton}
              size="medium"
              leftIcon={<LinkIcon />}
              onLinkClick={handleClick}
            >
              {t('linkText')}
            </InlineButton>
          </Box>
        </Grid>
      </Grid>
    </StandardLayout>
  );
}
