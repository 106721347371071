/* eslint-disable no-param-reassign */
// eslint-disable-next-line max-classes-per-file
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import isArray from 'lodash/isArray';
import omit from 'lodash/omit';
import isNumber from 'lodash/isNumber';
import flatMapDeep from 'lodash/flatMapDeep';
import clsx, { ClassValue } from 'clsx';
import pick from 'lodash/pick';
import trim from 'lodash/trim';
import map from 'lodash/map';
import isNaN from 'lodash/isNaN';
import isString from 'lodash/isString';
import { Formio } from 'formiojs';

import { formatDateTimeToISO, getDateFromDateTime } from '#web-components/utils/dateTime';
import { parse } from 'date-fns';
import uk from 'date-fns/locale/uk';
import {
  Column,
  FileComponent,
  FileFullResponse,
  FileShortResponse,
  FormComponent,
  FormioComponentName,
  FormSubmission,
  SelectComponent,
  TableComponent,
} from './types';
import {
  THOUSANDS_SEPARATOR, DECIMAL_SEPARATOR, NAVIGATION_CODE, SELECTION_FIELD, ACTION_SELECTION_FIELD,
} from './constants';

export const isFileComponent = (type: string): boolean => (
  type === 'file' || type === 'fileLatest' || type === 'fileLegacy' || type === 'dataImport'
);

export const isTextFieldComponent = (type: string): boolean => (
  type === 'textfield' || type === 'textfieldLatest'
);

export const isEditGridComponent = (type: string): boolean => (
  type === 'editgrid' || type === 'editgridLatest' || type === 'editgridLegacy'
);

export const isTableComponent = (type: string): boolean => (
  type === 'table' || type === 'tableLatest' || type === 'tableLegacy'
);

export const isColumnsComponent = (type: string): boolean => (
  type === 'columns' || type === 'columnsLatest' || type === 'columnsLegacy'
);

export const isFieldsetComponent = (type: string): boolean => (
  type === 'fieldset' || type === 'fieldsetLatest' || type === 'fieldsetLegacy'
);

export const isDateTimeComponent = (type: string): boolean => (
  type === 'datetime' || type === 'datetimeLatest' || type === 'datetimeLegacy'
);
// added to counter formio's internal cache which is impossible to disable
export const disableComponentDataCache = (component: FormComponent) => {
  const noCacheQueryParam = `noCache=${Date.now()}`;

  return {
    ...component,
    filter: component.filter ? `${component.filter}&${noCacheQueryParam}` : noCacheQueryParam,
  } as FormComponent;
};

export const sanitizeComponent = (component: FormComponent) => {
  const result = { ...component };
  // TODO: id field breaks form renderer for unknown reasons. Figure out why
  delete result.id;

  return result as FormComponent;
};

export const allowLocalDevFileUpload = (component: FormComponent) => {
  if (process.env.NODE_ENV !== 'production' && isFileComponent(component.type)) {
    const fileComponent = component as FileComponent;
    return {
      ...fileComponent,
      url: fileComponent.url?.startsWith('/') ? Formio.getBaseUrl().concat(fileComponent.url) : fileComponent.url,
      options: {
        ...(fileComponent.options && { ...fileComponent.options }),
        withCredentials: 'include',
      },
    };
  }
  return component;
};

const convertDateFromFormIOFormat = (dateTime: string, dayFirst: boolean) => {
  const date = dateTime.split('/');
  const year = date[2];
  const month = date[dayFirst ? 1 : 0];
  const day = date[dayFirst ? 0 : 1];

  if (day === '00' && year === '0000' && month === '00') {
    return null;
  }

  return `${year}-${month}-${day}`;
};

export const formatDateComponentDateTime = (dateTime: string, withoutTime: boolean) => {
  if (withoutTime) {
    return getDateFromDateTime(dateTime);
  }
  return formatDateTimeToISO(dateTime);
};

const formatFileResponse = (file: FileFullResponse[]): FileShortResponse[] => {
  const fieldsToSave: Array<keyof FileShortResponse> = ['id', 'checksum'];
  return isArray(file)
    ? file.map((el) => pick(el.data, fieldsToSave))
    : [];
};

const convertDateToFormIOFormat = (dateTime: string, dayFirst: boolean) => {
  const date = dateTime.split('-');
  const year = date[0];
  const month = date[1];
  const day = date[2];
  if (dayFirst) {
    return `${day}/${month}/${year}`;
  }
  return `${month}/${day}/${year}`;
};

export const convertSubmissionData = (
  components: Array<FormComponent>,
  data: Record<string, unknown>,
  converter: (value: unknown, component: FormComponent) => unknown,
): Record<string, unknown> => {
  return mapValues(data, (value, key) => {
    const componentDefinitionFirstLevel = components.find((component) => component.key === key);
    // fieldset does not have its own data - data from nested components is used instead
    const fieldsetComponents = components.filter((component) => isFieldsetComponent(component.type));
    const componentDefinitionInFieldset = fieldsetComponents
      .flatMap((fieldsetComponent) => get(fieldsetComponent, 'components', []))
      .find((component: FormComponent) => component.key === key);
    const componentDefinitionInColumns = components
      .filter((c) => isColumnsComponent(c.type))
      .flatMap((c) => get(c, 'columns', []))
      .flatMap((column) => get(column, 'components', []))
      .find((component: FormComponent) => component.key === key);
    const componentDefinitionInTable = components
      .filter((c) => isTableComponent(c.type))
      .flatMap((c) => get(c, 'rows', []))
      .flatMap((item) => item)
      .flatMap((column) => get(column, 'components', []))
      .find((component: FormComponent) => component.key === key);

    const componentDefinition = componentDefinitionFirstLevel
      || componentDefinitionInFieldset
      || componentDefinitionInTable
      || componentDefinitionInColumns;
    const nestedComponents = get(componentDefinition, 'components', []) as Array<FormComponent>;

    if (componentDefinition) {
      const convertedValue = converter(value, componentDefinition);
      if (nestedComponents?.length && isArray(convertedValue)) {
        return convertedValue.map((nestedValue) => convertSubmissionData(nestedComponents, nestedValue, converter));
      }

      return convertedValue;
    }

    return value;
  });
};

export const convertSubmission = (
  components: Array<FormComponent>,
  // eslint-disable-next-line @typescript-eslint/default-param-last
  formSubmission: FormSubmission = { data: {} },
  converter: (value: unknown, component: FormComponent) => unknown,
): FormSubmission => {
  return {
    ...formSubmission,
    data: convertSubmissionData(components, formSubmission.data, converter),
  };
};

export const prepareSubmissions = (
  components: Array<FormComponent>,
  formSubmission: FormSubmission | undefined,
  options?: {
    isFormData?: boolean,
    forStorage?: boolean, // pass true if submission is prepared not for request but for storage
  },
) => {
  const isFormData = options?.isFormData;
  const forStorage = options?.forStorage;
  let submission = cloneDeep(formSubmission);

  if (!submission) {
    return submission;
  }

  submission = convertSubmission(components, submission, (value, component) => {
    if (component.type === 'day' && value) {
      const date = value as string;
      const dayFirst = get(component, 'dayFirst', false);
      const formatDate = isFormData ? convertDateFromFormIOFormat : convertDateToFormIOFormat;

      return formatDate(date, dayFirst);
    }

    return value;
  });

  submission = convertSubmission(components, submission, (value, component) => {
    const textfieldComponent = component as unknown as {
      type: FormioComponentName.textfieldLatest | FormioComponentName.textfield,
      trimSpaces: boolean,
      phoneInput?: boolean,
      inputMask?: string,
    };
    const insertNumbersIntoMask = (numberValue: string, inputMask: string) => {
      let index = -1;
      return map(inputMask, (char) => {
        if (isNaN(+char)) {
          return char;
        }

        index += 1;
        return numberValue[index] || '';
      }).join('');
    };

    if (isTextFieldComponent(textfieldComponent.type) && value) {
      let resultValue = textfieldComponent.trimSpaces && isString(value) ? trim(value as string) : value as string;
      if (textfieldComponent.phoneInput && isString(resultValue)) {
        if (isFormData) {
          resultValue = resultValue.replace(/\D/g, '');
        } else if (textfieldComponent.inputMask) {
          resultValue = insertNumbersIntoMask(resultValue, textfieldComponent.inputMask);
        }
      }

      return resultValue;
    }

    return value;
  });

  // TODO: To be changed after 'Latest' components are removed: remove this for Latest version of DateTime
  submission = convertSubmission(components, submission, (value, component) => {
    if (component.type === 'datetime' && value) {
      const dateTime = value as string;
      return formatDateComponentDateTime(dateTime, !component.enableTime);
    }

    return value;
  });

  submission = convertSubmission(components, submission, (value, component) => {
    if (isFileComponent(component?.type) && !value) {
      return [];
    }

    return value;
  });

  if (!forStorage) {
    submission = convertSubmission(components, submission, (value, component) => {
      if (isEditGridComponent(component?.type) && value) {
        const editGridValue = value as Array<Record<string, unknown>>;
        if (isFormData) {
          const hasSelection = get(component, 'multipleSelection')
            || editGridValue.find((record) => get(record, SELECTION_FIELD))
            || editGridValue.find((record) => get(record, ACTION_SELECTION_FIELD));

          return !hasSelection ? editGridValue : editGridValue
            .filter((record) => get(record, SELECTION_FIELD) || get(record, ACTION_SELECTION_FIELD))
            .map((record) => omit(record, [SELECTION_FIELD, ACTION_SELECTION_FIELD]));
        }

        return editGridValue.map((record) => omit(record, ACTION_SELECTION_FIELD));
      }

      return value;
    });
  }

  submission = {
    ...submission,
    data: isFormData ? submission.data : omit(submission.data, NAVIGATION_CODE),
  };

  return submission;
};

export function prepareFileSubmission(
  components: Array<FormComponent>,
  formSubmission: FormSubmission,
): FormSubmission {
  const submission = cloneDeep(formSubmission);

  return convertSubmission(components, submission, (value, component) => {
    if (isFileComponent(component?.type) && value) {
      const fileFullResponse = value as FileFullResponse[];
      return formatFileResponse(fileFullResponse);
    }
    return value;
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const changeComponentKey = (Component: any, newKey: string) => class FormioClass extends Component {
  static schema() {
    return {
      ...Component.schema(),
      type: newKey,
      key: newKey,
    };
  }

  static get builderInfo() {
    return {
      ...Component.builderInfo,
      schema: {
        ...Component.schema(),
        type: newKey,
        key: newKey,
      },
    };
  }
} as typeof Component;
export const addUniqClasses = (...args: ClassValue[]) => {
  const newClasses = clsx(...args);
  return [...Array.from(new Set(newClasses.split(' ')))].join(' ');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addComponentClass = (Component: any, customClass: string) => class FormioClass extends Component {
  constructor(component: Record<string, unknown>, options: Record<string, unknown>, data: unknown) {
    super(component, options, data);
    this.component.customClass = addUniqClasses(
      customClass,
      this.component.customClass,
    );
  }
} as typeof Component;

export function ignoreBuilderFields(keys: Array<string>): Array<{ ignore: boolean, key: string }> {
  return keys.map((key) => ({ key, ignore: true }));
}

export function numberToString(value: unknown) {
  return isNumber(value) ? value.toString() : value;
}

export function modifySelectRowData(
  component: FormComponent,
  root: { form: { components: FormComponent[] }, options: { parentPath?: string } },
  row: Record<string, unknown>,
) {
  // TODO: To be changed after 'Latest' components are removed
  const selectTypes = ['select', 'selectLegacy', 'selectLatest', 'selectPreview'];
  const { conditional } = component;
  const key = conditional?.when;
  let resultRow = { ...row };
  if (!key || !root || !root.form) {
    return resultRow;
  }

  const { form: { components }, options: { parentPath } } = root;

  const selectComponent = components.find((c: FormComponent) => c.key === key) as SelectComponent;

  if (selectComponent && selectTypes.includes(selectComponent.type)) {
    const path = `${key}.${selectComponent.valueProperty || 'value'}`;
    resultRow = { ...resultRow, [key]: get(row, path) };
  }

  if (parentPath) {
    resultRow = { ...resultRow, [parentPath]: resultRow };
  }

  return resultRow;
}

export const editForm = (key: string, components: Record<string, unknown>[]) => ({
  key,
  components,
});

export const parseValueToNumber = (inputValue: string) => {
  if (!inputValue) {
    return null;
  }
  const inputValueReplaced = inputValue.replace(DECIMAL_SEPARATOR, '.');
  return parseFloat(inputValueReplaced.split(THOUSANDS_SEPARATOR).join(''));
};

export const isNavigationButton = (action: string): boolean => action === 'navigation';

export const findComponents = <T = FormComponent>(
  formComponents: FormComponent[],
  cb: (value: T) => boolean,
): T[] | undefined => {
  const fc = (
    components: FormComponent[],
    predicate: (value: T) => boolean,
  ): T[] => {
    return flatMapDeep(
      components,
      (component: FormComponent) => {
        if (predicate(component as unknown as T)) {
          return component;
        }

        if ('columns' in component) {
          return flatMapDeep(
            component.columns,
            (column: Column) => {
              return column.components.map((columnComponent) => {
                if (predicate(columnComponent as unknown as T)) {
                  return columnComponent;
                }

                if ('columns' in columnComponent) {
                  return fc([columnComponent], predicate);
                }

                const nestedColumnComponents = get(columnComponent, 'components', []);
                if (nestedColumnComponents.length) {
                  return fc(nestedColumnComponents, predicate);
                }

                return [];
              });
            },
          );
        }

        if (isTableComponent(component.type)) {
          return flatMapDeep(
            (component as TableComponent).rows,
            (rows) => {
              return rows.map((rowsComponents) => {
                return rowsComponents.components.map((columns) => {
                  if (predicate(columns as unknown as T)) {
                    return columns;
                  }

                  if ('rows' in columns) {
                    return fc([columns], predicate);
                  }

                  const nestedTableComponents = get(columns, 'components', []);
                  if (nestedTableComponents.length) {
                    return fc(nestedTableComponents, predicate);
                  }

                  return [];
                });
              });
            },
          );
        }

        const nestedComponents = get(component, 'components', []);
        if (nestedComponents.length) {
          return fc(nestedComponents, predicate);
        }

        return [];
      },
    ) as unknown as T[];
  };

  const result = fc(formComponents, cb);
  return result.length ? result : undefined;
};

export function filterComponents<T>(
  components: FormComponent[],
  predicate: (value: T) => boolean,
) {
  const cloneComponents = cloneDeep(components);
  return cloneComponents.filter((component) => {
    if (isTableComponent(component.type)) {
      (component as TableComponent).rows.forEach((rows) => {
        rows.forEach((c, key) => {
          rows[key].components = filterComponents(c.components, predicate);
        });
      });
      return true;
    }
    if ('components' in component) {
      component.components = filterComponents(component.components as FormComponent[], predicate);
      return true;
    }

    if ('columns' in component) {
      component.columns.forEach((c, key) => {
        component.columns[key].components = filterComponents(c.components, predicate);
      });
      return true;
    }

    return predicate(component as unknown as T);
  });
}

export function transformTextCase(
  value: string | number | null,
  textCase: 'mixed' | 'uppercase' | 'lowercase',
) {
  if (!value) {
    return value;
  }
  if (!textCase || textCase === 'mixed') {
    return value.toString();
  }
  if (textCase === 'uppercase') {
    return value.toString().toUpperCase();
  }
  return value.toString().toLowerCase();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getComponentPath(component: any, path = ''): string {
  const parentPath = component.options.parentPath || '';
  const key = `${parentPath}${parentPath ? '.' : ''}${component.key}`;

  // eslint-disable-next-line no-underscore-dangle
  if (!component || !component.key || component?._form?.display === 'wizard') {
    return path;
  }
  // eslint-disable-next-line no-param-reassign
  path = component.isInputComponent || component.input === true ? `${key}${path ? '.' : ''}${path}` : path;

  return getComponentPath(component.parent, path);
}

export function checkRefresh(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  this: any,
  refreshData: string,
  changed: Record<string, unknown>,
  flags: Record<string, unknown>,
) {
  // This method is a slight rework of formio's method in Component.js of the same name
  // Made to take options.parentPath into account.
  const changePath = get(changed, 'instance.path', false);
  const refreshableChange = (changePath && getComponentPath(changed.instance) === refreshData)
    && changed && changed.instance
    // Make sure the changed component is not in a different "context". Solves issues where refreshOn being set
    // in fields inside EditGrids could alter their state from other rows (which is bad).
    && this.inContext(changed.instance);

  // Don't let components change themselves.
  if (changePath && this.path === changePath) {
    return;
  }

  if (refreshData === 'data') {
    this.refresh(this.data, changed, flags);
  } else if (refreshableChange) {
    this.refresh(changed.value, changed, flags);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function renderLabelTemplate(this: any) {
  return `
    <span class="col-form-label ${this.labelInfo.className || ''}">
      ${this.t(this.component.label, { _userInput: true })}
      ${this.component.tooltip ? `
        <i
          ref="tooltip"
          class="${this.iconClass('question-sign')} text-muted"
          data-tooltip="${this.component.tooltip}"
        >
        </i>
      ` : ''}
    </span>
  `;
}

export function transformComponents(
  components: FormComponent[],
  transformer: (c: FormComponent) => FormComponent,
): FormComponent[] {
  return cloneDeep(components
    .map((component) => {
      const nestedComponents = get(component, 'components', []) as Array<FormComponent>;

      if (nestedComponents?.length) {
        return {
          ...transformer(component),
          components: transformComponents(nestedComponents, transformer),
        };
      }

      if ('columns' in component) {
        return {
          ...transformer(component),
          columns: component.columns.map((column: Column) => {
            return {
              ...column,
              components: transformComponents(column.components, transformer),
            };
          }),
        };
      }

      if (isTableComponent(component.type)) {
        const tableComponent = component as TableComponent;
        return {
          ...transformer(component),
          rows: tableComponent.rows.map((row) => {
            return row.map((item) => {
              return {
                ...item,
                components: transformComponents(item.components, transformer),
              };
            });
          }),
        };
      }

      return transformer(component);
    })) as Array<FormComponent>;
}

export function convertDateToTimestamp(date: string, format: string) {
  const timestamp = parse(
    date,
    format,
    new Date(),
    {
      locale: uk,
    },
  ).getTime();

  return timestamp;
}

export const getFileName = (file: FileFullResponse) => {
  return get(file, 'originalName', '') || file.name;
};
