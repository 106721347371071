import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Divider from '#web-components/components/Divider';
import Button, { ButtonVariants } from '#web-components/components/Button';
import Typography from '#web-components/components/Typography';

import styles from './TaskSideBar.styles';

const useStyles = makeStyles(styles, { name: 'TaskSideBar' });

type TaskSideBarProps = {
  onClickSave: () => void,
};

export default function TaskSideBar({ onClickSave }: TaskSideBarProps) {
  const classes = useStyles();
  const { t } = useTranslation('taskPage');
  return (
    <>
      <Typography variant="bodyText">{t('common:formMessages.mandatoryFields')}</Typography>
      <Box className={classes.divider}>
        <Divider />
      </Box>
      <Typography variant="bodyText">{t('sideBar.saveData')}</Typography>
      <Button
        size="small"
        variant={ButtonVariants.secondary}
        className={classes.btn}
        onClick={onClickSave}
      >
        {t('sideBar.saveButton')}
      </Button>
    </>
  );
}
