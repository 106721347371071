import { createStyles, Theme } from '@material-ui/core/styles';
import { spacing } from '#web-components/styles/constants';

const styles = ({ colors }: Theme) => createStyles({
  root: {
    overflow: 'hidden',
    paddingTop: spacing + 4,
    paddingBottom: spacing + 4,
    borderBottom: colors.uIBase4,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  name: {
    display: 'inline',
    cursor: 'pointer',
  },
});

export default styles;
