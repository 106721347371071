import { createStyles, Theme } from '@material-ui/core/styles';
import {
  h2, h7, h8, spacing,
} from '#web-components/styles';

const styles = ({ colors, palette }: Theme) => createStyles({
  link: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: palette.text.primary,
    textDecoration: 'none',
    cursor: 'pointer',
    '& svg': {
      fill: colors.uIIconBase,
      opacity: 0.7,
    },

    '&:hover': {
      '& svg': {
        opacity: ({ disabled }:{ disabled: boolean }) => (disabled ? 0.7 : 1),
      },
    },
  },
  mediumPadding: {
    padding: `0 ${spacing}px`,
  },
  iconMargin: {
    marginLeft: spacing,
  },
  text: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  small: {
    ...h8,
  },
  medium: {
    ...h7,
  },
  huge: {
    ...h2,
    marginLeft: `${spacing * 3}px`,
  },
  disabled: {
    color: colors.textMainSubtle,
    cursor: 'default',
  },
});

export default styles;
