import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Typography from '#web-components/components/Typography';
import Button, { ButtonVariants } from '#web-components/components/Button';
import styles from './Error.styles';

export type ErrorProps = {
  message: string,
  onClick: () => void;
  buttonText: string,
};

const useStyles = makeStyles(styles, { name: 'Error' });

export default function Error({ message, buttonText, onClick }: ErrorProps) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant="h8" className={classes.message}>{message}</Typography>
      <Button onClick={onClick} variant={ButtonVariants.secondary}>
        {buttonText}
      </Button>
    </Box>
  );
}
