import { createStyles } from '@material-ui/core/styles';
import { spacing } from '#web-components/styles';

const styles = () => createStyles({
  file: {
    marginBottom: spacing * 2,
  },
  box: {
    marginTop: spacing * 3,
  },
});

export default styles;
