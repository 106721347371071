import common from './common.json';
import processDomain from './domainSpecific/processDomain.json';
import formDomain from './domainSpecific/formDomain.json';
import homePage from './pageSpecific/homePage.json';
import processListPage from './pageSpecific/processListPage.json';
import processInstanceListPage from './pageSpecific/processInstanceListPage.json';
import loginPage from './pageSpecific/loginPage.json';
import userTaskListPage from './pageSpecific/userTaskListPage.json';
import taskPage from './pageSpecific/taskPage.json';
import reportListPage from './pageSpecific/reportListPage.json';
import startFormPage from './pageSpecific/startFormPage.json';
import profilePage from './pageSpecific/profilePage.json';
import confirmChannelPage from './pageSpecific/confirmChannelPage.json';
import notificationListPage from './pageSpecific/notificationListPage.json';

export const UA = {
  common,
  processDomain,
  formDomain,
  homePage,
  loginPage,
  processListPage,
  processInstanceListPage,
  userTaskListPage,
  taskPage,
  reportListPage,
  startFormPage,
  profilePage,
  confirmChannelPage,
  notificationListPage,
};
