import { createStyles, Theme } from '@material-ui/core';
import { spacing } from '#web-components/styles';

import type { EntityGroupComponentProps } from './EntityGroup';

const styles = ({ colors }: Theme) => createStyles({
  cellName: {
    display: 'flex',
    padding: spacing,
  },
  icon: {
    marginRight: `${spacing * 2}px`,
    width: '38px',
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${spacing * 4}px 0`,
    borderBottom: `2px solid ${colors.uIBase}`,
  },
  titleCellClass: (props: EntityGroupComponentProps) => ({
    borderBottom: `1px solid ${colors.uIBase4}`,
    ...(props.onEntitySelect ? {
      cursor: 'pointer',
      '&:hover': {
        background: colors.dropmenuHover,
      },
    } : {}),
  }),
  cellClass: {
    borderBottom: `1px solid ${colors.uIBase4}`,
  },
});

export default styles;
