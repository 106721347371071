import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';
import { createAsyncActionErrorSelector, createAsyncActionIsLoadingSelector } from '../asyncAction';
import { getPendingTasksListRequest, getPendingTasksCountRequest, claimTaskRequest } from './slice';

export const selectPendingTasksState = (state: RootState) => state.pendingTasks;

export const selectPendingTasksCount = createSelector(selectPendingTasksState, (state) => state.count);
export const selectPendingTasksCountIsLoading = createAsyncActionIsLoadingSelector(getPendingTasksCountRequest.type);

export const selectPendingTasksList = createSelector(selectPendingTasksState, (state) => state.list);
export const selectPendingTasksFilterParams = createSelector(
  selectPendingTasksState,
  (state) => state.filterParams,
);
export const selectPendingTasksIsLoading = createAsyncActionIsLoadingSelector(getPendingTasksListRequest.type);
export const selectPendingTasksCountError = createAsyncActionErrorSelector(getPendingTasksCountRequest.type);
export const selectPendingTasksError = createAsyncActionErrorSelector(getPendingTasksListRequest.type);

export const selectClaimTaskIsLoading = createAsyncActionIsLoadingSelector(claimTaskRequest.type);
