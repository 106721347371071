import baseRadioForm from 'formiojs/components/radio/Radio.form';
import { ignoreBuilderFields } from '#web-components/utils';
import {
  defaultConditionalFields,
  defaultDataFields,
  defaultValidationFields,
  defaultApiFields,
  tableSettingsTab,
} from '#web-components/components/Form/constants';

/**
 * This function returns the fields that should be present in the builder
 * You can extend the default one of any component
 * The values you pass are merged with the passed ones using the function unifyComponents (seacrh in formio.js source)
 *
 * *overrideEditForm* param is used when component key is equal to the default one to favor yours
 * *ignore* param will force the skipping of this component
 */
export default () => {
  return baseRadioForm([
    {
      key: 'display',
      components: ignoreBuilderFields([
        'labelWidth',
        'labelMargin',
        'customClass',
        'autofocus',
        'modalEdit',
        'shortcut',
        'hideLabel',
        'tableView',
      ]),
    },
    {
      key: 'data',
      components: [
        ...ignoreBuilderFields([
          'persistent',
          'protected',
          'redrawOn',
          'dbIndex',
          'encrypted',
          'calculateServer',
          'dataType',
        ]),
        {
          type: 'datagrid',
          input: true,
          label: 'Values',
          key: 'values',
          // eslint-disable-next-line max-len
          tooltip: 'The radio button values that can be picked for this field. Values are text submitted with the form data. Labels are text that appears next to the radio buttons on the form.',
          weight: 10,
          reorder: true,
          defaultValue: [{
            label: '',
            value: '',
          }],
          components: ignoreBuilderFields(['shortcut']),
        },
        ...defaultDataFields,
      ],
    },
    {
      key: 'validation',
      components: [
        ...ignoreBuilderFields(['errorLabel', 'validate.onlyAvailableItems']),
        ...defaultValidationFields,
      ],
    },
    {
      key: 'api',
      components: [
        ...ignoreBuilderFields(['tags', 'properties']),
        ...defaultApiFields,
      ],
    },
    {
      key: 'logic',
      ignore: true,
    },
    {
      key: 'layout',
      ignore: true,
    },
    {
      key: 'conditional',
      components: defaultConditionalFields,
    },
    tableSettingsTab,
  ]);
};
