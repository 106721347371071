import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as Icon } from '#web-components/assets/icons/folderArrow.svg';

import styles from './FolderArrowIcon.styles';

type FolderArrowIconProps = {
  className?: string;
};

const useStyles = makeStyles(styles, { name: 'FolderArrowIcon' });

export default function FolderArrowIcon({ className }: FolderArrowIconProps) {
  const classes = useStyles();
  return (
    <span className={clsx(classes.icon, className)}>
      <Icon />
    </span>
  );
}
