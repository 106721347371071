import React from 'react';
import clsx from 'clsx';
import { Box, makeStyles } from '@material-ui/core';
import styles from './ErrorInfoBox.styles';

interface ErrorInfoBoxProps {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}

const useStyles = makeStyles(styles, { name: 'ErrorInfoBox' });

export default function ErrorInfoBox({ className, children }: ErrorInfoBoxProps) {
  const classes = useStyles();
  return (
    <Box className={clsx(className, classes.root)}>
      {children}
    </Box>
  );
}
