import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { RootState } from 'store/rootReducer';
import {
  selectProcessDefinitionsCount,
  selectProcessDefinitionsCountIsLoading,
  getProcessDefinitionsCountRequest,
  selectProcessDefinitionsCountError,
} from 'store/processDefinitions';
import {
  selectProcessInstancesCount,
  selectProcessInstancesCountIsLoading,
  getProcessInstancesCountRequest,
  selectProcessInstancesCountError,
} from 'store/processInstances';
import {
  selectPendingTasksCount,
  selectPendingTasksCountIsLoading,
  getPendingTasksCountRequest,
  selectPendingTasksCountError,
} from 'store/pendingTasks';
import HomePage from './HomePage';

import styles from './HomePage.styles';

export interface PropsFromState {
  processDefinitionsCount: number;
  processInstancesCount: number;
  pendingTasksCount: number;
  isLoading: boolean;
  isProcessInstancesCountError: boolean;
  isProcessDefinitionsCountError: boolean;
  isPendingTasksCountError: boolean;
}

export interface DispatchProps {
  getProcessDefinitionsCount: typeof getProcessDefinitionsCountRequest;
  getProcessInstancesCountRequest: typeof getProcessInstancesCountRequest;
  getPendingTasksCount: typeof getPendingTasksCountRequest;
}

function mapStateToProps(state: RootState): PropsFromState {
  return {
    processDefinitionsCount: selectProcessDefinitionsCount(state),
    processInstancesCount: selectProcessInstancesCount(state),
    pendingTasksCount: selectPendingTasksCount(state),
    isLoading: selectProcessDefinitionsCountIsLoading(state)
    || selectProcessInstancesCountIsLoading(state)
    || selectPendingTasksCountIsLoading(state),
    isProcessInstancesCountError: !!selectProcessInstancesCountError(state),
    isProcessDefinitionsCountError: !!selectProcessDefinitionsCountError(state),
    isPendingTasksCountError: !!selectPendingTasksCountError(state),
  };
}

const mapDispatchToProps: DispatchProps = {
  getProcessDefinitionsCount: getProcessDefinitionsCountRequest,
  getProcessInstancesCountRequest,
  getPendingTasksCount: getPendingTasksCountRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation('common')(
    withStyles(styles)(HomePage),
  ),
);
