import { Theme, createStyles } from '@material-ui/core';
import { spacing } from '#web-components/styles';

const styles = ({ colors }: Theme) => createStyles({
  root: {
    '&, &:link, &:visited, &:hover, &:active': {
      color: 'inherit',
      textDecoration: 'inherit',
      fontWeight: 'inherit',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: spacing * 2.5,

      '& svg': {
        fill: colors.textMainPrimary,
      },
    },
  },
});

export default styles;
