import { createStyles } from '@material-ui/core';
import zIndex from '@material-ui/core/styles/zIndex';
import { shadows } from '#web-components/styles/constants';
import type { PopperProps } from '#web-components/types/popper';

const styles = () => createStyles({
  paper: {
    boxShadow: shadows.primary,
    borderRadius: 2,
  },
  root: {
    zIndex: (props: PopperProps) => props.zIndex || zIndex.tooltip,
  },
});

export default styles;
