import React from 'react';

import { ColumnDefinition } from '#web-components/types/table';
import MdtuTable from '#web-components/components/Table';

interface Props<T> {
  columnDefinitions: ColumnDefinition[];
  list: T[];
  emptyPlaceholder?: string;
  hideEmptyPlaceholder?: boolean;
  hideHeader?: boolean;
}

export default function EntityTable<T extends { id: string }>(props: Props<T>) {
  const {
    columnDefinitions,
    list,
    emptyPlaceholder,
    hideEmptyPlaceholder,
    hideHeader,
  } = props;
  return (
    <MdtuTable
      columnDefinitions={columnDefinitions}
      list={list}
      onOrderChange={() => null}
      emptyPlaceholder={emptyPlaceholder || ''}
      header={{
        hide: hideHeader,
      }}
      pagination={{
        hidePaginationControls: true,
      }}
      showAllRows
      hideEmptyPlaceholder={hideEmptyPlaceholder}
    />
  );
}
