import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as Icon } from '#web-components/assets/icons/login.svg';

import styles from './LoginIcon.styles';

const useStyles = makeStyles(styles, { name: 'LoginIcon' });

export default function LoginIcon() {
  const classes = useStyles();
  return (
    <span className={classes.root}>
      <Icon />
    </span>
  );
}
