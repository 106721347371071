import React, { Component } from 'react';
import { WithTranslation } from 'react-i18next';
import Table from 'components/Table';
import { ProcessInstanceListItem } from '#shared/types/processInstance';
import { ColumnDefinition, FilterParams } from '#web-components/types/table';
import { dateTimeFormatter } from '#web-components/utils';
import { spacing } from '#web-components/styles';
import { OkSignIcon } from '#web-components/components/Icons';

interface Props extends WithTranslation {
  processInstances: Array<ProcessInstanceListItem>;
  getProcessInstances: (filterParams: FilterParams, isInitialRequest?: boolean) => void;
  filterParams: FilterParams,
  isLoadingProcess: boolean
}

class ActiveProcessInstanceTable extends Component<Props> {
  columnDefinitions: ColumnDefinition[] = [
    {
      title: this.props.t('components.processInstanceTable.columns.name'),
      property: 'processDefinitionName',
      width: 512,
      sortable: false,
    },
    {
      title: this.props.t('components.processInstanceTable.columns.businessKey'),
      property: 'businessKey',
      width: 512,
      sortable: false,
    },
    {
      title: this.props.t('components.processInstanceTable.columns.startDateTime'),
      property: 'startTime',
      formatter: dateTimeFormatter,
      width: `${spacing * 26}px`,
    },
    {
      title: this.props.t('components.processInstanceTable.columns.status'),
      property: 'statusTitle',
      width: 400,
      // TODO: Declare this component outside parent component "EndedProcessInstanceTable" or memoize it
      //  If you want to allow component creation in props, set allowAsProps option to true
      // eslint-disable-next-line react/no-unstable-nested-components
      Component: ({ item }) => {
        const { status } = item as ProcessInstanceListItem;
        return (<span>{status.title}</span>);
      },
    },
  ];

  componentDidMount() {
    const { getProcessInstances, filterParams } = this.props;
    getProcessInstances(filterParams, true);
  }

  handleFilterChange = (filterParams: FilterParams) => {
    const { getProcessInstances } = this.props;
    getProcessInstances(filterParams, false);
  };

  render() {
    const {
      processInstances,
      filterParams: {
        page,
        rowsPerPage,
        order,
        orderField,
      },
      isLoadingProcess,
      t,
    } = this.props;

    return (
      <Table
        columnDefinitions={this.columnDefinitions}
        list={processInstances}
        order={order}
        orderField={orderField}
        emptyPlaceholder={t('components.processInstanceTable.emptyPlaceholderActive')}
        emptyPlaceholderIcon={<OkSignIcon />}
        hideEmptyPlaceholder={isLoadingProcess}
        onFilterChange={this.handleFilterChange}
        pagination={{
          page,
          rowsPerPage,
        }}
      />
    );
  }
}

export default ActiveProcessInstanceTable;
