import React, { useCallback } from 'react';
import {
  ClickAwayListener,
  Grow,
  makeStyles,
  Paper,
  Popper as MuiPopper,
} from '@material-ui/core';
import clsx from 'clsx';
import { PopperProps } from '#web-components/types/popper';
import { spacing } from '#web-components/styles';
import styles from './Popper.styles';

export const DEFAULT_TIMEOUT = 300;

const useStyles = makeStyles(styles, { name: 'Popper' });

export default function Popper(props: PopperProps) {
  const {
    anchorEl, children, open, onClose, placement, timeout = DEFAULT_TIMEOUT, fullWidth, disablePortal, className,
  } = props;
  const classes = useStyles(props);

  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  return (
    <MuiPopper
      open={open}
      anchorEl={anchorEl.current}
      placement={placement}
      disablePortal={disablePortal}
      transition
      popperOptions={{
        modifiers: {
          offset: {
            offset: `0, ${spacing / 2}`,
            fn: (data: { instance: { reference: HTMLElement, popper: HTMLElement } }) => {
              if (fullWidth) {
                const { instance: { reference, popper } } = data;
                popper.style.width = `${reference.offsetWidth}px`;
              }

              return data;
            },
          },
        },
      }}
      className={clsx(classes.root, className)}
    >
      <Grow
        in={open}
        {...(open ? { timeout } : {})}
      >
        <Paper className={classes.paper}>
          <ClickAwayListener onClickAway={handleClose}>
            {children}
          </ClickAwayListener>
        </Paper>
      </Grow>
    </MuiPopper>
  );
}
