import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Button, { ButtonVariants } from '#web-components/components/Button';
import styles from './PendingTasksTableAction.styles';

interface PendingTasksTableActionProps {
  taskId: string;
  taskName: string;
  onExecute: (id: string) => void;
  onClaim: (id: string, name: string) => void;
  isDisabled: boolean;
  hasAssignee: boolean;
}

const useStyles = makeStyles(styles, { name: 'PendingTasksTableAction' });

export default function PendingTasksTableAction(props: PendingTasksTableActionProps) {
  const {
    taskId,
    taskName,
    isDisabled,
    hasAssignee,
    onExecute,
    onClaim,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation('userTaskListPage');

  const onClickHandler = useCallback(
    () => {
      return hasAssignee ? onExecute(taskId) : onClaim(taskId, taskName);
    },
    [taskId, taskName, hasAssignee, onClaim, onExecute],
  );
  const variant = hasAssignee ? ButtonVariants.primary : ButtonVariants.secondary;
  const title = hasAssignee
    ? t('components.pendingTasksTable.executeAction')
    : t('components.pendingTasksTable.acceptAction');

  return (
    <Button
      variant={variant}
      onClick={onClickHandler}
      disabled={isDisabled}
      className={classes.button}
      size="small"
    >
      {title}
    </Button>
  );
}
