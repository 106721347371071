import { Components } from 'react-formio';
import baseFileForm from 'formiojs/components/file/File.form';

import { addUniqClasses } from '#web-components/utils';
import {
  withLegacyComponent,
} from '#web-components/components/Form/components/WithLegacyComponent/WithLegacyComponent';

import { ComponentsConfig, FormioComponentName } from '../../types';
import { COMPONENT_CLASSES, defaultApiFields } from '../../constants';

const File = (Components as ComponentsConfig).components.file;

export default class FileLegacy extends withLegacyComponent(File) {
  static schema() {
    return File.schema({
      type: FormioComponentName.fileLegacy,
      key: 'fileLegacy',
    });
  }

  static get builderInfo() {
    return {
      ...File.builderInfo,
      schema: FileLegacy.schema(),
    };
  }

  static editForm = () => baseFileForm([
    {
      key: 'api',
      components: defaultApiFields,
    },
  ]);

  constructor(component: Record<string, unknown>, options: Record<string, unknown>, data: unknown) {
    super(component, options, data);
    this.component.customClass = addUniqClasses(COMPONENT_CLASSES.bootstrapComponent, this.component.customClass);
  }
}
