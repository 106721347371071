import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from '#web-components/components/Typography';
import styles from './Loader.styles';

interface Props {
  className?: string;
  show?: boolean;
  description?: string;
  'data-xpath'?: string;
}

const useStyles = makeStyles(styles, { name: 'Loader' });

export default function Loader({
  className, show = true, description, 'data-xpath': dataXPath,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={clsx(classes.root, className, !show && classes.hide)} data-xpath={dataXPath}>
      <div className={classes.loaderBox}>
        <div className={classes.whiteStroke} />
        <div className={classes.rotatableUnitOverflow}>
          <div className={classes.rotatableUnit} />
        </div>
        <div className={classes.loader}>
          <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill={theme.colors.loaderBackground}
              fillRule="evenodd"
              clipRule="evenodd"
              d={`
             M140 0H0V140H140V0ZM70.0497 5H69.9206C55.9652 5 44.9963 4.99999 36.2479 6.28355C27.4492 7.57448 20.7724
             10.1788 15.4764 15.4767C10.1806 20.7743 7.57632 27.4509 6.28493 36.2492C5 45.0034 5 55.9807 5
             69.9503V70.0794C5 84.0348 4.99999 95.0037 6.28355 103.752C7.57448 112.551 10.1788 119.228 15.4767
             124.524C20.7743 129.819 27.4509 132.424 36.2492 133.715C45.0037 135 55.9815 135 69.9518
             135H70.0794H70.0799C84.035 135 95.0038 135 103.753 133.716C112.552 132.426 119.229 129.821 124.527
             124.523C129.825 119.226 132.428 112.549 133.718 103.751C135 95.0093 135 84.0511 135
             70.1107V70.1106V69.9206V69.9201C135 55.9649 135 44.9962 133.717 36.2479C132.426 27.4493 129.823
             20.7725 124.527 15.4765C119.231 10.1806 112.554 7.5763 103.754 6.28492C94.9985 5 84.0193 5 70.0497
             5ZM16.8909 16.8906C21.7749 12.0048 27.9846 9.51734 36.5382 8.26236C45.1255 7.00244 55.9498 7 70
             7C84.0501 7 94.8753 7.00335 103.464 8.26373C112.018 9.51917 118.229 12.0067 123.113 16.8907C127.997
             21.7748 130.484 27.9845 131.738 36.5382C132.998 45.1255 133 55.9498 133 70C133 84.0503 132.999 94.8736
             131.74 103.461C130.485 112.014 127.999 118.223 123.113 123.109C118.227 127.995 112.016 130.483 103.462
             131.738C94.8745 132.998 84.0502 133 70 133C55.9499 133 45.1265 132.997 36.5396 131.736C27.9864 130.481
             21.7765 127.993 16.8906 123.109C12.0048 118.225 9.51734 112.015 8.26236 103.462C7.00244 94.8745 7 84.0502
             7 70C7 55.9499 7.00335 45.1265 8.26372 36.5396C9.51915 27.9864 12.0067 21.7766 16.8909 16.8906Z`}
            />
            <path
              fill={theme.colors.loaderBody}
              d={`M67.5868 109.247C63.3748 106.049 60.3912 101.253 59.426 95.7467H46V37.2171C53.1956 40.8586 58.3729
            48.852 58.8994 57.1118L61.0055 73.5428L60.0402 73.4539C57.9342 73.4539 56.0037 75.4967 56.0037
            77.5395C56.0037 79.4046 57.4077 81.0033 59.3382 81.4474L61.7952 81.9803C65.8318 74.2533 68.1133 66.7039
            68.1133 59.2434C68.1133 52.5822 67.2358 45.8322 67.0603 38.9934C67.0603 35.9737 68.2011 33.1316 70.0439
            31C71.8867 33.1316 73.0274 35.9737 73.0274 38.9934C73.0274 45.8322 71.9744 52.4934 71.9744 59.2434C71.9744
            66.6151 74.2559 74.2533 78.2925 81.9803L80.7495 81.4474C82.5923 81.0033 84.0841 79.4046 84.0841
            77.5395C84.0841 75.4967 82.2413 73.4539 80.0475 73.4539L78.9945 73.6316L81.0128 57.2007C82.0658 48.852
            86.8044 40.8586 94 37.2171V95.7467H80.6618C79.6088 101.253 76.8007 106.227 72.5009 109.247C71.4479
            109.957 70.6581 110.845 70.0439 112C69.4296 110.845 68.5521 109.957 67.5868 109.247ZM52.4058
            75.4967C52.9324 73.3651 54.5119 71.4112 56.6179 70.2566L55.0384 56.7566C54.5119 52.4934 52.7569
            48.7632 50.1243 45.6546V75.4967H52.4058ZM59.1627 91.6612C59.1627 89.6184 59.426 87.7533 59.8647
            85.7993L58.0219 85.3553C55.3016 84.4671 53.1079 82.3355 52.4058
            79.4934H50.1243V91.5724H59.1627V91.6612ZM68.0256 91.6612C68.0256 89.1743 66.1828
            87.0428 63.7258 86.6875C63.287 88.2862 63.0238 89.8849 63.0238 91.6612H68.0256ZM68.0256
            95.7467H63.5503C64.2523 98.9441 65.8318 101.875 68.0256 104.362V95.7467ZM74.3437 83.0461C72.5887 79.8487
            71.0091 76.4737 70.0439  73.0099C68.9909 76.4737 67.4113 79.8487 65.7441 83.0461C67.4991 83.4901 68.9031
            84.6447 70.0439 85.977C71.1846 84.6447 72.5887 83.4901 74.3437 83.0461ZM77.064 91.6612C77.064 89.8849
            76.8007 88.2862 76.362 86.6875C73.9049 86.9539 72.0622 89.1743 72.0622 91.6612H77.064ZM76.5375
            95.7467H72.0622V104.362C74.2559 101.786 75.8355 98.9441 76.5375 95.7467ZM90.0512
            91.6612V79.4934H87.7697C87.0676 82.2467 84.8739 84.4671 82.1536 85.3553L80.3108
            85.7993C80.7495 87.6645 81.0128 89.5296 81.0128 91.6612H90.0512V91.6612ZM90.0512 75.4967V45.6546C87.4186
            48.6743 85.5759 52.4934 85.1371 56.7566L83.5576 70.2566C85.5759 71.4112 87.1554 73.2763 87.7697
            75.4967H90.0512Z`}
            />
          </svg>
        </div>
      </div>
      {description && (
        <Typography variant="h7" className={classes.description}>
          {description}
        </Typography>
      )}
    </div>
  );
}
