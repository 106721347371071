import { createStyles } from '@material-ui/core';

const styles = () => createStyles({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export default styles;
