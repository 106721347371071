import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const styles = ({ colors }: Theme) => createStyles({
  root: {
    '& svg': {
      fill: colors.uIGreenBase,
      verticalAlign: 'middle',
      lineHeight: 'inherit',
    },
  },
});

export default styles;
