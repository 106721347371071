import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import { ROUTES } from 'constants/routes';
import { X_PATH } from 'constants/xPath';
import useAuthentication from 'hooks/useAuthentication';
import DropdownMenu from '#web-components/components/DropdownMenu';
import MenuItem from '#web-components/components/MenuList/components/MenuItem';
import Typography from '#web-components/components/Typography';
import styles from './Menu.styles';

const useStyles = makeStyles(styles, { name: 'Menu' });

export default function Menu() {
  const authentication = useAuthentication();
  const history = useHistory();
  const userInfo = authentication.info;
  const { t } = useTranslation('common');
  const classes = useStyles();

  const handleLogout = useCallback((): void => {
    authentication.logout();
  }, [authentication]);

  const handleNavigateTo = useCallback((path: string) => () => {
    history.push(path);
  }, [history]);

  if (!(authentication.authenticated)) {
    return null;
  }

  return (
    <DropdownMenu
      triggerElement={(
        <Typography variant="h8">
          {userInfo?.given_name} {userInfo?.family_name && `${userInfo?.family_name.charAt(0)}.`}
        </Typography>
      )}
      xpath={X_PATH.headerUserInfo}
    >
      <div>
        {userInfo?.name && (
          <MenuItem>
            {userInfo.name}
          </MenuItem>
        )}
        <MenuItem onClick={handleNavigateTo(ROUTES.PROFILE)}>
          {t('components.header.profile')}
        </MenuItem>
        <hr className={classes.line} />
        <MenuItem
          data-xpath={X_PATH.logout}
          onClick={handleLogout}
        >
          {t('components.header.logout')}
        </MenuItem>
      </div>
    </DropdownMenu>
  );
}
