import { SETTINGS_API_APPENDIX } from 'constants/baseUrl';
import { ChannelDto, ChannelType } from 'types/settings';
import { settingsApi } from '.';

export const getSettings = () => {
  return settingsApi.get$(`${SETTINGS_API_APPENDIX}`);
};

export const activateChannel = (channel: ChannelType, payload: { address?: string, verificationCode?: string }) => {
  return settingsApi.post$(`${SETTINGS_API_APPENDIX}/channels/${channel}/activate`, payload);
};

export const deactivateChannel = (channel: ChannelType) => {
  return settingsApi.post$(`${SETTINGS_API_APPENDIX}/channels/${channel}/deactivate`, {
    deactivationReason: 'USER_DEACTIVATED',
  });
};

export const validateChannel = (channel: ChannelType, payload: ChannelDto) => {
  return settingsApi.post$(`${SETTINGS_API_APPENDIX}/channels/${channel}/validate`, payload);
};

export const verifyChannel = (channel: ChannelType, payload: ChannelDto) => {
  return settingsApi.post$(`${SETTINGS_API_APPENDIX}/channels/${channel}/verify`, payload);
};
