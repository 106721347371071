import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as Icon } from '#web-components/assets/icons/popupIcon.svg';

import styles from './ChevronDownIcon.styles';

const useStyles = makeStyles(styles, { name: 'ChevronDownIcon' });

export default function ChevronDownIcon({ className }: { className?: string }) {
  const classes = useStyles();
  return (
    <span className={clsx(classes.icon, className)}>
      <Icon />
    </span>
  );
}
