import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterNavLink } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { CitizenIcon, ArrowLeftIcon } from '#web-components/components/Icons';
import Typography from '#web-components/components/Typography';
import InlineButton from '#web-components/components/InlineButton';

import styles from './NotificationsEmptyPlaceholder.styles';

const useStyles = makeStyles(styles, { name: 'NotificationsEmptyPlaceholder' });

const emptyPlaceholderLinks = [
  {
    title: 'common:appSections.processDefinition.title',
    path: ROUTES.PROCESS_LIST,
  },
  {
    title: 'common:components.header.profile',
    path: ROUTES.PROFILE,
  },
];

export default function NotificationsEmptyPlaceholder() {
  const classes = useStyles();
  const { t } = useTranslation('notificationListPage');

  return (
    <div className={classes.root}>
      <CitizenIcon size={56} />
      <Typography variant="bodyText" className={classes.title}>{t('emptyPlaceholder.title')}
      </Typography>
      <br />
      <Typography variant="bodyText">{t('emptyPlaceholder.message')}</Typography>
      {emptyPlaceholderLinks.map(({ title, path }) => (
        <InlineButton
          key={path}
          size="medium"
          leftIcon={<ArrowLeftIcon />}
          component={RouterNavLink}
          to={path}
          classes={{ link: classes.link }}
        >
          {t(title)}
        </InlineButton>
      ))}
    </div>
  );
}
