export const TAB_QUERY_PARAM = 'tab';

export enum ProcessInstanceListTabs {
  active = 'active',
  ended = 'ended',
}

export enum UserTaskListTabs {
  active = 'active',
  closed = 'closed',
}
