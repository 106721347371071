import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { debounce } from 'lodash';
import { InputAdornment, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Input from '#web-components/components/FormControls/Input';
import { SearchIcon, CloseIcon } from '#web-components/components/Icons';

import IconButton from '#web-components/components/IconButton';
import styles from './SearchInput.styles';

const SEARCH_DEBOUNCE_TIMEOUT_MS = 500;

const useStyles = makeStyles(styles, { name: 'SearchInput' });

interface SearchInputProps {
  onSearch: (str: string) => void,
  label?: string,
  placeholder: string,
  disableSearchIcon?: boolean,
  clearIcon?: boolean,
  className?: string,
}

export default function SearchInput({
  label, placeholder, onSearch, disableSearchIcon, clearIcon, className,
}: SearchInputProps) {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const handleSearchChange = useCallback((str) => {
    setSearch(String(str ?? ''));
  }, []);

  const debouncedOnSearch = useMemo(() => {
    return debounce((value) => {
      onSearch(value);
    }, SEARCH_DEBOUNCE_TIMEOUT_MS);
  }, [onSearch]);

  const handleClear = useCallback(() => {
    setSearch('');
  }, []);
  useEffect(() => {
    debouncedOnSearch(search);
  }, [debouncedOnSearch, search]);

  return (
    <Input
      className={clsx(classes.search, className)}
      isLabelShrink
      label={label}
      placeholder={placeholder}
      name="search"
      onChange={handleSearchChange}
      value={search}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            { clearIcon && search.length > 0 && <IconButton onClick={handleClear}><CloseIcon /></IconButton> }
            { !disableSearchIcon && <SearchIcon />}
          </InputAdornment>
        ),
      }}
    />
  );
}
