import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { UA } from './locales/UA';

const resources = {
  uk: UA,
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'uk',
    defaultNS: 'common',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    fallbackNS: 'form',
  });

export default i18n;

document.documentElement.setAttribute('lang', i18n.language);
