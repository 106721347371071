import { createStyles, Theme } from '@material-ui/core/styles';
import { spacing } from '#web-components/styles';

const styles = ({ colors }: Theme) => createStyles({
  line: {
    margin: `${spacing * 2}px ${spacing}px`,
    height: 2,
    backgroundColor: colors.uIBase4,
    border: 'none',
  },
});

export default styles;
