import { WithStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import { WithTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import StandardLayout from 'components/Layouts/Standard';
import { ROUTES } from 'constants/routes';
import { X_PATH } from 'constants/xPath';
import MenuPanel from '#web-components/components/MenuPanel';

import type { PropsFromState, DispatchProps } from './index';

import styles from './HomePage.styles';

interface Props extends PropsFromState, DispatchProps, WithTranslation, WithStyles<typeof styles> {}

class HomePage extends Component<Props> {
  componentDidMount() {
    const { getProcessDefinitionsCount, getProcessInstancesCountRequest, getPendingTasksCount } = this.props;
    getProcessDefinitionsCount();
    getProcessInstancesCountRequest();
    getPendingTasksCount();
  }

  render() {
    const {
      processDefinitionsCount,
      processInstancesCount,
      pendingTasksCount,
      t,
      classes,
      isLoading,
      isProcessInstancesCountError,
      isProcessDefinitionsCountError,
      isPendingTasksCountError,
    } = this.props;

    return (
      <StandardLayout title={t('homePage:title')} isLoading={isLoading}>
        <Grid container spacing={4} className={classes.root}>
          <Grid item xs={4} className={classes.item}>
            <MenuPanel
              title={t('appSections.processDefinition.title')}
              titleLink={ROUTES.PROCESS_LIST}
              countDescription={`${t('appSections.processDefinition.description')}:`}
              count={processDefinitionsCount}
              hasError={isProcessDefinitionsCountError}
              unknownErrorText={t('errors.unknown')}
              data-xpath={X_PATH.processDefinitionOption}
              linkComponent={RouterLink}
            />
          </Grid>
          <Grid item xs={4}>
            <MenuPanel
              title={t('appSections.processActive.title')}
              titleLink={ROUTES.PROCESS_INSTANCE_LIST}
              countDescription={`${t('appSections.processActive.description')}:`}
              count={processInstancesCount}
              hasError={isProcessInstancesCountError}
              unknownErrorText={t('errors.unknown')}
              data-xpath={X_PATH.processActiveOption}
              linkComponent={RouterLink}
            />
          </Grid>
          <Grid item xs={4}>
            <MenuPanel
              title={t('appSections.task.title')}
              titleLink={ROUTES.USER_TASK_LIST}
              countDescription={`${t('appSections.task.description')}:`}
              count={pendingTasksCount}
              hasError={isPendingTasksCountError}
              unknownErrorText={t('errors.unknown')}
              data-xpath={X_PATH.taskOption}
              linkComponent={RouterLink}
            />
          </Grid>
          <Grid item xs={4}>
            <MenuPanel
              title={t('appSections.report.title')}
              titleLink={ROUTES.REPORTS_LIST}
              countDescription={`${t('appSections.report.description')}`}
              data-xpath={X_PATH.reportOption}
              linkComponent={RouterLink}
            />
          </Grid>
        </Grid>
      </StandardLayout>

    );
  }
}

export default HomePage;
