import { createStyles } from '@material-ui/core/styles';
import { spacing } from '#web-components/styles';

const styles = () => createStyles({
  dialogTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleText: {
    marginTop: `${spacing * 4}px`,
  },
});

export default styles;
