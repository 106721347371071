import React from 'react';
import IMaskInput from 'react-input-mask';
import { DEFAULT_INPUT_MASK_PLACEHOLDER_CHAR, MASK_REGEXP_CHARS } from '#web-components/components/Form/constants';
import { isArray } from 'lodash';

interface InputMaskProps {
  inputMask: string
  inputMaskPlaceholderChar?: string
  onChange: (event: { target: { value: string | number } }) => void;
  placeholder?: string;
  forceShowPlaceholder?: boolean;
}

const InputMask = (props: InputMaskProps) => {
  const {
    onChange, inputMask, inputMaskPlaceholderChar, placeholder: propsPlaceholder, forceShowPlaceholder, ...other
  } = props;

  const placeholder = isArray(inputMask) ? inputMask.join('').replace(
    MASK_REGEXP_CHARS,
    inputMaskPlaceholderChar || DEFAULT_INPUT_MASK_PLACEHOLDER_CHAR,
  ) : inputMask.replace(
    MASK_REGEXP_CHARS,
    inputMaskPlaceholderChar || DEFAULT_INPUT_MASK_PLACEHOLDER_CHAR,
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === placeholder) {
      onChange({ target: { value: '' } });
      return;
    }

    onChange({ target: { value } });
  };

  return (
    <IMaskInput
      {...other}
      mask={inputMask}
      placeholder={forceShowPlaceholder ? propsPlaceholder : placeholder}
      maskChar={inputMaskPlaceholderChar}
      onChange={handleChange}
    />
  );
};

export default InputMask;
