import React, {
  useMemo, useState, useCallback, FunctionComponent, useEffect,
} from 'react';
import { makeStyles, Box } from '@material-ui/core';
import get from 'lodash/get';

import { ColumnDefinition, ListItem } from '#web-components/types/table';
import FolderIcon from '#web-components/components/Icons/FolderIcon/FolderIcon';
import FileIcon from '#web-components/components/Icons/FileIcon/FileIcon';
import { textFormatter } from '#web-components/components/Table/utils/formatters';
import Typography from '#web-components/components/Typography';
import { EntityGroup, EntityItem } from '#web-components/types/groupedEntity';

import EntityGroupComponent from './components/EntityGroup';
import Table from './components/EntityTable';
import styles from './GroupedEntityList.styles';

const useStyles = makeStyles(styles, { name: 'GroupedEntityList' });

export interface GroupedEntityListProps {
  groupList: Array<EntityGroup>,
  ungroupedList: Array<EntityItem>,
  onGroupSelect?: (item: EntityGroup | null) => void,
  onEntitySelect?: (item: ListItem) => void,
  readonly?: boolean,
  SelectedGroupActions?: FunctionComponent<{ item: EntityGroup, unselectGroup: () => void }>,
  EntityTableActions?: FunctionComponent<{
    item: ListItem,
    list: Array<EntityItem>,
    groupName?: string,
    existingGroupNames: string[],
    moveToUngroupedEnable?: boolean,
  }>,
  EntityGroupTableActions?: FunctionComponent<{ item: EntityGroup, list: Array<EntityGroup> }>,
  localization:{
    groupsTitle: string,
    ungroupsTitle: string,
    emptyGroupsPlaceholder: string,
    emptyCurrentGroupPlaceholder: string,
  },
  'data-xpath'?: {
    backToGroups?: string,
  },
  existingGroupNames?: string[],
}

export default function GroupedEntityList(props: GroupedEntityListProps) {
  const {
    groupList,
    ungroupedList,
    onGroupSelect,
    onEntitySelect,
    localization,
    readonly,
    existingGroupNames,
    SelectedGroupActions,
    EntityTableActions,
    EntityGroupTableActions,
    'data-xpath': dataXpath,
  } = props;
  const classes = useStyles(props);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState<number | null>(null);

  const normalizedGroupsList = useMemo(() => {
    return (groupList.map((item) => ({ ...item, id: get(item, 'id', item.name) })) || []);
  }, [groupList]);

  const selectGroup = useCallback((index: number) => {
    setSelectedGroupIndex(index);
    if (onGroupSelect) {
      onGroupSelect(normalizedGroupsList[index]);
    }
  }, [normalizedGroupsList, onGroupSelect]);

  const backToGroups = useCallback(() => {
    setSelectedGroupIndex(null);
    if (onGroupSelect) {
      onGroupSelect(null);
    }
  }, [onGroupSelect]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [selectedGroupIndex]);

  const groupsColumnDefinitions: ColumnDefinition[] = [
    {
      title: localization.groupsTitle,
      property: 'name',
      sortable: false,
      width: 100,
      shouldMemoize: true,
      cellClass: classes.cellClass,
      headerCellClass: classes.cellClass,
      // TODO: Declare this component outside parent component or memoize it.
      //  If you want to allow component creation in props, set allowAsProps option to true
      // eslint-disable-next-line react/no-unstable-nested-components
      Component({ item, columnDefinition: { property } }) {
        return (
          <Box
            className={classes.groupCellName}
            onClick={() => selectGroup(normalizedGroupsList.findIndex((group) => group.name === item.id))}
          >
            <FolderIcon size={32} className={classes.icon} />
            <Typography variant="h3">{textFormatter(item, property)}</Typography>
          </Box>
        );
      },
    },
    {
      title: '',
      property: '',
      sortable: false,
      width: '140px',
      cellClass: classes.cellClass,
      headerCellClass: classes.cellClass,
      // TODO: Declare this component outside parent component or memoize it.
      //  If you want to allow component creation in props, set allowAsProps option to true
      // eslint-disable-next-line react/no-unstable-nested-components
      Component({ item }) {
        if (EntityGroupTableActions && !readonly) {
          return (
            <EntityGroupTableActions item={item as EntityGroup} list={normalizedGroupsList} />
          );
        }
        return null;
      },
    },
  ];

  const ungroupsColumnDefinitions: ColumnDefinition[] = [
    {
      title: localization.ungroupsTitle,
      property: 'name',
      sortable: false,
      cellClass: classes.titleCellClass,
      shouldMemoize: true,
      headerCellClass: classes.cellClass,
      // TODO: Declare this component outside parent component or memoize it.
      //  If you want to allow component creation in props, set allowAsProps option to true
      // eslint-disable-next-line react/no-unstable-nested-components
      Component({ item, columnDefinition: { property } }) {
        return (
          <Box
            className={classes.cellName}
            onClick={() => onEntitySelect && onEntitySelect(item)}
          >
            <FileIcon size={24} className={classes.icon} />
            <Typography variant="h5">{textFormatter(item, property)}</Typography>
          </Box>
        );
      },
    },
    {
      title: '',
      property: '',
      sortable: false,
      cellClass: classes.cellClass,
      headerCellClass: classes.cellClass,
      // TODO: Declare this component outside parent component or memoize it.
      //  If you want to allow component creation in props, set allowAsProps option to true
      // eslint-disable-next-line react/no-unstable-nested-components
      Component({ item }) {
        if (EntityTableActions) {
          return (
            <EntityTableActions
              item={item}
              list={ungroupedList}
              existingGroupNames={existingGroupNames || []}
            />
          );
        }
        return null;
      },
    },
  ];

  if (selectedGroupIndex !== null) {
    return (
      <EntityGroupComponent
        backToGroups={backToGroups}
        group={normalizedGroupsList[selectedGroupIndex]}
        onEntitySelect={onEntitySelect}
        readonly={readonly}
        existingGroupNames={existingGroupNames}
        GroupActions={SelectedGroupActions}
        EntityTableActions={EntityTableActions}
        emptyPlaceholder={localization.emptyCurrentGroupPlaceholder}
        data-xpath={{ backToGroups: dataXpath?.backToGroups }}
      />
    );
  }

  return (
    <>
      <Table
        columnDefinitions={readonly ? [groupsColumnDefinitions[0]] : groupsColumnDefinitions}
        list={normalizedGroupsList}
        emptyPlaceholder={localization.emptyGroupsPlaceholder}
        hideEmptyPlaceholder={!!ungroupedList?.length}
        hideHeader={!normalizedGroupsList.length}
      />
      {ungroupedList?.length ? (
        <Table
          columnDefinitions={readonly ? [ungroupsColumnDefinitions[0]] : ungroupsColumnDefinitions}
          list={ungroupedList}
          hideEmptyPlaceholder
        />
      ) : null}
    </>
  );
}
