export type Settings = {
  settingsId: string,
  channels: Channel[]
};

export type Channel = {
  channel: ChannelType,
  activated: boolean,
  address?: string,
  deactivationReason?: string,
  verificationCode?: string,
};

export enum ChannelType {
  EMAIL = 'email',
}

export type ChannelDto = Pick<Channel, 'address'>;
