import { createStyles, Theme } from '@material-ui/core';
import {
  tinyText,
  spacing,
} from '#web-components/styles';

const styles = (theme: Theme) => createStyles({
  textError: {
    ...tinyText,
    color: theme.colors.textErrors,
    marginLeft: 5,
  },
  errorBox: {
    display: 'flex',
    marginTop: spacing,
    alignItems: 'center',
    '& svg': {
      fill: theme.colors.uIRedBase,
      display: 'block',
      width: spacing * 2,
      height: spacing * 2,
    },
  },
});

export default styles;
