import { createStyles } from '@material-ui/core';
import { spacing } from '#web-components/styles';

const styles = () => createStyles({
  root: {
    marginTop: -(spacing * 2),
  },
  item: {
    marginBottom: spacing * 2,
  },
});

export default styles;
