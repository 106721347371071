import React, { Component } from 'react';
import { WithTranslation } from 'react-i18next';
import Table from 'components/Table';
import { UserTaskHistory } from '#shared/types/task';
import { ColumnDefinition, FilterParams } from '#web-components/types/table';
import { dateTimeFormatter } from '#web-components/utils';
import { spacing } from '#web-components/styles';
import FolderIcon from '#web-components/components/Icons/FolderIcon';

interface CompletedTasksTableProps extends WithTranslation {
  tasksList: UserTaskHistory[],
  getCompetedTasksList: (filterParams: FilterParams, isInitialRequest?: boolean) => void,
  filterParams: FilterParams,
  isLoadingTasks: boolean,
}

export default class CompletedTasksTable extends Component<CompletedTasksTableProps> {
  columnDefinitions: ColumnDefinition[] = [
    {
      title: this.props.t('components.completedTasksTable.columns.processDefinitionName'),
      property: 'processDefinitionName',
      width: 408,
      sortable: false,
    },
    {
      title: this.props.t('components.completedTasksTable.columns.businessKey'),
      property: 'businessKey',
      width: 408,
      sortable: false,
    },
    {
      title: this.props.t('components.completedTasksTable.columns.name'),
      property: 'taskDefinitionName',
      width: 352,
      sortable: false,
    },
    {
      title: this.props.t('components.completedTasksTable.columns.dateCreated'),
      property: 'startTime',
      formatter: dateTimeFormatter,
      width: `${spacing * 26}px`,
    },
    {
      title: this.props.t('components.completedTasksTable.columns.dateCompleted'),
      property: 'endTime',
      formatter: dateTimeFormatter,
      width: `${spacing * 24}px`,
    },
  ];

  componentDidMount() {
    const { getCompetedTasksList, filterParams } = this.props;
    getCompetedTasksList(filterParams, true);
  }

  handleFilterChange = (filterParams: FilterParams) => {
    const { getCompetedTasksList } = this.props;
    getCompetedTasksList(filterParams, false);
  };

  render() {
    const {
      tasksList,
      t,
      isLoadingTasks,
      filterParams: {
        page,
        rowsPerPage,
        order,
        orderField,
      },
    } = this.props;
    return (
      <Table
        columnDefinitions={this.columnDefinitions}
        onFilterChange={this.handleFilterChange}
        list={tasksList}
        order={order}
        orderField={orderField}
        pagination={{
          page,
          rowsPerPage,
        }}
        emptyPlaceholderIcon={<FolderIcon size={28} />}
        emptyPlaceholder={t('components.completedTasksTable.emptyPlaceholder')}
        hideEmptyPlaceholder={isLoadingTasks}
        dataXpathTableRowPropName="processInstanceId"
      />
    );
  }
}
