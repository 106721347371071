import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(6),
  },
  sidebarTitle: {
    marginBottom: theme.spacing(4),
  },
  timeoutMessage: {
    marginTop: theme.spacing(2),
    opacity: 0.6,
  },
  refreshButton: {
    marginTop: theme.spacing(2),
  },
  refreshButtonText: {
    marginLeft: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    marginTop: theme.spacing(10),
    '& > *': {
      marginRight: theme.spacing(3),
    },
  },
});

export default styles;
