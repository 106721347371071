import { ProcessDefinition } from '#shared/types/processDefinition';

export const processDefinitionFormatter = (processDefinitionList: Array<ProcessDefinition>) => {
  return processDefinitionList.map((processDefinition, index) => {
    const name = processDefinition.name ? processDefinition.name : `Process definition ${index + 1}`;
    return {
      ...processDefinition,
      name,
    };
  });
};
