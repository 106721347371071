import { createStyles, Theme } from '@material-ui/core';
import { spacing } from '#web-components/styles';

const styles = ({ colors }: Theme) => createStyles({
  infoItem: {
    marginTop: spacing * 2,
  },
  emailInfo: {
    marginTop: spacing * 4,
  },
  name: {
    marginBottom: spacing * 3,
    textTransform: 'uppercase',
  },
  settingsTitle: {
    marginTop: spacing * 8,
    marginBottom: spacing * 4,
    display: 'flex',
    alignItems: 'center',
  },
  emailMessage: {
    opacity: 0.25,
  },
  profileBox: {
    marginTop: spacing * 6,
  },
  link: {
    color: colors.uIBase,
    marginLeft: spacing / 2,
  },
});

export default styles;
