import { ActionsObservable, StateObservable } from 'redux-observable';
import { Action } from '@reduxjs/toolkit';
import { EMPTY, of } from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import { push } from 'connected-react-router';
import * as processDefinitionApi from 'api/processDefinition';
import * as processInstanceApi from 'api/processInstance';
import { ROUTES } from 'constants/routes';
import { getProcessInstancesError } from 'store/processInstances';
import { getPendingTaskError, getPendingTaskRequest, getPendingTaskSuccess } from 'store/userTask';
import {
  getProcessDefinitionsError,
  startProcessError,
  startProcessRequest,
  startProcessSuccess,
} from 'store/processDefinitions';
import { getCriticalErrorProps, catchError } from '#shared/utils/apiHelpers';
import { RootState } from 'store/rootReducer';
import { selectCurrentUserInfo } from 'store/currentUser';
import { isUnregisteredRole } from 'utils/user';
import { isErrorAction } from 'store/asyncAction/utils';
import { ErrorInfo } from '#shared/types/common';
import {
  onboardingProcessRequest,
  onboardingProcessRequestSuccess,
  onboardingProcessRequestError,
} from './slice';
import { ONBOARDING_PROCESS_FILTER_PARAMS } from '../../constants/tableFiltering';

function getResultObservableAndRoute(route: string) {
  return of(push(route), onboardingProcessRequestSuccess());
}

export const onboardingProcessEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$
    .pipe(
      filter(onboardingProcessRequest.match),
      mergeMap(() => {
        return processDefinitionApi.getProcessListDefinitions().pipe(
          mergeMap((processDefinitionList) => {
            const resProcessDefinitionList = processDefinitionList.response;

            if (resProcessDefinitionList.length === 1) {
              return processInstanceApi.getProcessInstancesList(ONBOARDING_PROCESS_FILTER_PARAMS).pipe(
                mergeMap((processInstanceList) => {
                  const resProcessInstanceList = processInstanceList.response;
                  const processDefinitionKey = resProcessDefinitionList[0].key;
                  const processDefinitionFormKey = resProcessDefinitionList[0].formKey;

                  if (resProcessInstanceList.length === 1) {
                    const { processInstanceId } = resProcessInstanceList[0];

                    return of(getPendingTaskRequest({ processInstanceId }));
                  }

                  if (resProcessInstanceList.length === 0) {
                    if (processDefinitionFormKey) {
                      return getResultObservableAndRoute(
                        ROUTES.PROCESS_START_FORM.replace(':processDefinitionKey', processDefinitionKey),
                      );
                    }
                    return of(startProcessRequest({ processDefinitionKey, goToFirstTask: true }));
                  }

                  return getResultObservableAndRoute(ROUTES.USER_TASK_LIST);
                }),
                catchError((serverResponse) => of(
                  getProcessInstancesError(getCriticalErrorProps({ serverResponse })),
                  onboardingProcessRequestError(getCriticalErrorProps({ serverResponse })),
                )),
              );
            }
            return getResultObservableAndRoute(ROUTES.PROCESS_LIST);
          }),
          catchError((serverResponse) => of(
            getProcessDefinitionsError(getCriticalErrorProps({ serverResponse })),
            onboardingProcessRequestError(getCriticalErrorProps({ serverResponse })),
          )),
        );
      }),
    );
};

export const additionalOnboardingProcessEpic = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<RootState>,
) => {
  return action$
    .pipe(
      filter(({ type }) => {
        return [
          getPendingTaskSuccess.type,
          startProcessSuccess.type,
          getPendingTaskError.type,
          startProcessError.type].includes(type);
      }),
      mergeMap((action) => {
        const userInfo = selectCurrentUserInfo(state$.value);
        if (userInfo?.roles && isUnregisteredRole(userInfo?.roles)) {
          if (isErrorAction(action)) {
            return of(onboardingProcessRequestError(action.payload as ErrorInfo));
          }
          return of(onboardingProcessRequestSuccess());
        }
        return EMPTY;
      }),
    );
};
