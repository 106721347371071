import React, { useMemo } from 'react';
import clsx from 'clsx';
import TableCell from '@material-ui/core/TableCell';

import { CellComponentProps } from '#web-components/types/table';
import TextCell from '#web-components/components/Table/Cells/TextCell';

type Props = Pick<CellComponentProps, 'item' | 'columnDefinition'>
& { classes?: { cell?: string, cellLarge?: string } };

const MemorizedCell = (props: Props) => {
  const { classes, item, columnDefinition } = props;
  const {
    property,
    height,
    Component = TextCell,
    cellClass,
  } = columnDefinition;

  const memorizeComponent = useMemo(
    () => <Component item={item} columnDefinition={columnDefinition} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item.id, property],
  );

  return (
    <TableCell
      id={property}
      key={item.id + property}
      classes={{
        root: clsx(classes?.cell, cellClass, height === 'large' && classes?.cellLarge),
      }}
    >
      {memorizeComponent}
    </TableCell>
  );
};

export default MemorizedCell;
