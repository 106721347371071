import { createStyles } from '@material-ui/core';

import { spacing } from '#web-components/styles';

const PADDING_SIZE = spacing * 8;

const styles = () => createStyles({
  tabsRoot: {
    marginBottom: spacing * 8,
    marginLeft: `-${PADDING_SIZE}px`,
    marginRight: `-${PADDING_SIZE}px`,
    paddingLeft: `${PADDING_SIZE}px`,
    paddingRight: `${PADDING_SIZE}px`,
    marginTop: -(spacing * 12.75),
  },
});

export default styles;
