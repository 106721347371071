import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as Icon } from '#web-components/assets/icons/revert.svg';

import styles from './RevertIcon.styles';

type SyncIconProps = {
  className?: string;
};

const useStyles = makeStyles(styles, { name: 'RevertIcon' });

export default function RevertIcon({ className }: SyncIconProps) {
  const classes = useStyles();
  return (
    <span className={clsx(classes.root, className)}>
      <Icon />
    </span>
  );
}
