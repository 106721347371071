import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@material-ui/core/Tooltip';
import Typography from '#web-components/components/Typography';
import styles from './Tooltip.styles';

export type TooltipProps = Pick<MuiTooltipProps, 'title' | 'children' | 'placement' | 'enterDelay'>;

const useStyles = makeStyles(styles, { name: 'Tooltip' });

export default function Tooltip({
  children,
  title,
  enterDelay,
  placement = 'bottom-start',
}: TooltipProps) {
  const classes = useStyles();

  return (
    <MuiTooltip
      enterDelay={enterDelay}
      placement={placement}
      classes={{
        tooltip: classes.tooltip,
      }}
      title={(
        <Typography variant="smallText">{title}</Typography>
      )}
    >
      <Box component="span">{children}</Box>
    </MuiTooltip>
  );
}
