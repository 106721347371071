import React, {
  useCallback, useRef, useState,
} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import MenuList from '#web-components/components/MenuList';
import Popper from '#web-components/components/Popper';
import Button, { ButtonVariants } from '#web-components/components/Button';
import ArrowDropDownIcon from '#web-components/components/Icons/ArrowDropDownIcon';
import styles from './DropdownMenu.styles';

export interface DropdownMenuProps {
  children: React.ReactNode | Element[],
  triggerElement: React.ReactNode;
  xpath?: string;
}

const useStyles = makeStyles(styles, { name: 'DropdownMenu' });

export default function DropdownMenu({
  children,
  triggerElement,
  xpath,
}: DropdownMenuProps): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const handleClick = useCallback(() => {
    setOpen((prevStateOfOpen) => !prevStateOfOpen);
  }, []);

  return (
    <div className={classes.root} data-xpath={xpath}>
      <Button
        disableRipple
        buttonRef={anchorEl}
        variant={ButtonVariants.text}
        onClick={handleClick}
        className={clsx(classes.btn, { [classes.active]: open })}
      >
        <>
          <span className={classes.children}>
            {triggerElement}
          </span>
          <ArrowDropDownIcon className={clsx({ [classes.arrowUp]: open })} />
        </>
      </Button>
      <Popper
        open={open}
        onClose={setOpen}
        anchorEl={anchorEl}
        placement="bottom-end"
        className={classes.popper}
      >
        <MenuList size="small">
          {children}
        </MenuList>
      </Popper>
    </div>
  );
}
