export enum MapMode {
  edit = 'edit',
  select = 'select',
}

export enum GeometryType {
  polygon = 'polygon',
  point = 'point',
  line = 'line',
}

export interface GeometryValue {
  geometry: {
    type: GeometryType;
    dots?: Array<{ longitude: string, latitude: string }>;
    longitude?: string;
    latitude?: string;
  };
  properties?: Record<string, unknown>;
}

export interface LayerConfig {
  url: string;
  name: string;
  attribution?: string;
  maxZoom?: number;
}

export interface OverlayConfig {
  name: string;
  type: string;
  url: string;
  color?: string;
  detailsTitlePath: string;
  detailParamsList?: Array<DetailParamsConfig>;
}

export interface DetailParamsConfig {
  path: string;
  title: string;
}

export type GeocodeOptions = {
  geocodeSearchZoom: number;
  geocodeMarkerRadius: number;
};
