import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'connected-react-router';

import SidebarLayout from 'components/Layouts/Sidebar';
import useQuery from 'hooks/useQuery';
import {
  activateChannelClean,
  activateChannelRequest,
  selectActivateChannelValidationError,
  selectChannelTimeout,
  selectSettingsIsLoading,
  verifyChannelRequest,
} from 'store/settings';
import { ChannelType } from 'types/settings';
import { ROUTES } from 'constants/routes';

import Typography from '#web-components/components/Typography';
import Input from '#web-components/components/FormControls/Input';
import Button, { ButtonVariants } from '#web-components/components/Button';
import InlineButton from '#web-components/components/InlineButton';
import { EmailIcon } from '#web-components/components/Icons';
import useTimer from '#web-components/hooks/useTimer';

import styles from './ProfileConfirmChannel.styles';

const MAX_CODE_LENGTH = 6;

interface Params {
  channel: ChannelType;
}

const useStyles = makeStyles(styles, { name: 'ProfileConfirmChannel' });

export default function ProfileConfirmChannel() {
  const dispatch = useDispatch();
  const params = useParams<Params>();
  const { t, i18n } = useTranslation('confirmChannelPage');
  const classes = useStyles();
  const isLoading = useSelector(selectSettingsIsLoading);
  const codeValidationError = useSelector(selectActivateChannelValidationError);
  const timeout = useSelector(selectChannelTimeout);
  const addressQuery = useQuery().get('address');
  const [code, onCodeChange] = useState<{ value: string }>({ value: '' });
  const [codeError, onCodeErrorChange] = useState<string>(codeValidationError || '');
  const { channel } = params;
  const address = addressQuery || '';
  const { time, timeString, clear: clearTimeout } = useTimer(timeout, i18n.language);

  const handleSubmit = (value: string) => {
    dispatch(activateChannelRequest({ channel, address, verificationCode: value }));
  };

  const handleCodeChange = (value: string | number | null) => {
    const newCode = (value as string || '').substring(0, MAX_CODE_LENGTH);

    if (newCode.length === MAX_CODE_LENGTH) {
      handleSubmit(newCode);
    }

    onCodeChange({
      value: newCode,
    });
    onCodeErrorChange('');
  };

  const handleBackLinkClick = useCallback((event) => {
    event.preventDefault();
    dispatch(push(ROUTES.PROFILE));
  }, [dispatch]);

  const onCancel = useCallback((event) => {
    event.preventDefault();
    dispatch(goBack());
  }, [dispatch]);

  const onReload = useCallback(() => {
    dispatch(activateChannelClean());
    onCodeChange({ value: '' });
    if (address) {
      dispatch(verifyChannelRequest({
        channel,
        address,
      }));
    }
  }, [channel, address, dispatch]);

  const onRefresh = useCallback(() => {
    onReload();
    clearTimeout();
  }, [onReload, clearTimeout]);

  useEffect(() => {
    clearTimeout();
  }, [clearTimeout, timeout]);

  useEffect(() => {
    onCodeErrorChange(codeValidationError || '');
  }, [codeValidationError]);

  useEffect(() => {
    onReload();
  }, [onReload]);

  return (
    <SidebarLayout
      backLinkPath=""
      onBackLinkClick={handleBackLinkClick}
      title={t(`${channel}.title`)}
      backLinkCaption={t('backToProfile')}
      isLoading={isLoading}
      sideBarContent={(
        <>
          <Typography variant="h5" className={classes.sidebarTitle}>{t(`${channel}.sidebarTitle`)}</Typography>
          <Typography variant="bodyText">{t(`${channel}.sidebarDescription`)}</Typography>
        </>
    )}
    >
      <form className={classes.root}>
        <Typography variant="h7">
          { t(`${channel}.codeSent`, { address }) }
        </Typography>
        <div>
          <Input
            name="otp_code"
            id="otp_code_input"
            label={t(`${channel}.codeInput.label`)}
            placeholder={t(`${channel}.codeInput.placeholder`, { number: MAX_CODE_LENGTH })}
            withNumberFormat={{ enabled: true, parseToNumber: false, leadingZeros: true }}
            value={code.value}
            onChange={handleCodeChange}
            error={codeError ? { message: codeError } : undefined}
          />
          {time !== 0 && (
            <Typography variant="tinyText" className={classes.timeoutMessage}>
              {t('sendAgainTimeoutMessage', { time: timeString })}
            </Typography>
          )}
          {time === 0 && (
            <InlineButton
              size="medium"
              classes={{
                link: classes.refreshButton,
              }}
              onLinkClick={onRefresh}
              leftIcon={<EmailIcon />}
            >
              <Typography variant="h8" className={classes.refreshButtonText}>{t('sendAgain')}</Typography>
            </InlineButton>
          )}
        </div>

        <div className={classes.buttonContainer}>
          <Button variant={ButtonVariants.secondary} onClick={onCancel}>
            {t('cancelButton')}
          </Button>
        </div>
      </form>
    </SidebarLayout>
  );
}
