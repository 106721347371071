import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as Icon } from '#web-components/assets/icons/arrowDown.svg';

import styles from './ArrowDownIcon.styles';

type ArrowDownIconProps = {
  className?: string;
};

const useStyles = makeStyles(styles, { name: 'ArrowDownIcon' });

export default function ArrowDownIcon({ className }: ArrowDownIconProps) {
  const classes = useStyles();
  return (
    <span className={clsx(classes.icon, className)}>
      <Icon />
    </span>
  );
}
