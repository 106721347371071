import { eachComponent } from 'formiojs/utils/utils';

import { FormComponent, EditGridComponent } from '#web-components/components/Form/types';

export function checkUniqueKeys(
  components: Array<FormComponent>,
  componentPath?: string,
  componentKey?: string,
): boolean {
  const keys = new Set();
  let hasError = false;

  eachComponent(
    components,
    (comp: EditGridComponent, path: string) => {
      let calculatedPath = path;
      if (componentPath && path === componentPath) {
        const lastDotIndex = path.lastIndexOf('.');
        calculatedPath = path.substring(0, lastDotIndex + 1) + componentKey;
      }

      if (keys.has(calculatedPath)) {
        hasError = true;
      } else {
        keys.add(calculatedPath);
      }

      if (comp.components) {
        checkUniqueKeys(comp.components, componentPath, componentKey);
      }
    },
  );

  return hasError;
}
