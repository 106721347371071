import React, {
  useCallback, useEffect, useMemo, useState, forwardRef, ForwardedRef, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  completeTaskRequest,
  completeTaskClean,
  getTaskRequest,
  getTaskClean,
  selectUserTaskError,
  selectUserTaskIsLoading,
  selectUserTask,
  selectUserTaskFormSubmissionData,
  selectUserTaskValidationErrors,
  signTaskRequest,
  selectTaskData,
  selectUserTaskForm,
  selectSignUserTaskValidationErrors,
  signTaskClean,
  saveTaskRequest,
} from 'store/userTask';
import { FormEvalContext } from 'types/form';
import { APP_URL_PREFIX, ROUTES } from 'constants/routes';
import SidebarLayout from 'components/Layouts/Sidebar';
import { taskSuspendedErrorProps } from 'constants/errorProps';
import { X_PATH } from 'constants/xPath';
import { selectCurrentUserInfo } from 'store/currentUser';
import { NAVIGATION_CODE } from '#web-components/components/Form/constants';
import {
  ButtonComponent,
  FileComponent,
  FormComponent,
  FORMIO_EVENT,
  FormSubmission,
} from '#web-components/components/Form/types';
import {
  findComponents,
  filterComponents,
  isEditGridComponent,
  isFileComponent,
  prepareFileSubmission,
  isNavigationButton,
} from '#web-components/utils';
import { Formio } from '#web-components/exports/formio';
import Form, {
  FormioModule,
} from '#web-components/components/Form';
import SignatureWidget from '#web-components/components/SignatureWidget';
import { assignMultipleRefs } from '#web-components/utils/common';
import RouterPrompt from '#shared/utils/RouterPrompt';

import TaskSideBar from './components/TaskSideBar';

const validationMessagesProps = {
  flashMessagesProps: {
    xpathConfig: {
      messageBlock: X_PATH.validationMessage,
      titleBlock: X_PATH.validationMessageTitle,
    },
  },
};

const SIGN_WIDGET_XPATH_CONFIG = {
  changeKeyButton: X_PATH.changeKeyButton,
  signButton: X_PATH.signButton,
};

Formio.use(FormioModule);
Formio.setBaseUrl(ENVIRONMENT_VARIABLES?.apiUrl || window.location.origin);

interface Params {
  taskId: string;
}

function TaskPage(props: unknown, forwardedRef: ForwardedRef<Formio>) {
  const formRef = useRef<Formio>();
  const { t, i18n } = useTranslation('taskPage');
  const dispatch = useDispatch();
  const params = useParams<Params>();
  const userInfo = useSelector(selectCurrentUserInfo);
  const form = useSelector(selectUserTaskForm);
  const userTask = useSelector(selectUserTask);
  const isSignNeeded = userTask?.esign;
  const isSubmitOrNavigationAction = (action: string) => (action === 'submit' || action === 'navigation');

  const preparedComponents = useMemo(() => {
    if (isSignNeeded) {
      return filterComponents<ButtonComponent>(
        form?.components || [],
        (component) => !(component?.type === 'button' && isSubmitOrNavigationAction(component.action)),
      );
    }
    return form?.components || [];
  }, [isSignNeeded, form?.components]);
  const taskSubmission = useSelector(selectUserTaskFormSubmissionData);
  const taskData = useSelector(selectTaskData);
  const preparedTaskData = useMemo(() => {
    const { data } = prepareFileSubmission(preparedComponents, { data: taskData });
    return data;
  }, [preparedComponents, taskData]);
  const isLoading = useSelector(selectUserTaskIsLoading);
  const error = useSelector(selectUserTaskError);
  const validationErrors = useSelector(selectUserTaskValidationErrors);
  const signValidationErrors = useSelector(selectSignUserTaskValidationErrors);
  const suspendedError = userTask?.suspended ? taskSuspendedErrorProps : undefined;
  const [signWidgetLoading, setSignWidgetLoading] = useState(false);
  const [isFormDirty, setFormDirty] = useState(false);

  const formEvalContext: FormEvalContext = useMemo(() => {
    return {
      formVariables: userTask?.formVariables || null,
      currentUser: userInfo,
    };
  }, [userInfo, userTask?.formVariables]);

  const completeTask = useCallback((formData: FormSubmission) => {
    dispatch(completeTaskRequest({
      taskId: params.taskId,
      taskName: userTask?.name,
      processInstanceId: userTask?.processInstanceId,
      formData,
    }));
  }, [dispatch, params.taskId, userTask?.name, userTask?.processInstanceId]);

  const handleCustomEvent = useCallback((data: { type: string, data: FormSubmission; }) => {
    const { type, data: formData } = data;
    if (type === FORMIO_EVENT.NAVIGATION) {
      completeTask(formData);
    }
  }, [completeTask]);

  const handleNavigation = useCallback((actionCode: string) => {
    completeTask({
      data: {
        ...preparedTaskData,
        [NAVIGATION_CODE]: actionCode,
      },
    });
  }, [completeTask, preparedTaskData]);

  const handleSubmit = useCallback((submission: FormSubmission) => {
    completeTask(submission);
  }, [completeTask]);

  const handleSignText = useCallback((signature: string) => {
    dispatch(signTaskRequest({
      taskId: params.taskId,
      taskName: userTask?.name,
      processInstanceId: userTask?.processInstanceId,
      data: {
        data: preparedTaskData,
        signature,
      },
    }));
  }, [dispatch, params.taskId, userTask?.name, userTask?.processInstanceId, preparedTaskData]);

  const fileUrlTransformer = useCallback((component: FormComponent) => {
    if (!isFileComponent(component.type)) {
      return component;
    }
    const newUrl = `
          ${(component as FileComponent).url}/${userTask?.rootProcessInstanceId}/${userTask?.id}/${component.key}`;
    return {
      ...component,
      url: newUrl,
    };
  }, [userTask?.id, userTask?.rootProcessInstanceId]);

  useEffect(() => {
    dispatch(getTaskRequest(params.taskId));
    return function cleanup() {
      dispatch(completeTaskClean());
      dispatch(signTaskClean());
    };
  }, [dispatch, params.taskId]);

  useEffect(() => {
    return function cleanup() {
      dispatch(completeTaskClean());
      dispatch(getTaskClean());
      dispatch(signTaskClean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (validationErrors.length > 0 || signValidationErrors.length > 0) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [validationErrors, signValidationErrors]);

  const hasEditGrid = useMemo(() => findComponents(
    form?.components || [],
    (component) => isEditGridComponent(component.type),
  ), [form?.components]);

  const hasFileWithResourceValidation = useMemo(() => findComponents<FileComponent>(
    form?.components || [],
    (component) => !!(isFileComponent(component.type) && component?.resourceValidation),
  ), [form?.components]);

  const loaderDescription = useMemo(() => {
    if ((hasEditGrid || hasFileWithResourceValidation) && isSignNeeded) {
      return t('loaderDescription');
    }

    return undefined;
  }, [hasEditGrid, hasFileWithResourceValidation, isSignNeeded, t]);

  const handleClickSave = useCallback(() => {
    if (formRef.current) {
      const { formio } = formRef.current;
      const isValid = formio.checkValidity(formio.data, true);
      if (isValid) {
        dispatch(saveTaskRequest({
          taskId: params.taskId,
          data: formio.data,
        }));
      }
    }
  }, [dispatch, params.taskId]);

  const sideBarContent = useMemo(
    () => (isSignNeeded
      ? t('signWidget.sideBarContent')
      : <TaskSideBar onClickSave={handleClickSave} />),
    [isSignNeeded, t, handleClickSave],
  );

  const handleDirtyChange = useCallback((status) => {
    setFormDirty(status);
  }, []);

  const MemoForm = useMemo(() => {
    return (
      <Form
        formRef={(node) => assignMultipleRefs(node, formRef, forwardedRef)}
        components={preparedComponents}
        onSubmit={handleSubmit}
        onCustomEvent={handleCustomEvent}
        submissionData={taskSubmission}
        validationErrors={validationErrors.concat(signValidationErrors)}
        readOnly={isSignNeeded}
        language={i18n.language}
        validationMessagesProps={validationMessagesProps}
        evalContext={formEvalContext}
        componentsTransformer={fileUrlTransformer}
        onDirtyChange={handleDirtyChange}
        blackList={ENVIRONMENT_VARIABLES.emailBlacklist || []}
        fileMaxSize={ENVIRONMENT_VARIABLES.digitalDocumentsMaxFileSize}
        fileMaxTotalSize={ENVIRONMENT_VARIABLES.digitalDocumentsMaxTotalFileSize}
      />
    );
  }, [
    fileUrlTransformer,
    formEvalContext,
    forwardedRef,
    handleCustomEvent,
    handleDirtyChange,
    handleSubmit,
    i18n.language,
    isSignNeeded,
    preparedComponents,
    signValidationErrors,
    taskSubmission,
    validationErrors,
  ]);

  return (
    <SidebarLayout
      title={form?.title || t('title')}
      sideBarContent={sideBarContent}
      isLoading={isLoading || signWidgetLoading}
      loaderDescription={loaderDescription}
      backLinkPath={ROUTES.USER_TASK_LIST}
      backLinkCaption={t('backLinkCaption')}
      error={error || suspendedError}
    >
      <RouterPrompt
        enabled={isFormDirty}
        baseName={APP_URL_PREFIX}
        okText={t('formDomain:routerPrompt.okText')}
        cancelText={t('formDomain:routerPrompt.cancelText')}
        text={t('formDomain:routerPrompt.text')}
        title={t('formDomain:routerPrompt.title')}
      />
      {MemoForm}
      {isSignNeeded && (
        <SignatureWidget
          signWidgetUri={ENVIRONMENT_VARIABLES.signWidgetUrl}
          signWidgetHeight={ENVIRONMENT_VARIABLES.signWidgetHeight}
          changeKeyButtonCaption={t('signWidget.changeKeyButton')}
          signButtonCaption={t('signWidget.signButton')}
          signDataTitle={t('signWidget.signDataTitle')}
          readKeyTitle={t('signWidget.readKeyTitle')}
          organizationCaption={t('signWidget.organization')}
          rnokppCaption={t('signWidget.rnokpp')}
          edrpouCaption={t('signWidget.edrpou')}
          xpathConfig={SIGN_WIDGET_XPATH_CONFIG}
          dataToSign={preparedTaskData}
          onSignText={handleSignText}
          onLoadingChange={setSignWidgetLoading}
          navigationButton={findComponents(
            form?.components || [],
            (component) => isNavigationButton(component.action),
          )}
          onNavigation={handleNavigation}
          errorButtonCaption={t('signWidget.errorButtonText')}
          qrCode={{
            title: t('signWidget.qrCode.title'),
            description: t('signWidget.qrCode.description'),
            timerText: t('signWidget.qrCode.timerText'),
            errorMessage: t('signWidget.qrCode.errorMessage'),
          }}
        />
      )}
    </SidebarLayout>
  );
}

export default forwardRef(TaskPage);
