import get from 'lodash/get';
import formioUtils from 'formiojs/components/_classes/component/editForm/utils';
import { eachComponent } from 'formiojs/utils/utils';
import { checkUniqueKeys } from '#web-components/utils/form';
import { EditGridComponent, FormComponent, FormioComponentName } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNestedInGrid = (context: any) => {
  let show = true;
  context.utils.eachComponent(context.instance.options.editForm.components, (component: EditGridComponent) => {
    if ([FormioComponentName.editgridLatest].includes(component.type as FormioComponentName)
      && component.optimizedRendering) {
      context.utils.eachComponent(component.components, (nestedComponent: FormComponent) => {
        if (nestedComponent.id === context.data.id) {
          show = false;
        }
      });
    }
  });
  return show;
};

export const FORMIO_SELECT_VALUE_PROPERTY = 'value';

export const I18N_SEPARATOR = '.|.';

export const COMPONENT_CLASSES = {
  textfield: 'mdtuddm-textfield',
  number: 'mdtuddm-number',
  textarea: 'mdtuddm-textarea',
  radio: 'mdtuddm-radio',
  checkbox: 'mdtuddm-checkbox',
  file: 'mdtuddm-file',
  email: 'mdtuddm-email',
  map: 'mdtuddm-map',
  dataImport: 'mdtuddm-dataImport',
  editgrid: 'mdtuddm-editgrid',
  columns: 'mdtuddm-columns',
  datetime: 'mdtuddm-datetime',
  content: 'mdtuddm-content',
  select: 'mdtuddm-select',
  fieldset: 'mdtuddm-fieldset',
  table: 'mdtuddm-table',
  buttonPrimaryComponent: 'mdtuddm-button-primary',
  buttonSecondaryComponent: 'mdtuddm-button-secondary',
  buttonCancelComponent: 'mdtuddm-button-cancel',
  bootstrapComponent: 'bootstrapFormStyles',
};

export const THOUSANDS_SEPARATOR = ' ';
export const DECIMAL_SEPARATOR = ',';

export const DEFAULT_INPUT_MASK_PLACEHOLDER_CHAR = '_';
export const MASK_REGEXP_CHARS = /a|\*|9/g;

export const NAVIGATION_CODE = '_action_code';
export const SELECTION_FIELD = '_selection_field';
export const ACTION_SELECTION_FIELD = '_action_selection_field';
export const FLEX_FIELD = '_flex_field';
export const INITIAL_DATA_KEY = 'initialData';

export const tableSortSettings = [
  {
    key: 'sortAsNumber',
    label: 'Sort As Number',
    type: 'checkbox',
    input: true,
    defaultValue: false,
    tooltip: 'Should the value of this component be considered a number when sorting in grids (e.g. EditGrid).',
  },
];

export const tableSettingsTab = {
  label: 'Table',
  key: 'table',
  components: [
    {
      weight: 0,
      type: 'checkbox',
      label: 'Table View',
      tooltip: 'Shows this value within the table view of the submissions.',
      key: 'tableView',
      input: true,
    },
    {
      weight: 0,
      type: 'checkbox',
      label: 'Table column width',
      key: 'customColumnWidth',
      input: true,
    },
    {
      type: 'number',
      key: 'columnWidth',
      label: 'Column width',
      tooltip: 'Set the width in pixels for element in the table',
      input: true,
      weight: 0,
      defaultValue: 400,
      decimalLimit: 0,
      validate: {
        required: true,
        customMessage: 'Use numbers 0-9, amount of numbers min 1',
        min: 1,
      },
      customConditional: 'show = data.customColumnWidth',
    },
    ...tableSortSettings,
  ],
};

const parentVariableDefinition = '<tr>'
  + '<th>parent</th>'
  + '<td>The complete submission data object when this component is inside Latest EditGrid.</td>'
  + '</tr>';
const rowIndexVariableDefinition = '<tr>'
  + '<th>rowIndex</th>'
  + '<td>The number of item under edit inside EditGrid.</td>'
  + '</tr>';
const additionalVariableDef = parentVariableDefinition + rowIndexVariableDefinition;

export const defaultValidationFields = [
  {
    overrideEditForm: true,
    type: 'panel',
    title: 'Custom Validation',
    collapsible: true,
    collapsed: true,
    style: { 'margin-bottom': '10px' },
    key: 'custom-validation-js',
    weight: 300,
    components: [
      formioUtils.logicVariablesTable(`<tr><th>input</th><td>The value that was input into this component</td></tr>
${additionalVariableDef}`),
      {
        type: 'textarea',
        key: 'validate.custom',
        rows: 5,
        editor: 'ace',
        hideLabel: true,
        as: 'javascript',
        input: true,
      },
      {
        type: 'htmlelement',
        tag: 'div',
        content: `
          <small>
            <p>Enter custom validation code.</p>
            <p>You must assign the <strong>valid</strong> variable as either <strong>true</strong>
             or an error message if validation fails.</p>
            <h5>Example:</h5>
            <pre>valid = (input === 'Joe') ? true : 'Your name must be "Joe"';</pre>
          </small>`,
      },
      {
        type: 'well',
        components: [
          {
            weight: 100,
            type: 'checkbox',
            label: 'Secret Validation',
            tooltip: 'Check this if you wish to perform the validation ONLY on the server side. '
              + 'This keeps your validation logic private and secret.',
            description: 'Check this if you wish to perform the validation ONLY on the server side. '
              + 'This keeps your validation logic private and secret.',
            key: 'validate.customPrivate',
            input: true,
          },
        ],
      },
    ],
  },
];

export const defaultDataFields = [
  {
    key: 'customDefaultValuePanel',
    components: [{
      key: 'customDefaultValue-json',
      ignore: true,
    }],
  },
  {
    overrideEditForm: true,
    ...formioUtils.javaScriptValue(
      'Custom Default Value',
      'customDefaultValue',
      'customDefaultValue',
      1000,
      '<p><h4>Example:</h4><pre>value = data.firstName + " " + data.lastName;</pre></p>',
      '<p><h4>Example:</h4><pre>{"cat": [{"var": "data.firstName"}, " ", {"var": "data.lastName"}]}</pre>',
      additionalVariableDef,
    ),
    customConditional: isNestedInGrid,
  },
  {
    overrideEditForm: true,
    ...formioUtils.javaScriptValue(
      'Calculated Value',
      'calculateValue',
      'calculateValue',
      1100,
      '<p><h4>Example:</h4><pre>value = data.a + data.b + data.c;</pre></p>',
      // eslint-disable-next-line max-len
      '<p><h4>Example:</h4><pre>{"+": [{"var": "data.a"}, {"var": "data.b"}, {"var": "data.c"}]}</pre><p><a target="_blank" href="http://formio.github.io/formio.js/app/examples/calculated.html">Click here for an example</a></p>',
      `<tr><th>token</th><td>The decoded JWT token for the authenticated user.</td></tr>${additionalVariableDef}`,
    ),
    customConditional: isNestedInGrid,
  },
];

export const defaultConditionalFields = [
  {
    overrideEditForm: true,
    ...formioUtils.javaScriptValue(
      'Advanced Conditions',
      'customConditional',
      'conditional.json',
      110,
      '<p>You must assign the <strong>show</strong> variable a boolean result.</p>'
      + '<p><strong>Note: Advanced Conditional logic will override the results of the Simple Conditional logic.'
      + '</strong></p>'
      + '<h5>Example</h5><pre>show = !!data.showMe;</pre>',
      '<p><a href="http://formio.github.io/formio.js/app/examples/conditions.html" target="_blank">'
      + 'Click here for an example</a></p>',
      additionalVariableDef,
    ),
    customConditional: isNestedInGrid,
  },
];

export const defaultApiFields = [
  {
    weight: 0,
    type: 'textfield',
    input: true,
    key: 'key',
    label: 'Property Name',
    tooltip: 'The name of this field in the API endpoint.',
    overrideEditForm: true,
    validate: {
      pattern: '(\\w|\\w[\\w-.]*\\w)',
      // eslint-disable-next-line max-len
      patternMessage: 'The property name must only contain alphanumeric characters, underscores, dots and dashes and should not be ended by dash or dot.',
      required: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      custom(context: any) {
        const key = get(context, 'data.key', {});
        const editComponent = get(context, 'instance.options.editComponent', {});
        const components = get(context, 'instance.options.editForm.components', {});
        const { id } = editComponent;
        let path;
        eachComponent(
          components,
          (comp: FormComponent, currentPath: string) => {
            if (comp.id === id) {
              path = currentPath;
            }
          },
        );
        const hasError = checkUniqueKeys(components, path, key);

        if (hasError) {
          return context.t('APIKeyNotUniqueError');
        }

        return true;
      },
    },
  },
];
