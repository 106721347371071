import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import Link from '#web-components/components/Link';
import Typography from '#web-components/components/Typography';
import styles from './InlineButton.styles';

type LinkProps = {
  to: string,
  onLinkClick?: (e: React.MouseEvent) => void,
};

type ButtonProps = Partial<LinkProps>;

type NavigationProps = LinkProps | ButtonProps;
export type InlineButtonProps = {
  children: React.ReactNode,
  classes?: {
    link?: string,
    title?: string,
  },
  component?: React.ElementType,
  leftIcon?: React.ReactNode,
  size?: 'medium' | 'small' | 'huge',
  disabled?: boolean,
  download?: string,
  type?: string,
  target?: string,
  rel?: string,
  'data-xpath'?: string,
} & NavigationProps;

const useStyles = makeStyles(styles, { name: 'InlineButton' });

export default function InlineButton({
  onLinkClick,
  component,
  to,
  leftIcon,
  children,
  size = 'medium',
  disabled = false,
  classes,
  download,
  type,
  target,
  rel,
  'data-xpath': dataXPath,
}: InlineButtonProps) {
  const ownClasses = useStyles({ disabled });
  const onClick = useCallback((e: React.MouseEvent) => {
    if (onLinkClick && !disabled) {
      onLinkClick(e);
    }
  }, [onLinkClick, disabled]);

  return (
    <Link
      to={to}
      download={download}
      type={type}
      target={target}
      rel={rel}
      className={clsx(
        ownClasses.link,
        classes?.link,
        disabled && ownClasses.disabled,
        (size === 'medium' || size === 'huge') && ownClasses.mediumPadding,
      )}
      component={component}
      onClick={onClick}
      data-xpath={dataXPath}
    >
      {/* left margin for medium size */}
      {leftIcon}
      <Typography
        className={clsx(
          classes?.title,
          size === 'huge' && ownClasses.huge,
          size === 'medium' && ownClasses.medium,
          size === 'small' && ownClasses.small,
          !disabled && ownClasses.text,
          leftIcon && ownClasses.iconMargin,
        )}
      >
        {children}
      </Typography>
    </Link>
  );
}
