import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import {
  selectProcessDefinitionForm,
  selectProcessDefinitionFormValidationErrors,
  selectProcessDefinitionLoading,
  startProcessWithFormRequest,
  selectProcessDefinitionError,
  getProcessDefinitionFormRequest,
  selectProcessDefinitionFormError,
  getProcessDefinitionFormClean,
  startProcessWithFormClean,
  selectStartFormSubmissionData,
} from 'store/processDefinitions';
import { APP_URL_PREFIX, ROUTES } from 'constants/routes';
import SidebarLayout from 'components/Layouts/Sidebar';
import { X_PATH } from 'constants/xPath';
import useQuery from 'hooks/useQuery';
import { FormEvalContext } from 'types/form';
import { selectCurrentUserInfo } from 'store/currentUser';
import Form from '#web-components/components/Form';
import { b64UrlDecodeUnicode } from '#web-components/utils/common';
import { FormSubmission } from '#web-components/components/Form/types';
import RouterPrompt from '#shared/utils/RouterPrompt';

const validationMessagesProps = {
  flashMessagesProps: {
    xpathConfig: {
      messageBlock: X_PATH.validationMessage,
      titleBlock: X_PATH.validationMessageTitle,
    },
  },
};

interface Params {
  processDefinitionKey: string;
}

export default function StartForm() {
  const { t, i18n } = useTranslation('startFormPage');
  const form = useSelector(selectProcessDefinitionForm);
  const userInfo = useSelector(selectCurrentUserInfo);
  const isLoading = useSelector(selectProcessDefinitionLoading);
  const validationErrors = useSelector(selectProcessDefinitionFormValidationErrors);
  const error = useSelector(selectProcessDefinitionError);
  const formError = useSelector(selectProcessDefinitionFormError);
  const formSubmission = useSelector(selectStartFormSubmissionData);
  const params = useParams<Params>();
  const queryParams = useQuery();
  const queryDataBase64 = queryParams.get('data') || '';
  const queryDataJSON = b64UrlDecodeUnicode(queryDataBase64);
  const queryData = useMemo(() => {
    try {
      return { data: JSON.parse(queryDataJSON) };
    } catch {
      return { data: {} };
    }
  }, [queryDataJSON]);
  const [isFormDirty, setFormDirty] = useState(false);
  const dispatch = useDispatch();
  const formEvalContext: FormEvalContext = useMemo(() => ({
    formVariables: null,
    currentUser: userInfo,
  }), [userInfo]);

  const handleSubmit = useCallback((submission: FormSubmission) => {
    dispatch(
      startProcessWithFormRequest({
        processDefinitionKey: params.processDefinitionKey,
        formData: submission,
      }),
    );
  }, [dispatch, params.processDefinitionKey]);

  const handleDirtyChange = useCallback((status) => {
    setFormDirty(status);
  }, []);

  useEffect(() => {
    dispatch(getProcessDefinitionFormRequest(params.processDefinitionKey));
    return () => {
      dispatch(getProcessDefinitionFormClean());
      dispatch(startProcessWithFormClean());
    };
  }, [dispatch, params.processDefinitionKey]);

  useEffect(() => {
    if (validationErrors.length > 0) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [validationErrors]);

  const MemoForm = useMemo(() => (
    <Form
      components={form?.components}
      onSubmit={handleSubmit}
      validationErrors={validationErrors}
      language={i18n.language}
      validationMessagesProps={validationMessagesProps}
      evalContext={formEvalContext}
      submissionData={isEmpty(queryData.data) ? formSubmission : queryData}
      onDirtyChange={handleDirtyChange}
      blackList={ENVIRONMENT_VARIABLES.emailBlacklist || []}
      fileMaxSize={ENVIRONMENT_VARIABLES.digitalDocumentsMaxFileSize}
      fileMaxTotalSize={ENVIRONMENT_VARIABLES.digitalDocumentsMaxTotalFileSize}
    />
  ), [
    form?.components,
    formEvalContext,
    formSubmission,
    handleDirtyChange,
    handleSubmit,
    i18n.language,
    validationErrors,
    queryData,
  ]);

  return (
    <SidebarLayout
      title={form?.title || ''}
      sideBarContent={t('common:formMessages.mandatoryFields')}
      isLoading={isLoading}
      backLinkPath={ROUTES.PROCESS_LIST}
      backLinkCaption={t('backLinkCaption')}
      error={error || formError}
    >
      <RouterPrompt
        enabled={isFormDirty}
        baseName={APP_URL_PREFIX}
        okText={t('formDomain:routerPrompt.okText')}
        cancelText={t('formDomain:routerPrompt.cancelText')}
        text={t('formDomain:routerPrompt.text')}
        title={t('formDomain:routerPrompt.title')}
      />
      {MemoForm}
    </SidebarLayout>
  );
}
