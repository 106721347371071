import { createStyles } from '@material-ui/core';

const styles = () => createStyles({
  container: {
    '& .form-builder-group-header': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .builder-group-button': {
      whiteSpace: 'nowrap',
    },
    // Hotfix for https://jiraeu.epam.com/browse/MDTUDDM-13524
    // TODO: fix this in normal way. The problem is caused by a style conflict between
    // Styles just copied from formio.full.js for increase power of css selectors
    '& .formcomponents .formcomponent': {
      textAlign: 'left',
      padding: '5px 5px 5px 8px',
      marginTop: '0.2rem',
      fontSize: '.8em',
      lineHeight: 1.2,
      borderRadius: '0.3em',
    },
    '& .component-btn-group .component-settings-button': {
      padding: 2,
      fontSize: 10,
      lineHeight: '1.2em',
      borderRadius: 0,
      width: 18,
      height: 18,
    },
    // hotfix end
  },
});

export default styles;
