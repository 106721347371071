import { createStyles, Theme } from '@material-ui/core/styles';
import { spacing } from '#web-components/styles';

const styles = ({ colors }: Theme) => createStyles({
  root: {
    zIndex: 0,
  },
  active: {
    borderImage: colors.uIGradient1,
    borderImageSlice: 1,
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px',
  },
  children: {
    marginRight: spacing / 2,
  },
  btn: {
    marginBottom: '-2px',
    borderBottom: '2px solid transparent',
    borderRadius: 0,
    '&:hover': {
      borderBottom: `2px solid ${colors.uIBase}`,
      '& path': {
        fill: colors.uIBase,
      },
    },
    '&& button': {
      background: 'none',
    },
  },
  arrowUp: {
    transform: 'rotate(180deg)',
  },
  popper: {
    top: `${spacing - 2}px !important`,
  },
});

export default styles;
