import { createStyles, Theme } from '@material-ui/core';
import { spacing } from '#web-components/styles';

import type { GroupedEntityListProps } from './GroupedEntityList';

const styles = ({ colors }: Theme) => createStyles({
  groupCellName: {
    display: 'flex',
    padding: spacing,
    cursor: 'pointer',
    '&:hover': {
      background: colors.dropmenuHover,
    },
  },
  titleCellClass: (props: GroupedEntityListProps) => ({
    borderBottom: `1px solid ${colors.uIBase4}`,
    ...(props.onEntitySelect ? {
      cursor: 'pointer',
      '&:hover': {
        background: colors.dropmenuHover,
      },
    } : {}),
  }),
  cellName: {
    display: 'flex',
    padding: spacing,
  },
  icon: {
    marginRight: `${spacing * 2}px`,
    width: '38px',
    flexShrink: 0,
  },
  cellClass: {
    borderBottom: `1px solid ${colors.uIBase4}`,
  },
});

export default styles;
