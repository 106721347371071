import { createStyles } from '@material-ui/core';
import isInteger from 'lodash/isInteger';
import toNumber from 'lodash/toNumber';

import { spacing } from '#web-components/styles/constants';
import { darkTheme } from '#web-components/styles/themes/darkTheme';

import type { SignatureWidgetProps } from './SignatureWidget';

const styles = () => createStyles({
  button: {
    marginRight: spacing * 3,
  },
  frameContainer: (props: SignatureWidgetProps) => ({
    height: isInteger(toNumber(props.signWidgetHeight)) && !!toNumber(props.signWidgetHeight)
      ? toNumber(props.signWidgetHeight)
      : spacing * 90,
    background: darkTheme.colors.uIBase,
  }),
  hide: {
    display: 'none',
  },
  keyInfo: {
    border: '2px solid',
    padding: `${spacing * 3}px ${spacing * 4}px`,
  },
  buttonContainer: {
    marginTop: spacing * 7,
    marginBottom: spacing * 7,
    display: 'flex',
  },
  title: {
    margin: `${spacing * 5}px 0`,
  },
  name: {
    marginBottom: spacing * 3,
  },
  caption: {
    opacity: 0.5,
  },
  gutterBottom: {
    marginBottom: spacing * 2,
  },
});

export default styles;
