import {
  createAsyncActionErrorSelector,
  createAsyncActionIsLoadedSelector,
  createAsyncActionIsLoadingSelector,
} from '../asyncAction';
import type { RootState } from '../rootReducer';
import { getProcessInstancesHistoryRequest, ProcessInstancesHistoryState } from './slice';

export const selectProcessInstancesHistoryState = (
  state: RootState,
): ProcessInstancesHistoryState => state.processInstancesHistory;

export const selectProcessInstancesHistory = (state: RootState) => state.processInstancesHistory.list;
export const selectProcessInstancesHistoryFilterParams = (
  state: RootState,
) => state.processInstancesHistory.filterParams;
export const selectProcessInstancesHistoryCount = (state: RootState) => state.processInstancesHistory.count;

export const selectProcessInstancesHistoryIsLoading = (
  createAsyncActionIsLoadingSelector(getProcessInstancesHistoryRequest.type)
);

export const selectProcessInstancesHistoryIsLoaded = (
  createAsyncActionIsLoadedSelector(getProcessInstancesHistoryRequest.type)
);
export const selectProcessInstancesHistoryError = (
  createAsyncActionErrorSelector(getProcessInstancesHistoryRequest.type)
);
