import baseEditForm from 'formiojs/components/_classes/component/Component.form';

import { ignoreBuilderFields } from '#web-components/utils';
import { MapMode } from '#web-components/components/Map/types';
import {
  defaultConditionalFields,
  defaultDataFields,
  defaultValidationFields,
  defaultApiFields,
} from '#web-components/components/Form/constants';

import { OVERLAY_GRID, SCROLLABLE_GRID_CLASS } from './CustomMap.styles';
import { drawColors } from './constants';

/**
 * This function returns the fields that should be present in the builder
 * You can extend the default one of any component
 * The values you pass are merged with the passed ones using the function unifyComponents (seacrh in formio.js source)
 *
 * *overrideEditForm* param is used when component key is equal to the default one to favor yours
 * *ignore* param will force the skipping of this component
 */
export default () => {
  return baseEditForm([
    {
      key: 'display',
      components: ignoreBuilderFields([
        'inputType',
        'customClass',
        'autofocus',
        'modalEdit',
        'tabIndex',
        'placeholder',
        'hideLabel',
      ]),
    },
    {
      key: 'data',
      components: [
        {
          type: 'select',
          weight: 10,
          input: true,
          key: 'mode',
          label: 'Component mode',
          template: '<span>{{ item.label }}</span>',
          dataSrc: 'values',
          defaultValue: MapMode.edit,
          data: {
            values: [
              { label: 'Edit', value: MapMode.edit },
              { label: 'Select', value: MapMode.select },
            ],
          },
        },
        {
          label: 'Single value',
          key: 'singleValue',
          input: true,
          type: 'checkbox',
          defaultValue: true,
          weight: 10,
        },
        {
          type: 'datagrid',
          input: true,
          label: 'Base Layers',
          key: 'layers',
          tooltip: '',
          weight: 10,
          reorder: true,
          customClass: SCROLLABLE_GRID_CLASS,
          defaultValue: [{ url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', name: 'OpenStreetMap' }],
          components: [
            {
              label: 'Url',
              key: 'url',
              input: true,
              type: 'textfield',
              validate: {
                required: true,
              },
            },
            {
              label: 'Name',
              key: 'name',
              input: true,
              type: 'textfield',
              validate: {
                required: true,
              },
            },
            {
              label: 'Attribution',
              key: 'attribution',
              input: true,
              type: 'textfield',
            },
            {
              label: 'Max Zoom',
              key: 'maxZoom',
              input: true,
              defaultValue: 17,
              type: 'number',
              validate: {
                required: true,
              },
            },
          ],
        },
        {
          label: 'Overlay Search Field',
          key: 'overlaySearchProp',
          input: true,
          type: 'textfield',
          defaultValue: 'name',
          weight: 10,
          validate: {
            required: true,
          },
        },
        {
          label: 'Search Zoom',
          key: 'searchZoom',
          input: true,
          type: 'number',
          defaultValue: 12,
          weight: 10,
        },
        {
          type: 'editgrid',
          input: true,
          label: 'Overlay Layers',
          key: 'overlays',
          tooltip: '',
          weight: 10,
          reorder: true,
          modal: true,
          customClass: OVERLAY_GRID,
          initEmpty: true,
          defaultValue: [],
          components: [
            {
              label: 'Name',
              key: 'name',
              input: true,
              type: 'textfield',
              validate: {
                required: true,
              },
            },
            {
              label: 'Url',
              key: 'url',
              input: true,
              type: 'textfield',
              validate: {
                required: true,
              },
            },
            {
              label: 'Type',
              key: 'type',
              input: true,
              type: 'textfield',
              validate: {
                required: true,
              },
            },
            {
              type: 'select',
              input: true,
              key: 'color',
              label: 'Custom color',
              template: '<div style="display: inline-block; width: 140px; height: 20px; background:{{item.value}};"/>'
                + '<span style="visibility: hidden">{{ item.label }}</span>',
              dataSrc: 'values',
              data: {
                values: drawColors,
              },
            },
            {
              label: 'Details Title Path',
              key: 'detailsTitlePath',
              input: true,
              type: 'textfield',
              defaultValue: 'name',
              weight: 10,
              validate: {
                required: true,
              },
            },
            {
              type: 'datagrid',
              input: true,
              label: 'Details Params',
              key: 'detailParamsList',
              tooltip: '',
              weight: 10,
              tableView: false,
              reorder: true,
              defaultValue: [],
              initEmpty: true,
              components: [
                {
                  label: 'Title',
                  key: 'title',
                  input: true,
                  type: 'textfield',
                  tableView: false,
                  validate: {
                    required: true,
                  },
                },
                {
                  label: 'Path',
                  key: 'path',
                  input: true,
                  type: 'textfield',
                  tableView: false,
                  validate: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          label: 'Enable Geocode',
          key: 'geocodeEnabled',
          input: true,
          type: 'checkbox',
          weight: 10,
          defaultValue: false,
        },
        {
          label: 'Geocode Search Placeholder',
          key: 'geocodeSearchPlaceholder',
          input: true,
          type: 'textfield',
          defaultValue: 'Search',
          weight: 10,
          conditional: {
            json: { '===': [{ var: 'data.geocodeEnabled' }, true] },
          },
        },
        {
          label: 'Geocode Search Url',
          key: 'geocodeSearchUrl',
          input: true,
          type: 'textfield',
          defaultValue: 'https://nominatim.openstreetmap.org/search?format=json&q={s}',
          weight: 10,
          conditional: {
            json: { '===': [{ var: 'data.geocodeEnabled' }, true] },
          },
        },
        {
          label: 'Geocode Search Zoom',
          key: 'geocodeSearchZoom',
          input: true,
          type: 'number',
          defaultValue: 12,
          weight: 10,
          conditional: {
            json: { '===': [{ var: 'data.geocodeEnabled' }, true] },
          },
        },
        {
          label: 'Geocode Marker Radius',
          key: 'geocodeMarkerRadius',
          input: true,
          type: 'number',
          defaultValue: 10,
          weight: 10,
          conditional: {
            json: { '===': [{ var: 'data.geocodeEnabled' }, true] },
          },
        },
        {
          label: 'Custom Radius',
          key: 'geocodeMarkerRadiusCustom',
          input: true,
          type: 'checkbox',
          weight: 10,
          defaultValue: false,
          conditional: {
            json: { '===': [{ var: 'data.geocodeEnabled' }, true] },
          },
        },
        ...ignoreBuilderFields([
          'multiple',
          'defaultValue',
          'persistent',
          'protected',
          'dbIndex',
          'calculateServer',
          'encrypted',
        ]),
        ...defaultDataFields,
      ],
    },
    {
      key: 'validation',
      components: [
        ...defaultValidationFields,
        ...ignoreBuilderFields(['unique', 'json-validation-json', 'validateOn', 'errorLabel']),
      ],
    },
    {
      key: 'api',
      components: [
        ...ignoreBuilderFields(['tags', 'properties']),
        ...defaultApiFields,
      ],
    },
    {
      key: 'logic',
      ignore: true,
    },
    {
      key: 'layout',
      ignore: true,
    },
    {
      key: 'conditional',
      components: defaultConditionalFields,
    },
  ]);
};
