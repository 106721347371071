import { combineReducers, Reducer } from 'redux';
import { History } from 'history';
import { connectRouter, RouterState } from 'connected-react-router';

import { Notification, reducer as notificationsReducer } from 'reapop';
import ProcessDefinitionsReducer, { ProcessDefinitionsState } from './processDefinitions';
import ProcessInstancesReducer, { ProcessInstancesState } from './processInstances';
import ProcessInstancesHistoryReducer, { ProcessInstancesHistoryState } from './processInstancesHistory';
import PendingTasksReducer, { PendingTasksState } from './pendingTasks';
import UserTaskReducer, { UserTaskState } from './userTask';
import AsyncActionReducer, { AsyncActionState } from './asyncAction';
import CompletedTasksReducer, { CompletedTasksState } from './completedTasks';
import CurrentUserReducer, { CurrentUserState } from './currentUser';
import OnboardingProcessReducer, { OnboardingProcessState } from './onboarding';
import SettingsReducer, { SettingsState } from './settings';
import userNotificationsReducer, { UserNotificationsState } from './userNotifications';

export interface RootState {
  processDefinitions: ProcessDefinitionsState;
  processInstances: ProcessInstancesState;
  processInstancesHistory: ProcessInstancesHistoryState;
  pendingTasks: PendingTasksState;
  completedTasks: CompletedTasksState;
  userTask: UserTaskState;
  asyncAction: AsyncActionState;
  router: RouterState;
  notifications: Notification[];
  currentUser: CurrentUserState;
  onboardingProcess: OnboardingProcessState;
  settings: SettingsState;
  userNotifications: UserNotificationsState;
}

export const rootReducer = (history: History): Reducer<RootState> => combineReducers({
  router: connectRouter(history),
  processDefinitions: ProcessDefinitionsReducer,
  processInstances: ProcessInstancesReducer,
  processInstancesHistory: ProcessInstancesHistoryReducer,
  pendingTasks: PendingTasksReducer,
  completedTasks: CompletedTasksReducer,
  userTask: UserTaskReducer,
  asyncAction: AsyncActionReducer,
  notifications: notificationsReducer(),
  currentUser: CurrentUserReducer,
  onboardingProcess: OnboardingProcessReducer,
  settings: SettingsReducer,
  userNotifications: userNotificationsReducer,
});
